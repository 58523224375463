const ProcessAndSaveTrustLedgerTotal = async ({ saveFileToCollection, finalTotal }) => {
  const finalTotals = {
    trustLedgerItem: "Total",
    _classes: "font-weight-bold",
    trustLedgerExpenditureAmount: finalTotal?.totalExpenditures || 0,
    trustLedgerReceiptAmount: finalTotal?.totalReceipts || 0,
    processorReference: "trustLedgerTotal",
    location: "last",
    typeOfGeneratedEntry: "automaticEntry",
    excludeFromTotal: true,
    doNotDelete: true,
    doNotEdit: true,
  };
  await saveFileToCollection({ formData: finalTotals });
};

export default ProcessAndSaveTrustLedgerTotal;
