export const changeFontInDocx = (zip, font) => {
  const xmls = zip.folder("word");
  const xml = xmls.file("styles.xml");
  const docXml = xmls.file("document.xml");
  const docXmlContent = docXml.asText();
  const xmlContent = xml.asText();

  const parser = new DOMParser();
  const parseStyle = parser.parseFromString(xmlContent, "text/xml");
  const parseDocument = parser.parseFromString(docXmlContent, "text/xml");
  const rFontsStyles = parseStyle.getElementsByTagName("w:rFonts");
  const rFontsDocument = parseDocument.getElementsByTagName("w:rFonts");
  const wrTags = parseDocument.getElementsByTagName("w:r");
  for (let item of rFontsStyles) {
    if (item.hasAttribute("w:eastAsia")) {
      item.setAttribute("w:eastAsia", font);
    }
    if (item.hasAttribute("w:cs")) {
      item.setAttribute("w:cs", font);
    }
    if (item.hasAttribute("w:ascii") && item.hasAttribute("w:hAnsi")) {
      item.setAttribute("w:ascii", font);
      item.setAttribute("w:hAnsi", font);
    }
  }

  for (let item of rFontsDocument) {
    if (
      item.hasAttribute("w:hAnsi") ||
      item.hasAttribute("w:cs") ||
      item.hasAttribute("w:ascii") ||
      item.hasAttribute("w:eastAsia")
    ) {
      item.setAttribute("w:hAnsi", font);
      item.setAttribute("w:cs", font);
      item.setAttribute("w:ascii", font);
      item.setAttribute("w:eastAsia", font);
    }
  }
  for (let i = 0; i < wrTags.length; i++) {
    const wrTag = wrTags[i];
    const rprTag = wrTag.getElementsByTagName("w:rPr")[0];
    if (rprTag && !rprTag.getElementsByTagName("w:rFonts")[0]) {
      const newRfontsTag = parseDocument.createElement("w:rFonts");
      newRfontsTag.setAttribute("w:ascii", font);
      newRfontsTag.setAttribute("w:hAnsi", font);
      newRfontsTag.setAttribute("w:cs", font);
      const newLangTag = parseDocument.createElement("w:lang");
      newLangTag.setAttribute("w:val", "en-US");
      rprTag.appendChild(newRfontsTag);
      rprTag.appendChild(newLangTag);
    }
    if (!rprTag) {
      const newRprTag = parseDocument.createElement("w:rPr");
      const newRfontsTag = parseDocument.createElement("w:rFonts");
      newRfontsTag.setAttribute("w:ascii", font);
      newRfontsTag.setAttribute("w:hAnsi", font);
      newRfontsTag.setAttribute("w:cs", font);
      newRfontsTag.setAttribute("w:eastAsia", font);
      const newLangTag = parseDocument.createElement("w:lang");
      newLangTag.setAttribute("w:val", "en-US");
      newRprTag.appendChild(newRfontsTag);
      newRprTag.appendChild(newLangTag);
      wrTag.insertBefore(newRprTag, wrTag.firstChild);
      wrTag.insertBefore(parseDocument.createElement("w:tab"), wrTag.firstChild);
    }
  }
  const xmlSerialize = new XMLSerializer();
  const documentStyle = xmlSerialize.serializeToString(parseStyle);
  const documentDoc = xmlSerialize.serializeToString(parseDocument);
  return { documentStyle, documentDoc };
};
