import currency from "currency.js";

const ProcessStatementOfAccount = async ({ dataToProcess, lawFirm, processedData }) => {
  let TotalAmountWithHST = currency(
    dataToProcess?.StatementOfAccountFile?.Summary?.totalAmountWithHst,
  ).value;
  let lawFirmToUse = "";

  if (lawFirm?.freelanceClerk === "yes") {
    const type = dataToProcess?.createFile?.fileType;
    if (type === "Purchase" && dataToProcess?.purchaser?.solicitor) {
      lawFirmToUse = dataToProcess?.purchaser?.solicitor?.lawFirm;
    } else if (type === "Sale" && dataToProcess?.vendor?.solicitor) {
      lawFirmToUse = dataToProcess?.vendor?.solicitor?.lawFirm;
    } else if (type === "Mortgage") {
      const mortgageActingFor = dataToProcess?.fileOpen?.mortgageActingFor;
      const filesPrimaryClient = dataToProcess?.fileOpen?.filesPrimaryClient;
      if (
        (mortgageActingFor === "mortgagor" ||
          (mortgageActingFor === "both" &&
            (filesPrimaryClient === "mortgagor" || filesPrimaryClient === ""))) &&
        dataToProcess?.mortgagor?.solicitor
      ) {
        lawFirmToUse = dataToProcess?.mortgagor?.solicitor?.lawFirm;
      } else if (
        (mortgageActingFor === "mortgagee" ||
          (mortgageActingFor === "both" && filesPrimaryClient === "mortgagee")) &&
        dataToProcess?.mortgageesSolicitor
      ) {
        lawFirmToUse = dataToProcess?.mortgageesSolicitor?.otherSolicitorLawFirm;
      }
    }
  } else {
    lawFirmToUse = lawFirm?.name;
  }

  if (TotalAmountWithHST > 0) {
    return processedData.concat({
      trustLedgerItem: `Paid legal fees to (${lawFirmToUse})`,
      trustLedgerExpenditureAmount: TotalAmountWithHST,
      trustLedgerReceiptAmount: "",
      type: "expenditure",
      uniqueID: "StatementOfAccount",
      processorReference: "StatementOfAccount",
      typeOfGeneratedEntry: "automaticEntry",
    });
  }
  return processedData;
};

export default ProcessStatementOfAccount;
