const HnHMoveInArray = ({ array, from, to }) => {
  // Make sure a valid array is provided
  if (Array.isArray(array)) {
    const item = array?.splice(from, 1);
    // Make sure there's an item to move
    if (!item.length) {
      throw new Error("There is no item in the array at index " + from);
    }
    // Move the item to its new position
    array?.splice(to, 0, item[0]);
    // TODO: Uncomment and implement
    // if (to > item.length - 1) {
    //   array?.splice(item.length - 2, 0, item[0]);
    // } else array?.splice(to, 0, item[0]);
  }
};

export default HnHMoveInArray;
