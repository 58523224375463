import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import useCollections from "src/reusable/firestoreHooks/useCollections";
import useDeleteDocumentInSeperateCollection from "src/reusable/firestoreHooks/useDeleteDocumentInSeperateCollection";
import useDeleteMultipleDocumentsInTrustLedger from "src/reusable/firestoreHooks/useDeleteMultipleDocumentsInSeperateCollection";
import useSaveDocumentToCollection from "src/reusable/firestoreHooks/useSaveDocumentToCollection";
import HnHHandleDate from "src/reusable/HnHHandleDate";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";
import useStatementOfAccount from "../../statementOfAccount/processor/useStatementOfAccount";
import ProcessBillsHoldbacks from "./processBillsHoldbacks";
import ProcessBrokers from "./processBrokers";
import ProcessExistingEncumbrances from "./processExistingEncumbrances";
import ProcessFireInsuranceBrokerFee from "./processFireInsuranceBrokerFee";
import ProcessLandTransferTax from "./processLandTransferTax";
import ProcessNewMortgages from "./processNewMortgages";
import ProcessPropertyDeposits from "./processPropertyDeposits";
import ProcessPropertyTax from "./processPropertyTax";
import ProcessStatementOfAccount from "./processStatementOfAccount";
import processStatementOfAdjustments from "./processStatementOfAdjustments";
import ProcessTrustledgerRoutineEntries from "./processTrustledgerRoutineEntries";
import ProcessAndSaveTrustLedgerTotal from "./processTrustLedgerTotal";
import ProcessAndSaveTrustLedgerTotalOwing from "./processTrustLedgerTotalOwing";
import useUpdateStatementOfAdjustments from "./useUpdateStatementOfAdjustments";

const useTrustLedgerProcessor = () => {
  const { type } = useParams();
  const db = useFirestore();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);

  const { saveFileToCollection } = useSaveDocumentToCollection("trustLedger");
  const { UpdateStatementOfAdjustments } = useUpdateStatementOfAdjustments();
  const {
    deleteMultipleDocumentsInTrustLedger,
    deleteMultipleDocumentsInTrustLedgerUnlessEditedOrAlreadyDeleted,
  } = useDeleteMultipleDocumentsInTrustLedger("trustLedger");
  const { deleteDocumentInSeperateCollection } =
    useDeleteDocumentInSeperateCollection("trustLedger");
  const { GetCollection } = useCollections();
  const { fetchAllFiles, fetchLawfirmFields } = useSaveFileToDatabase();
  const { UpdateStatementOfAccount } = useStatementOfAccount();

  useEffect(() => {
    fetchAllFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processTrustLedger = async () => {
    let processedData = (await processTrustLedgerReceiptsAndExpenditures()) || [];
    const currentTrustLedgerData = await GetCollection({
      nameOfCollection: "trustLedger",
      locationOfCollection: "currentFile",
    });
    processedData = updateProcessedDataWithUserChangesToEntryIndex({
      processedData,
      currentTrustLedgerData,
    });

    // At this point the processing is done, and we have the Receipts and Expenditures
    /// ///////////////////////////////////////////////////////////////////////////////////////////////
    // We are fetching the currentOwingEntry to get a reference to the edits made
    const currentOwingEntry = await getCurrentOwingEntry();
    // We now delete all the trust ledger entries that have not been regenerated. This is because
    // that must mean they longer exist in the system. So we dont want to regenerate them or keep them in Trust Ledger
    await clearTrustLedgerCollectionData(processedData);

    // We now save the processed data into the Trust Ledger
    await updateTrustLedger(processedData);
    // We now do the calculations on the Trust Ledger data to get the total and the balancing entry of the Trust Ledger
    const finalTotal = await ProcessAndSaveTrustLedgerTotalOwing({
      type,
      trustLedgerData: await GetCollection({
        nameOfCollection: "trustLedger",
        locationOfCollection: "currentFile",
      }),
      saveFileToCollection,
      currentOwingEntry,
    });
    await ProcessAndSaveTrustLedgerTotal({
      saveFileToCollection,
      finalTotal,
    });
  };
  const clearTrustLedgerCollectionData = async (currentProcessedData) => {
    const currentTrustLedgerData = await GetCollection({
      nameOfCollection: "trustLedger",
      locationOfCollection: "currentFile",
    });

    // If there are entries marked edited, but have not been regenerated by the processor, that means they are deleted
    // in LCS, so they SHOULD be deleted even tho they have been edited
    await deleteCurrentDataThatHasNotBeenRegenerated({
      currentTrustLedgerData,
      currentProcessedData,
    });
    await deleteMultipleDocumentsInTrustLedger({
      queryByFieldValue: "trustLedgerTotal",
      queryByFieldName: "processorReference",
    });
    // We will first delete the entries from the trust ledger, so we do not get duplicates
    // Get the trust ledger collection data, delete the ones that have *not been marked edited by the lawclerk*
    await deleteMultipleDocumentsInTrustLedgerUnlessEditedOrAlreadyDeleted({
      queryByFieldValue: "automaticEntry",
      queryByFieldName: "typeOfGeneratedEntry",
    });

    // We now have deleted all the trust ledger entries that are
    // - not manual
    // - not edited by user
  };
  const deleteCurrentDataThatHasNotBeenRegenerated = async ({
    currentTrustLedgerData,
    currentProcessedData,
  }) => {
    currentTrustLedgerData?.forEach(async (currentData) => {
      // loop over the current trust ledger data, and check if they have been regenerated
      // if they have NOT been regenerated, that means we need to delete them, as theyre gone from the system
      if (
        currentData?.processorReference !== "trustLedgerTotal" &&
        currentData?.processorReference !== "routineEntry"
      ) {
        if (
          currentData?.processorReference &&
          !currentProcessedData?.some(
            (processedData) =>
              processedData?.processorReference === currentData?.processorReference,
          )
        ) {
          await deleteMultipleDocumentsInTrustLedger({
            queryByFieldValue: currentData?.processorReference,
            queryByFieldName: "processorReference",
          });
        }
      }
    });
  };
  const updateTrustLedger = async (processedData) => {
    // Save the processed trust ledger entries
    // We only want to save the entries that are :
    // - not marked editedbylawclerk,
    // - not alreadydeleted
    const currentTrustLedgerData = await GetCollection({
      nameOfCollection: "trustLedger",
      locationOfCollection: "currentFile",
    });
    if (processedData && Array.isArray(processedData)) {
      processedData?.forEach(async (processedTrustLedgerEntry) => {
        if (
          !currentTrustLedgerData.some(
            (existingTurstLedgerEntry) =>
              existingTurstLedgerEntry?.processorReference ===
                processedTrustLedgerEntry?.processorReference &&
              existingTurstLedgerEntry?.uniqueID === processedTrustLedgerEntry?.uniqueID,
          )
        ) {
          await callSaveFileToCollection(processedTrustLedgerEntry);
        }
      });
    }
  };
  const callSaveFileToCollection = async (processedTrustLedgerEntry) => {
    await saveFileToCollection({ formData: processedTrustLedgerEntry });
  };
  const getCurrentOwingEntry = async () => {
    const trustLedgerCollection = await GetCollection({
      nameOfCollection: "trustLedger",
      locationOfCollection: "currentFile",
    });
    return trustLedgerCollection?.find((entry) => {
      return (
        entry.processorReference === "trustLedgerTotal_owing_receipt" ||
        entry.processorReference === "trustLedgerTotal_owing_expenditure"
      );
    });
  };

  const fetchValidTrustLedgerRoutineEntries = async () => {
    const lawFirmDocRef = doc(db, `lawFirm/${lawFirmID}`);
    const data = (await getDoc(lawFirmDocRef)).data();
    const dataToSave = data?.TrustLedgerRoutineEntries;
    const fileData = await fetchAllFiles();

    return dataToSave?.filter((routineEntry) => {
      return (
        routineEntry?.relatedToFileTypesArrayOfValues?.includes(type) &&
        HnHHandleDate(routineEntry?.creationDate) <
          HnHHandleDate(fileData?.createFile?.dateFileCreated)
      );
    });
  };

  const resetEditedToOriginalRoutineEntry = async (entryToReset) => {
    const entryAfterReset = entryToReset?.originalData;
    if (entryAfterReset) {
      entryAfterReset.editedByUser = false;
      await deleteDocumentInSeperateCollection({
        uniqueID: entryToReset.uniqueID,
      });
      await saveFileToCollection({
        formData: entryAfterReset,
      });
    }
  };

  const processTrustLedgerReceiptsAndExpenditures = async () => {
    let processedData = [];
    processedData = await ProcessBillsHoldbacks({
      dataToProcess: await GetCollection({
        nameOfCollection: "billsHoldbacks",
        locationOfCollection: "currentFile",
      }),
      processedData,
    });
    processedData = await ProcessTrustledgerRoutineEntries({
      dataToProcess: await fetchValidTrustLedgerRoutineEntries(),
      processedData,
    });
    if (type === "Purchase") {
      let landTransferTaxAppearsIn = await fetchLawfirmFields().then((data) => {
        return data?.landTransferTaxAppearsIn;
      });
      if (landTransferTaxAppearsIn !== "statementofAccount") {
        processedData = await ProcessLandTransferTax({
          dataToProcess: await fetchAllFiles(),
          processedData,
        });
      }
    }

    processedData = await ProcessNewMortgages({
      dataToProcess: await fetchAllFiles(),
      processedData,
    });
    processedData = await ProcessExistingEncumbrances({
      dataToProcess: await fetchAllFiles(),
      processedData,
      currentFile: await fetchAllFiles(),
    });
    processedData = await ProcessPropertyTax({
      dataToProcess: await fetchAllFiles(),
      processedData,
    });
    processedData = await ProcessPropertyDeposits({
      dataToProcess: await fetchAllFiles(),
      processedData,
    });
    processedData = await ProcessFireInsuranceBrokerFee({
      dataToProcess: await fetchAllFiles(),
      processedData,
    });
    processedData = await ProcessBrokers({
      dataToProcess: await fetchAllFiles(),
      processedData,
      typeOfFile: type,
    });
    await UpdateStatementOfAccount();
    processedData = await ProcessStatementOfAccount({
      dataToProcess: await fetchAllFiles(),
      lawFirm: await fetchLawfirmFields(),
      processedData,
    });
    await UpdateStatementOfAdjustments({
      currentFile: await fetchAllFiles(),
    });
    const statementOfAdjustmentTrustLedgerEntry = await processStatementOfAdjustments({
      dataToProcess: await fetchAllFiles(),
      processedData,
      typeOfFile: type,
    });

    if (statementOfAdjustmentTrustLedgerEntry)
      processedData = processedData.concat(statementOfAdjustmentTrustLedgerEntry);
    return processedData;
  };
  const updateProcessedDataWithUserChangesToEntryIndex = ({
    processedData,
    currentTrustLedgerData,
  }) => {
    let trustLedgerDataWithEntryIndexesFromUser = processedData?.map((entry) => {
      let theMatchingEntryFromTheCurrentTrustLedger = currentTrustLedgerData?.find(
        (x) =>
          x?.processorReference === entry?.processorReference && x?.uniqueID === entry?.uniqueID,
      );

      return {
        ...entry,
        ...(theMatchingEntryFromTheCurrentTrustLedger &&
          theMatchingEntryFromTheCurrentTrustLedger.userSpecifiedIndex >= 0 && {
            userSpecifiedIndex: theMatchingEntryFromTheCurrentTrustLedger.userSpecifiedIndex,
          }),
      };
    });

    return trustLedgerDataWithEntryIndexesFromUser;
  };
  return {
    processTrustLedger,
    resetEditedToOriginalRoutineEntry,
  };
};
export default useTrustLedgerProcessor;
