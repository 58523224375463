/* eslint-disable @typescript-eslint/no-unused-vars */
import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { saveAs } from "file-saver";
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { dummyFilesLawFirmID } from "src/config/my-templates-config";
import HnHReactCreatableReactSelectWithLabel from "src/new-folder-structure/components/molecules/creatable-react-select/creatable-react-select.component";
import DummyFiles from "src/old-folder-structure/molecules/dummy-files-dropdown/dummy-files-dropdown.component";
import useGenerateDocument from "src/old-folder-structure/sharedComponents/generateDocument/useGenerateDocument";

import EnsureCurlyBracketsAreClosedForDocumentTemplateName from "src/reusable/EnsureCurlyBracketsAreClosedForDocumentTemplateName";
import FileUploadDropzone from "src/reusable/FileUploadDropzone";
import HandleRemovingSlashFromTemplateDocumentName from "src/reusable/HandleRemovingSlashFromTemplateDocumentName";
import HnHReactSelectWithLabel from "src/reusable/HnHReactSelectWithLabel";
import HnHSelectInput from "src/reusable/HnHSelectInput";
import HnHToggleWithLabel from "src/reusable/HnHToggleWithLabel";
import useGetCollection from "src/reusable/firestoreHooks/useGetCollection";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";
import { Base64toBlob, blobToBase64 } from "../../../../reusable/base64ToBlob";
import { trpc } from "../../../../shared/utils/trpc";
import "./custom.css";

const lcsCategories = [
  {
    label: "Commercial",
    value: "Commercial",
  },
  {
    label: "Early Stages in File",
    value: "Early Stages in File",
  },
  {
    label: "Preparation for Closing",
    value: "Preparation for Closing",
  },
  {
    label: "Follow-up Letters",
    value: "Follow-up Letters",
  },
  {
    label: "Closing Documents",
    value: "Closing Documents",
  },
  {
    label: "Vacant Land",
    value: "Vacant Land",
  },
  {
    label: "Corporate",
    value: "Corporate",
  },
  {
    label: "Builder",
    value: "Builder",
  },
];

const AddEditSpecificTemplateModal = ({ showModal, setShowModal, templateData, refetch }) => {
  const methods = useForm({
    defaultValues: {
      ...templateData,
      relatedToFileTypes: templateData?.relatedToFileTypes?.[0].value,
      hasDefaultFont: templateData?.hasDefaultFont ? "yes" : "no",
    },
  });
  const {
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [uploadedDocument, setUploadedDocument] = useState();
  const [fontsList, setFontsList] = useState([]);
  const [templateUrl, setTemplateUrl] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [generatedFile, setGeneratedFile] = useState();
  const [generatedDocMessage, setGeneratedDocMessage] = useState({
    type: "",
    message: "",
  });
  const { generating, linkToGenerateDocumentGuide } = useGenerateDocument();
  const db = useFirestore();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser.lawFirmID);
  const specificTemplatesCollectionRef = collection(db, `lawFirm/${lawFirmID}/specificTemplates`);
  const specificCategoriesCollectionRef = collection(db, `lawFirm/${lawFirmID}/specificCategories`);
  const { fetchFontsList, createNewSpecificFont } = useSaveFileToDatabase();
  // eslint-disable-next-line no-unused-vars
  const { data: specificCategories, status: specificCategoriesStatus } = useFirestoreCollectionData(
    specificCategoriesCollectionRef,
  );
  const { collectionData: billsAndHoldbacks } = useGetCollection({
    nameOfCollection: "billsHoldbacks",
  });
  const { mutateAsync: getUrl } = trpc.manageStorage.getDownloadUrl.useMutation();
  const { mutateAsync: uploadBytesToStorage } =
    trpc.manageStorage.uploadBytesToStorage.useMutation();

  const utils = trpc.useContext();
  const multiple = watch("multiple");
  // const hasDefaultFont = watch("hasDefaultFont");
  const multipleCollection = watch("collection");
  const testFile = watch("testFile");
  useEffect(() => {
    getAllFontsFromDB();

    return () => {
      utils.manageStorage.getDownloadUrl.invalidate();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setAllCategories([...lcsCategories, ...(specificCategories ? specificCategories : [])]);
  }, [specificCategories]);

  useEffect(() => {
    async function fetchData() {
      if (templateData) {
        let response = await getUrl({
          path: `lawFirms/${lawFirmID}/specificTemplates/${templateData.id}.docx`,
        });
        if (response?.url) {
          setTemplateUrl(response.url);
        } else {
          console.error("Error while fetching document: ", response);
        }
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]);
  const { mutateAsync: validateTemplate, isLoading: isLoadingGenerateDocument } =
    trpc.lawFirmTemplate.validateTemplate.useMutation();
  const generateDocx = async () => {
    setGeneratedDocMessage({ type: "", message: "" });
    setGeneratedFile("");
    if ((uploadedDocument || templateUrl) && selectedFile) {
      const fileToUpload = uploadedDocument ? await blobToBase64(uploadedDocument) : "";
      try {
        const data = await validateTemplate({
          fileId: selectedFile.id,
          lawFirmId: lawFirmID,
          ...(uploadedDocument && {
            selectedTemplate: fileToUpload.replace(
              "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
              "",
            ),
          }),
          ...(uploadedDocument && { collection: multipleCollection }),
          ...(uploadedDocument && { multiple }),
          ...(templateUrl &&
            !uploadedDocument && {
              templateData: {
                ...templateData,
                templateId: templateData.id,
                multipleEntityId: 0,
                category: templateData.category.map((c) => (typeof c === "string" ? c : c.label)),
              },
            }),
        });

        const blob = Base64toBlob(data, "testGeneration");

        const file = new File([blob], "generatedDocument", {
          type: blob.type,
        });

        setGeneratedFile(file);
        setGeneratedDocMessage({
          type: "success",
          message: "Document generated successfully",
        });
      } catch (e) {
        setGeneratedDocMessage({
          type: "danger",
          message: "Error while generating document",
        });
      }
    }
  };
  useEffect(() => {
    generateDocx();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFile,
    uploadedDocument,
    templateUrl,
    multipleCollection,
    billsAndHoldbacks,
    multiple,
  ]);
  const getAllFontsFromDB = async () => {
    const fonts = await fetchFontsList();

    setFontsList(fonts);
  };
  const onSubmit = (formData) => {
    delete formData.testFile;
    // const defaultFont = getValues("defaultFont");
    // if (hasDefaultFont === "yes") {
    //   if (fontsList.every((font) => font.value !== defaultFont.value)) {
    //     createNewSpecificFont(defaultFont);
    //     getAllFontsFromDB();
    //   }
    // }
    if (templateData) editTemplate(formData);
    else addTemplate(formData);
  };
  const editTemplate = async (formData) => {
    setSaving(true);
    setErrorMessage("");
    try {
      const specificTemplateRef = doc(db, `lawFirm/${lawFirmID}/specificTemplates/${formData.id}`);
      let fileMetaData = {
        ...formData,
        documentName: HandleRemovingSlashFromTemplateDocumentName(formData.documentName),
      };
      delete fileMetaData.fileToUpload;
      fileMetaData.fileName = `${templateData?.id}.docx`;
      fileMetaData.fullPath = `lawFirms/${lawFirmID}/specificTemplates/${templateData?.id}.docx`;
      fileMetaData.multiple = formData?.multiple;
      fileMetaData.category = formData?.category.map((el) =>
        typeof el === "string" ? el : el.label,
      );
      // fileMetaData.hasDefaultFont = formData?.hasDefaultFont === "yes";
      fileMetaData.relatedToFileTypes = [
        {
          label:
            formData?.relatedToFileTypes &&
            formData?.relatedToFileTypes[0].toUpperCase() + formData?.relatedToFileTypes.slice(1),
          value: formData?.relatedToFileTypes,
        },
      ];
      if (formData.fileToUpload?.type) {
        // const storageRef = ref(
        //   storage,
        //   `lawFirms/${lawFirmID}/specificTemplates/${templateData.id}.docx`,
        // );
        // await uploadBytes(storageRef, formData.fileToUpload);
        const base64String = await blobToBase64(formData.fileToUpload);

        uploadBytesToStorage({
          pathToStorage: `lawFirms/${lawFirmID}/specificTemplates/${templateData.id}.docx`,
          fileToUpload: base64String.replace(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
            "",
          ),
        });

        await setDoc(specificTemplateRef, { ...fileMetaData });
      } else {
        await setDoc(specificTemplateRef, { ...fileMetaData });
      }

      reset();
      setShowModal(false);
    } catch (error) {
      if (error?.message?.toLowerCase()?.includes("failed to fetch"))
        setErrorMessage("There was an error when submitting. Please click Save to try again.");
      else setErrorMessage(error?.message);
    }
    refetch();
    setSaving(false);
  };
  const addTemplate = async (formData) => {
    setSaving(true);
    setErrorMessage("");
    try {
      let fileMetaData = {
        ...formData,
        documentName: HandleRemovingSlashFromTemplateDocumentName(formData.documentName),
      };
      const uploadedFile = fileMetaData.fileToUpload;
      delete fileMetaData.fileToUpload;
      fileMetaData.multiple = formData?.multiple;
      fileMetaData.category = formData?.category.map((el) => el.label);
      // fileMetaData.hasDefaultFont = formData?.hasDefaultFont === "yes";
      fileMetaData.templateOrigin = "My Law Firm";
      fileMetaData.templateType = "DOCX";
      fileMetaData.relatedToFileTypes = [
        {
          label:
            formData?.relatedToFileTypes &&
            formData?.relatedToFileTypes[0].toUpperCase() + formData?.relatedToFileTypes.slice(1),
          value: formData?.relatedToFileTypes,
        },
      ];
      let resp = await addDoc(specificTemplatesCollectionRef, {
        ...fileMetaData,
      });
      if (resp?.id) {
        const specificTemplateId = doc(db, `lawFirm/${lawFirmID}/specificTemplates/${resp.id}`);
        // const storageRef = ref(storage, `lawFirms/${lawFirmID}/specificTemplates/${resp.id}.docx`);
        // await uploadBytes(storageRef, uploadedFile);
        const base64String = await blobToBase64(uploadedFile);

        uploadBytesToStorage({
          pathToStorage: `lawFirms/${lawFirmID}/specificTemplates/${resp.id}.docx`,
          fileToUpload: base64String.replace(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
            "",
          ),
        });
        await updateDoc(specificTemplateId, {
          id: resp.id,
          fileName: `${resp.id}.docx`,
          fullPath: `lawFirms/${lawFirmID}/specificTemplates/${resp.id}.docx`,
        });
      }
      reset();
      setShowModal(false);
    } catch (error) {
      if (error?.message?.toLowerCase()?.includes("failed to fetch"))
        setErrorMessage("There was an error when submitting. Please click Save to try again.");
      else setErrorMessage(error?.message);
    }
    refetch();
    setSaving(false);
  };
  useEffect(() => {
    if (testFile) {
      const fetchData = async (value) => {
        const fileRef = doc(db, `lawFirm/${dummyFilesLawFirmID}/files/${value}`);
        const fileData = (await getDoc(fileRef)).data();

        setSelectedFile(fileData);
      };

      // call the function
      fetchData(testFile)
        // make sure to catch any error
        .catch(console.error);
    }
  }, [db, testFile]);
  return (
    <>
      <FormProvider {...methods}>
        <CForm className="form-horizontal needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <CModal
            show={showModal}
            onClose={() => setShowModal(false)}
            color="primary"
            size="xl"
            backdrop={true}
            className="modal-100-view"
            closeOnBackdrop={false}
          >
            <CModalHeader closeButton>
              <CModalTitle>
                {templateData ? "Edit your own document" : "Add your own document"}
              </CModalTitle>
            </CModalHeader>
            {errorMessage ? <CAlert color="danger">{errorMessage}</CAlert> : ""}
            <CModalBody>
              <CFormGroup row>
                <CCol md="6">
                  <CCardBody>
                    <h4>File Details</h4>
                    <CFormGroup row>
                      <CCol md="3">
                        <CLabel>Document name</CLabel>
                      </CCol>
                      <CCol md="9">
                        <input
                          className={`form-control ${
                            !_.isEmpty(errors?.documentName) ? "is-invalid" : ""
                          }`}
                          {...register(`documentName`, {
                            validate: {
                              closingCurlyBrackets: (value) =>
                                EnsureCurlyBracketsAreClosedForDocumentTemplateName(value) ||
                                "Please ensure to close all curly brackets '}}'",
                              nameIncludesForwardSlash: (value) =>
                                !value.includes("/") || 'The name cannot include a "/" symbol',
                              nameIncludesBackSlash: (value) =>
                                !value.includes(`\\`) || 'The name cannot include a "\\" symbol',
                            },

                            required: {
                              value: true,
                              message: `Document name is required`,
                            },
                          })}
                        />
                        {errors?.documentName?.message && (
                          <div className="display-invalid-feedback">
                            {errors?.documentName?.message}
                          </div>
                        )}
                      </CCol>
                    </CFormGroup>
                    <HnHToggleWithLabel
                      label="Document type"
                      name="filters.letterOrNonLetter"
                      buttons={[
                        {
                          name: "Letter",
                          value: "Letter",
                        },
                        {
                          name: "Non-letter",
                          value: "Non-letter",
                        },
                      ]}
                      modal
                    />
                    <HnHReactCreatableReactSelectWithLabel
                      label="Category"
                      name={"category"}
                      required
                      modal
                      isMulti
                      options={allCategories}
                      underlyingText="Search or create new by typing in this field"
                      onCreateOption={async (value) => {
                        try {
                          const category = getValues("category");
                          setValue("category", [
                            ...category,
                            {
                              label: value,
                              value,
                            },
                          ]);
                          await addDoc(specificCategoriesCollectionRef, {
                            label: value,
                            value,
                          });
                        } catch (error) {
                          setValue("category", "");
                        }
                      }}
                    />
                    {/*<HnHToggleWithLabel*/}
                    {/*  name="hasDefaultFont"*/}
                    {/*  label="Specify default font for this generated document?"*/}
                    {/*  buttons={[*/}
                    {/*    { value: "yes", name: "Yes" },*/}
                    {/*    { value: "no", name: "No" },*/}
                    {/*  ]}*/}
                    {/*  modal*/}
                    {/*/>*/}
                    {/*{hasDefaultFont === "yes" ? (*/}
                    {/*  <CFormGroup row>*/}
                    {/*    <CCol md="3">*/}
                    {/*      <CLabel>*/}
                    {/*        Select default font for generated document*/}
                    {/*      </CLabel>*/}
                    {/*    </CCol>*/}
                    {/*    <CCol md="9">*/}
                    {/*      <HnHReactCreatableReactSelectWithLabel*/}
                    {/*        name="defaultFont"*/}
                    {/*        options={fontsList}*/}
                    {/*        onCreateOption={async (value) => {*/}
                    {/*          try {*/}
                    {/*            setValue("defaultFont", {*/}
                    {/*              label: value,*/}
                    {/*              value,*/}
                    {/*            });*/}
                    {/*          } catch (error) {*/}
                    {/*            setValue("defaultFont", "");*/}
                    {/*          }*/}
                    {/*        }}*/}
                    {/*      />*/}
                    {/*    </CCol>*/}
                    {/*  </CFormGroup>*/}
                    {/*) : (*/}
                    {/*  <></>*/}
                    {/*)}*/}
                    <HnHToggleWithLabel
                      name="relatedToFileTypes"
                      label="Related to file type(s)"
                      required
                      buttons={[
                        { value: "purchase", name: "Purchase" },
                        { value: "sale", name: "Sale" },
                        { value: "mortgage", name: "Mortgage" },
                      ]}
                      modal
                    />
                    <HnHToggleWithLabel
                      name="multiple"
                      label="Are there multiples of this document"
                      required
                      buttons={[
                        { value: "yes", name: "Yes" },
                        { value: "no", name: "No" },
                      ]}
                      modal
                    />
                    {multiple === "yes" ? (
                      <>
                        <CFormGroup row>
                          <CCol md="3">
                            <CLabel>Collection</CLabel>
                          </CCol>
                          <CCol md="9">
                            <HnHSelectInput name="collection" required label="Collection">
                              <option value="">Please select</option>
                              <option value="encumbrances">Existing Encumbrances</option>
                              <option value="vtbMortgages">VTB Mortgages</option>
                              <option value="newMortgages">New Mortgages</option>
                              <option value="executionAffidavits">Execution Affidavits</option>
                              <option value="billsHoldbacks">Bills and holdbacks</option>
                              <option value="purchasers">Purchasers</option>
                              <option value="vendors">Vendors</option>
                              <option value="mortgagors">Mortgagors</option>
                            </HnHSelectInput>
                          </CCol>
                        </CFormGroup>
                      </>
                    ) : (
                      <></>
                    )}
                    {multiple === "yes" && multipleCollection === "encumbrances" ? (
                      <>
                        <HnHReactSelectWithLabel
                          label="Encumbrance is to"
                          name="filters.encumbranceIsTo"
                          options={[
                            {
                              label: "Remain",
                              value: "remain",
                            },
                            { label: "Be assumed", value: "be assumed" },
                            {
                              label: "Be discharged",
                              value: "be discharged",
                            },
                          ]}
                          isMulti
                          modal
                        />
                        <HnHToggleWithLabel
                          label="Category"
                          name="filters.encumbranceCategory"
                          buttons={[
                            {
                              name: "All encumbrances",
                              value: "allEncumbrances",
                            },
                            {
                              name: "Existing mortgages only",
                              value: "existingMortgagesOnly",
                            },
                          ]}
                          modal
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {multiple === "yes" && multipleCollection === "billsHoldbacks" ? (
                      <HnHReactSelectWithLabel
                        label="Category"
                        name="filters.billsHoldbacksCategory"
                        options={[
                          {
                            label: "Holdback",
                            value: "holdback",
                          },
                          { label: "Bill / Debt", value: "billOrdebt" },
                          {
                            label: "Tax arrears",
                            value: "taxArrears",
                          },
                          {
                            label: "Water arrears",
                            value: "waterArrears",
                          },
                          {
                            label: "Fire inspection",
                            value: "fireInspection",
                          },
                          {
                            label: "Zoning report",
                            value: "zoningReport",
                          },
                        ]}
                        isMulti
                        modal
                      />
                    ) : (
                      ""
                    )}

                    {multiple === "yes" && multipleCollection === "newMortgages" ? (
                      <HnHReactSelectWithLabel
                        label="Category"
                        name="filters.newMortgagesCategory"
                        options={[
                          {
                            label: "New Mortgage",
                            value: "new mortgage",
                          },
                          { label: "Bridge Loan", value: "bridge loan" },
                          {
                            label: "Assumed Mortgage",
                            value: "assumed mortgage",
                          },
                          {
                            label: "Vendor take-back mortgage",
                            value: "vendor take back mortgage",
                          },
                        ]}
                        isMulti
                        modal
                      />
                    ) : (
                      ""
                    )}
                    {multiple === "yes" && multipleCollection === "executionAffidavits" ? (
                      <HnHReactSelectWithLabel
                        label="Declaration type"
                        name="filters.declarationType"
                        options={[
                          {
                            label: "Client (less than $50,000)",
                            value: "Client",
                          },
                          {
                            label: "Solicitor (equal or greater than $50,000)",
                            value: "Solicitor",
                          },
                        ]}
                        isMulti
                        modal
                      />
                    ) : (
                      ""
                    )}
                    {multiple === "yes" &&
                    (multipleCollection === "purchasers" ||
                      multipleCollection === "vendors" ||
                      multipleCollection === "mortgagors") ? (
                      <HnHReactSelectWithLabel
                        label="Client type"
                        name="filters.clientType"
                        options={[
                          { label: "Person", value: "person" },
                          { label: "Corporation", value: "corporation" },
                          { label: "Estate", value: "estate" },
                          {
                            label: "Person (Power of Attorney)",
                            value: "personPowerOfAttorney",
                          },
                        ]}
                        isMulti
                        modal
                      />
                    ) : (
                      ""
                    )}
                    <input hidden value="My Law Firm" {...register("fileType")} />
                  </CCardBody>
                </CCol>
                {/* <div style="border-left:1px solid #000;height:500px" /> */}
                <CCol md="6 pageList">
                  <CCardBody>
                    <h4>How to use this page?</h4>
                    <ol>
                      <li>
                        Refer to the Build your own guide by clicking{" "}
                        <a
                          href={linkToGenerateDocumentGuide}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <b>
                            <u>here</u>
                          </b>
                        </a>
                      </li>
                      <li>Provide the File Details on the left</li>
                      <li>Select a test file in the Test file drop down</li>
                      <li>
                        Create your .docx template in Word and add mappings where you need dynamic
                        fields to populate
                      </li>
                      <li>
                        Drag and drop your completed template in the Upload File box below and a
                        sample of the final product will be generated
                      </li>
                      <li>Review the sample</li>
                      <li>
                        Modify, save and reupload the template until the generated sample is
                        satisfactory
                      </li>
                      <li>Once satisfactory, click save</li>
                    </ol>
                  </CCardBody>
                </CCol>
              </CFormGroup>
              <hr />
              <h4>Add Template</h4>

              <CFormGroup row className="justify-content-center">
                <CCol md="10">
                  <div className="templateSection">
                    <CFormGroup row className="m-0 mb-3">
                      <DummyFiles required name="testFile" />
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol md="4">
                        {uploadedDocument ? (
                          <div>
                            Template currently uploaded:
                            <CButton
                              onClick={() => {
                                saveAs(
                                  uploadedDocument,
                                  uploadedDocument?.name || "uploadedDocument",
                                );
                              }}
                              shape="rounded-0"
                              color="link"
                            >
                              Click to download
                            </CButton>
                          </div>
                        ) : templateUrl ? (
                          <div>
                            File Uploaded:{" "}
                            <a
                              href={templateUrl}
                              target="_blank"
                              rel="noreferrer"
                              download={templateUrl}
                            >
                              Click to download
                            </a>
                          </div>
                        ) : null}
                      </CCol>
                      <CCol md="4">
                        {generatedFile && generatedDocMessage?.type === "success" && (
                          <div>
                            Generated sample:
                            <CButton
                              onClick={() => {
                                saveAs(generatedFile, "generatedDoc");
                              }}
                              shape="rounded-0"
                              color="link"
                            >
                              Click to download
                            </CButton>
                          </div>
                        )}
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs="12" md="12">
                        <FileUploadDropzone
                          name="fileToUpload"
                          required={!templateData}
                          accept=".docx"
                          fileUploaded={(file) => {
                            if (file) setUploadedDocument(file);
                          }}
                        />
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs="12" md="12">
                        {generatedDocMessage?.message ? (
                          <CAlert color={generatedDocMessage?.type}>
                            {generatedDocMessage?.message}
                          </CAlert>
                        ) : (
                          ""
                        )}
                      </CCol>
                    </CFormGroup>
                  </div>
                </CCol>
              </CFormGroup>
            </CModalBody>

            <CModalFooter>
              <CButton
                type="submit"
                disabled={
                  saving ||
                  isLoadingGenerateDocument ||
                  (generatedDocMessage?.type !== "" && generatedDocMessage?.type !== "success")
                }
                color="primary"
              >
                {saving ? (
                  "Saving..."
                ) : (
                  <>
                    <CIcon name="cil-pencil" alt="Settings" /> Save
                  </>
                )}{" "}
              </CButton>
              <CButton
                disabled={isLoadingGenerateDocument}
                color="secondary"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  reset();
                }}
              >
                Cancel
              </CButton>
            </CModalFooter>
          </CModal>
        </CForm>
      </FormProvider>
    </>
  );
};

export default AddEditSpecificTemplateModal;
