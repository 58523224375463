import currency from "currency.js";
import Money from "src/reusable/Money";

const ProcessTrustLedgerTableEntries = (TableEntries) => {
  if (TableEntries && Array.isArray(TableEntries)) {
    // We are filtering the table entries to remove the ones that were deleted by the user
    let filteredTableEntries = TableEntries?.filter(
      (e) => e?.deletedByUser !== true
    );
    let processedData = filteredTableEntries?.map((e) => {
      let expenditureCurrency;
      let receiptCurrency;
      if (e?.trustLedgerItem !== "Total") {
        if (e?.trustLedgerItemization?.length > 0) {
          if (e?.type === "expenditure") {
            expenditureCurrency = Money(e?.total);
            receiptCurrency = "";
          } else if (e?.type === "receipt") {
            expenditureCurrency = "";
            receiptCurrency = Money(e?.total);
          }
        } else {
          expenditureCurrency =
            currency(e?.trustLedgerExpenditureAmount).value >
            currency(e?.trustLedgerReceiptAmount).value
              ? Money(e?.trustLedgerExpenditureAmount)
              : "";
          receiptCurrency =
            currency(e?.trustLedgerReceiptAmount).value >
            currency(e?.trustLedgerExpenditureAmount).value
              ? Money(e?.trustLedgerReceiptAmount)
              : "";
        }
      } else {
        expenditureCurrency = Money(e?.trustLedgerExpenditureAmount);
        receiptCurrency = Money(e?.trustLedgerReceiptAmount);
      }
      return {
        ...e,
        expenditureCurrency: expenditureCurrency,
        receiptCurrency: receiptCurrency,
      };
    });
    return processedData;
  } else return [];
};

export default ProcessTrustLedgerTableEntries;
