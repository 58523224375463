import currency from "currency.js";

const ProcessBrokers = async ({ dataToProcess, processedData, typeOfFile }) => {
  const currentFileData = dataToProcess;
  const brokerData = dataToProcess?.brokers;

  if (!brokerData) return processedData;
  if (brokerData) {
    let listOfBrokersTrustLedgerEntries = [];
    const vendorsPaidTo = brokerData?.vendorsPaidTo;

    const commissionWithHST = brokerData?.commissionWithHST;
    const purchasersCommissionWithHST = brokerData?.purchasersCommissionWithHST;
    const vendorsBrokerage = brokerData?.vendorBrokerage?.[0]?.brokerage?.name || "";
    const purchasersBrokerage = brokerData?.purchaserBrokerage?.[0]?.brokerage?.name || "";
    const useDepositAmountToPayCommission = brokerData?.useDepositAmountToPayCommission;
    const purchasersUseDepositAmountToPayCommission =
      brokerData?.purchasersUseDepositAmountToPayCommission;
    const expenditureOrReceipt = brokerData?.expenditureOrReceipt;
    const purchaserExpenditureOrReceipt = brokerData?.purchaserExpenditureOrReceipt;
    const addSperateEntries = brokerData?.seperateComissionPaymentsInTrustLedger;
    if (typeOfFile === "Sale") {
      const realEstateBrokersBeingUsed = currentFileData?.property?.realEstateBrokersBeingUsed;
      if (realEstateBrokersBeingUsed === "yes") {
        let vendorType = "";
        let vendorTrustLedgerExpenditureAmount = "";
        let vendorTrustLedgerReceiptAmount = "";
        let purchaserType = "";
        let purchaserTrustLedgerExpenditureAmount = "";
        let purchaserTrustLedgerReceiptAmount = "";
        if (vendorsPaidTo === "vendorsBrokerOnly" && commissionWithHST !== "NaN") {
          if (useDepositAmountToPayCommission === "yes") {
            const totalCommissionAmountAsNumber = currency(brokerData?.totalCommissionAmount).value;
            const commissionWithHSTAbsValue = Math.abs(totalCommissionAmountAsNumber);
            if (totalCommissionAmountAsNumber < 0 || expenditureOrReceipt === "receipt") {
              vendorType = "receipt";
              vendorTrustLedgerReceiptAmount = commissionWithHSTAbsValue;
              vendorTrustLedgerExpenditureAmount = "";
            } else if (
              totalCommissionAmountAsNumber > 0 ||
              expenditureOrReceipt === "expenditure"
            ) {
              vendorType = "expenditure";
              vendorTrustLedgerExpenditureAmount = commissionWithHSTAbsValue;
              vendorTrustLedgerReceiptAmount = "";
            }
          } else {
            const commissionAbsValue = Math.abs(currency(commissionWithHST).value);
            const commissionWithHSTAsNumber = currency(commissionWithHST).value;
            if (commissionWithHSTAsNumber < 0 || expenditureOrReceipt === "receipt") {
              vendorType = "receipt";
              vendorTrustLedgerExpenditureAmount = "";
              vendorTrustLedgerReceiptAmount = commissionAbsValue;
            } else if (commissionWithHSTAsNumber > 0 || expenditureOrReceipt === "expenditure") {
              vendorType = "expenditure";
              vendorTrustLedgerExpenditureAmount = commissionAbsValue;
              vendorTrustLedgerReceiptAmount = "";
            }
          }
          if (
            currency(vendorTrustLedgerReceiptAmount).value !== 0 ||
            currency(vendorTrustLedgerExpenditureAmount).value !== 0
          )
            listOfBrokersTrustLedgerEntries = listOfBrokersTrustLedgerEntries.concat({
              trustLedgerItem:
                vendorType === "receipt"
                  ? `Received excess deposit amount from Vendor's broker (${vendorsBrokerage})`
                  : `Paid to Vendor's real estate broker (${vendorsBrokerage})`,
              type: vendorType,
              trustLedgerExpenditureAmount: vendorTrustLedgerExpenditureAmount,
              trustLedgerReceiptAmount: vendorTrustLedgerReceiptAmount,
              uniqueID: brokerData?.uniqueID,
              processorReference: "realtorComission_vendorsBrokerOnly",
              typeOfGeneratedEntry: "automaticEntry",
            });
        } else if (
          vendorsPaidTo === "purchasersBrokerOnly" &&
          purchasersCommissionWithHST !== "NaN"
        ) {
          if (purchasersUseDepositAmountToPayCommission === "yes") {
            const purchaserTotalCommissionAmountAsNumber = currency(
              brokerData?.purchasersTotalCommissionAmount,
            ).value;
            const purchasersCommissionWithHSTAbsValue = Math.abs(
              purchaserTotalCommissionAmountAsNumber,
            );

            if (
              purchaserTotalCommissionAmountAsNumber < 0 ||
              purchaserExpenditureOrReceipt === "receipt"
            ) {
              purchaserType = "receipt";
              purchaserTrustLedgerReceiptAmount = purchasersCommissionWithHSTAbsValue;
              purchaserTrustLedgerExpenditureAmount = "";
            } else if (
              purchaserTotalCommissionAmountAsNumber > 0 ||
              purchaserExpenditureOrReceipt === "expenditure"
            ) {
              purchaserType = "expenditure";
              purchaserTrustLedgerExpenditureAmount = purchasersCommissionWithHSTAbsValue;
              purchaserTrustLedgerReceiptAmount = "";
            }
          } else {
            const purchaserCommissionAbsValue = Math.abs(
              currency(purchasersCommissionWithHST).value,
            );
            const purchaserCommissionWithHSTAsNumber = currency(purchasersCommissionWithHST).value;

            if (
              purchaserCommissionWithHSTAsNumber < 0 ||
              purchaserExpenditureOrReceipt === "receipt"
            ) {
              purchaserType = "receipt";
              purchaserTrustLedgerExpenditureAmount = "";
              purchaserTrustLedgerReceiptAmount = purchaserCommissionAbsValue;
            } else if (
              purchaserCommissionWithHSTAsNumber > 0 ||
              purchaserExpenditureOrReceipt === "expenditure"
            ) {
              purchaserType = "expenditure";
              purchaserTrustLedgerExpenditureAmount = purchaserCommissionAbsValue;
              purchaserTrustLedgerReceiptAmount = "";
            }
          }
          if (
            currency(purchaserTrustLedgerReceiptAmount).value !== 0 ||
            currency(purchaserTrustLedgerExpenditureAmount).value !== 0
          )
            listOfBrokersTrustLedgerEntries = listOfBrokersTrustLedgerEntries.concat({
              type: purchaserType,
              trustLedgerExpenditureAmount: purchaserTrustLedgerExpenditureAmount,
              trustLedgerItem:
                purchaserType === "receipt"
                  ? `Received excess deposit amount from Purchaser's broker (${purchasersBrokerage})`
                  : `Paid to Purchaser's real estate broker (${purchasersBrokerage})`,
              trustLedgerReceiptAmount: purchaserTrustLedgerReceiptAmount,
              uniqueID: brokerData?.uniqueID,
              processorReference: "realtorComission_purchasersBrokerOnly",
              typeOfGeneratedEntry: "automaticEntry",
            });
        }

        // Vendor section
        else if (
          vendorsPaidTo === "bothBrokers" &&
          purchasersCommissionWithHST !== "NaN" &&
          commissionWithHST !== "NaN"
        ) {
          if (useDepositAmountToPayCommission === "yes") {
            const totalCommissionAmountAsNumber = currency(brokerData?.totalCommissionAmount).value;
            const commissionWithHSTAbsValue = Math.abs(totalCommissionAmountAsNumber);
            if (totalCommissionAmountAsNumber < 0 || expenditureOrReceipt === "receipt") {
              vendorType = "receipt";
              vendorTrustLedgerReceiptAmount = commissionWithHSTAbsValue;
              vendorTrustLedgerExpenditureAmount = "";
            } else if (
              totalCommissionAmountAsNumber > 0 ||
              expenditureOrReceipt === "expenditure"
            ) {
              vendorType = "expenditure";
              vendorTrustLedgerExpenditureAmount = commissionWithHSTAbsValue;
              vendorTrustLedgerReceiptAmount = "";
            }
          } else {
            const commissionAbsValue = Math.abs(currency(commissionWithHST).value);
            const commissionWithHSTAsNumber = currency(commissionWithHST).value;
            if (commissionWithHSTAsNumber < 0 || expenditureOrReceipt === "receipt") {
              vendorType = "receipt";
              vendorTrustLedgerExpenditureAmount = "";
              vendorTrustLedgerReceiptAmount = commissionAbsValue;
            } else if (commissionWithHSTAsNumber > 0 || expenditureOrReceipt === "expenditure") {
              vendorType = "expenditure";
              vendorTrustLedgerExpenditureAmount = commissionAbsValue;
              vendorTrustLedgerReceiptAmount = "";
            }
          }
          if (purchasersUseDepositAmountToPayCommission === "yes") {
            const purchaserTotalCommissionAmountAsNumber = currency(
              brokerData?.purchasersTotalCommissionAmount,
            ).value;
            const purchasersCommissionWithHSTAbsValue = Math.abs(
              purchaserTotalCommissionAmountAsNumber,
            );

            if (
              purchaserTotalCommissionAmountAsNumber < 0 ||
              purchaserExpenditureOrReceipt === "receipt"
            ) {
              purchaserType = "receipt";
              purchaserTrustLedgerReceiptAmount = purchasersCommissionWithHSTAbsValue;
              purchaserTrustLedgerExpenditureAmount = "";
            } else if (
              purchaserTotalCommissionAmountAsNumber > 0 ||
              purchaserExpenditureOrReceipt === "expenditure"
            ) {
              purchaserType = "expenditure";
              purchaserTrustLedgerExpenditureAmount = purchasersCommissionWithHSTAbsValue;
              purchaserTrustLedgerReceiptAmount = "";
            }
          } else {
            const purchaserCommissionAbsValue = Math.abs(
              currency(purchasersCommissionWithHST).value,
            );
            const purchaserCommissionWithHSTAsNumber = currency(purchasersCommissionWithHST).value;

            if (
              purchaserCommissionWithHSTAsNumber < 0 ||
              purchaserExpenditureOrReceipt === "receipt"
            ) {
              purchaserType = "receipt";
              purchaserTrustLedgerExpenditureAmount = "";
              purchaserTrustLedgerReceiptAmount = purchaserCommissionAbsValue;
            } else if (
              purchaserCommissionWithHSTAsNumber > 0 ||
              purchaserExpenditureOrReceipt === "expenditure"
            ) {
              purchaserType = "expenditure";
              purchaserTrustLedgerExpenditureAmount = purchaserCommissionAbsValue;
              purchaserTrustLedgerReceiptAmount = "";
            }
          }

          if (addSperateEntries === "yes") {
            if (
              currency(vendorTrustLedgerReceiptAmount).value !== 0 ||
              currency(vendorTrustLedgerExpenditureAmount).value !== 0
            )
              listOfBrokersTrustLedgerEntries = listOfBrokersTrustLedgerEntries.concat({
                type: vendorType,
                trustLedgerItem:
                  vendorType === "receipt"
                    ? `Received excess deposit amount from Vendor's broker (${vendorsBrokerage})`
                    : `Paid to Vendor's real estate broker (${vendorsBrokerage})`,
                trustLedgerExpenditureAmount: vendorTrustLedgerExpenditureAmount,
                trustLedgerReceiptAmount: vendorTrustLedgerReceiptAmount,
                uniqueID: brokerData?.uniqueID,
                processorReference: "realtorComission_vendorsBrokerOnly",
                typeOfGeneratedEntry: "automaticEntry",
              });
            if (
              currency(purchaserTrustLedgerReceiptAmount).value !== 0 ||
              currency(purchaserTrustLedgerExpenditureAmount).value !== 0
            )
              listOfBrokersTrustLedgerEntries = listOfBrokersTrustLedgerEntries.concat({
                type: purchaserType,
                trustLedgerItem:
                  purchaserType === "receipt"
                    ? `Received excess deposit amount from Purchaser's broker (${purchasersBrokerage})`
                    : `Paid to Purchaser's real estate broker (${purchasersBrokerage})`,
                trustLedgerExpenditureAmount: purchaserTrustLedgerExpenditureAmount,
                trustLedgerReceiptAmount: purchaserTrustLedgerReceiptAmount,
                uniqueID: brokerData?.uniqueID,
                processorReference: "realtorComission_purchasersBrokerOnly",
                typeOfGeneratedEntry: "automaticEntry",
              });
          } else if (addSperateEntries === "no") {
            // brokerData?.purchasersTotalCommissionAmount
            // brokerData?.totalCommissionAmount
            let vendorTotalCommissionAmount = brokerData?.totalCommissionAmount;
            let purchasersTotalCommissionAmount = brokerData?.purchasersTotalCommissionAmount;

            if (brokerData?.expenditureOrReceipt === "receipt") {
              vendorTotalCommissionAmount = currency(vendorTotalCommissionAmount).multiply(
                -1,
              ).value;
            }
            if (brokerData?.purchaserExpenditureOrReceipt === "receipt") {
              purchasersTotalCommissionAmount = currency(purchasersTotalCommissionAmount).multiply(
                -1,
              ).value;
            }

            let totalOfVendorAndPurchaserCommission = 0;
            totalOfVendorAndPurchaserCommission = currency(vendorTotalCommissionAmount).add(
              purchasersTotalCommissionAmount,
            ).value;
            const typeOfCombinedCommission =
              totalOfVendorAndPurchaserCommission > 0 ? "expenditure" : "receipt";
            if (currency(totalOfVendorAndPurchaserCommission).value !== 0)
              listOfBrokersTrustLedgerEntries = listOfBrokersTrustLedgerEntries.concat({
                type: typeOfCombinedCommission,
                trustLedgerItem:
                  typeOfCombinedCommission === "receipt"
                    ? `Received excess deposit amount from Vendor's broker (${vendorsBrokerage})`
                    : `Paid to Vendor's real estate broker (${vendorsBrokerage})`,
                trustLedgerExpenditureAmount:
                  totalOfVendorAndPurchaserCommission > 0
                    ? totalOfVendorAndPurchaserCommission
                    : "",
                trustLedgerReceiptAmount:
                  totalOfVendorAndPurchaserCommission < 0
                    ? Math.abs(totalOfVendorAndPurchaserCommission)
                    : "",
                uniqueID: brokerData?.uniqueID,
                processorReference: "realtorComission_vendorsBrokerOnly",
                typeOfGeneratedEntry: "automaticEntry",
              });
          }
        }
      }
    }
    return processedData.concat(listOfBrokersTrustLedgerEntries);
  }
};

export default ProcessBrokers;
