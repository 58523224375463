import currency from "currency.js";
import HnHMoveInArray from "src/reusable/HnHMoveInArray";

const OrganizeTrustLedgerData = (processedData, fileType) => {
  let finalOrganizedData = [];
  if (processedData && Array.isArray(processedData)) {
    let lastEntry = processedData.find((e) => e.location === "last");
    let arrayReceipts = processedData.filter((e) => e.type === "receipt");
    let arrayExpenditure = processedData.filter((e) => e.type === "expenditure");
    if (arrayReceipts) {
      const organizedReceipts = OrganizeReceipts(arrayReceipts, fileType);
      finalOrganizedData = finalOrganizedData.concat(organizedReceipts);
    }
    if (arrayExpenditure) {
      const organizedExpenditure = OrganizeExpenditures(arrayExpenditure, fileType);
      finalOrganizedData = finalOrganizedData.concat(organizedExpenditure);
    }
    if (lastEntry) {
      finalOrganizedData = finalOrganizedData.concat(lastEntry);
    }
    finalOrganizedData = arrangeByManualUserOrdering(finalOrganizedData);
  }
  return finalOrganizedData;
};

export default OrganizeTrustLedgerData;

const arrangeByManualUserOrdering = (data) => {
  let dataSortedByUserIndexes = [...data];
  let listOfUserSpecifiedIndexes = [];
  data?.forEach((entry) => {
    if (entry.userSpecifiedIndex >= 0) {
      listOfUserSpecifiedIndexes = listOfUserSpecifiedIndexes.concat(entry.userSpecifiedIndex);
    }
  });
  listOfUserSpecifiedIndexes.sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
  listOfUserSpecifiedIndexes?.forEach((userSpecifiedIndex) => {
    const currentIndexOfElement = dataSortedByUserIndexes?.findIndex(
      (e) => e.userSpecifiedIndex === userSpecifiedIndex,
    );
    HnHMoveInArray({
      array: dataSortedByUserIndexes,
      from: currentIndexOfElement,
      to: userSpecifiedIndex,
    });
  });

  return dataSortedByUserIndexes;
};
const OrganizeReceipts = (arrayReceipts, fileType) => {
  if (Array.isArray(arrayReceipts)) {
    let finalRankedTrustLedgerReceipts = [];
    const trustLedgerTotal_owing_receipt = arrayReceipts?.find(
      (e) => e?.processorReference === "trustLedgerTotal_owing_receipt",
    );
    if (fileType === "Purchase" || fileType === "Mortgage") {
      const newMortgageReceipts = arrayReceipts?.filter(
        (e) =>
          e?.processorReference === "newMortgage_appraisalFee" ||
          e?.processorReference === "newMortgage_advanceOfFunds" ||
          e?.processorReference === "newMortgage_mortgageBroker" ||
          e?.processorReference === "newMortgage_lenderFee" ||
          e?.processorReference === "newMortgage_interestAdjusmentAmount" ||
          e?.processorReference === "newMortgage_commitmentFee" ||
          e?.processorReference === "newMortgage_commitmentFee2" ||
          e?.processorReference === "newMortgage_prepaidAmount",
      );
      let otherReceipts = arrayReceipts?.filter(
        (e) =>
          e?.processorReference !== "newMortgage_appraisalFee" &&
          e?.processorReference !== "newMortgage_advanceOfFunds" &&
          e?.processorReference !== "newMortgage_mortgageBroker" &&
          e?.processorReference !== "newMortgage_lenderFee" &&
          e?.processorReference !== "newMortgage_interestAdjusmentAmount" &&
          e?.processorReference !== "newMortgage_commitmentFee" &&
          e?.processorReference !== "newMortgage_commitmentFee2" &&
          e?.processorReference !== "newMortgage_prepaidAmount" &&
          e?.processorReference !== "trustLedgerTotal_owing_receipt",
      );
      if (newMortgageReceipts) {
        const sorted_newMortgageReceipts = newMortgageReceipts?.sort(
          sort_by_priority_and_cost_receipts,
        );

        finalRankedTrustLedgerReceipts = finalRankedTrustLedgerReceipts.concat(
          sorted_newMortgageReceipts,
        );
      }

      if (otherReceipts) {
        otherReceipts = OrganizeOtherEntries(otherReceipts, "receipt");
        finalRankedTrustLedgerReceipts = finalRankedTrustLedgerReceipts.concat(otherReceipts);
      }
    } else if (fileType === "Sale") {
      const statementOfAdjustmentsReceipts = arrayReceipts?.filter(
        (e) => e?.processorReference === "StatementOfAdjustment",
      );
      let otherReceipts = arrayReceipts?.filter(
        (e) =>
          e?.processorReference !== "StatementOfAdjustment" &&
          e?.processorReference !== "trustLedgerTotal_owing_receipt",
      );
      if (statementOfAdjustmentsReceipts) {
        finalRankedTrustLedgerReceipts = finalRankedTrustLedgerReceipts.concat(
          statementOfAdjustmentsReceipts,
        );
      }
      if (otherReceipts) {
        otherReceipts = OrganizeOtherEntries(otherReceipts, "receipt");
        finalRankedTrustLedgerReceipts = finalRankedTrustLedgerReceipts.concat(otherReceipts);
      }
    }
    if (trustLedgerTotal_owing_receipt)
      finalRankedTrustLedgerReceipts = finalRankedTrustLedgerReceipts.concat(
        trustLedgerTotal_owing_receipt,
      );
    return finalRankedTrustLedgerReceipts;
  } else return arrayReceipts;
};

const OrganizeExpenditures = (arrayExpenditures, fileType) => {
  if (Array.isArray(arrayExpenditures)) {
    let finalRankedTrustLedgerExpenditures = [];
    const statement_of_account_expenditure = arrayExpenditures?.find(
      (e) => e?.processorReference === "StatementOfAccount",
    );
    const trustLedgerTotal_owing_expenditure = arrayExpenditures?.find(
      (e) => e?.processorReference === "trustLedgerTotal_owing_expenditure",
    );

    if (fileType === "Sale" || fileType === "Mortgage") {
      const newMortgageExpenditures = arrayExpenditures?.filter(
        (e) =>
          e?.processorReference === "newMortgage_appraisalFee" ||
          e?.processorReference === "newMortgage_advanceOfFunds" ||
          e?.processorReference === "newMortgage_mortgageBroker" ||
          e?.processorReference === "newMortgage_lenderFee" ||
          e?.processorReference === "newMortgage_interestAdjusmentAmount" ||
          e?.processorReference === "newMortgage_commitmentFee" ||
          e?.processorReference === "newMortgage_commitmentFee2" ||
          e?.processorReference === "newMortgage_prepaidAmount",
      );
      const encumbranceExpenditures = arrayExpenditures?.filter(
        (e) =>
          e?.processorReference === "encumbrances_chequeForDischargeFee" ||
          e?.processorReference === "encumbrances_chequeForAdminFee" ||
          e?.processorReference === "encumbrances_dischargeFee",
      );
      let otherExpenditures = arrayExpenditures?.filter(
        (e) =>
          e?.processorReference !== "encumbrances_chequeForDischargeFee" &&
          e?.processorReference !== "encumbrances_chequeForAdminFee" &&
          e?.processorReference !== "encumbrances_dischargeFee" &&
          e?.processorReference !== "trustLedgerTotal_owing_expenditure" &&
          e?.processorReference !== "StatementOfAccount" &&
          e?.processorReference !== "newMortgage_appraisalFee" &&
          e?.processorReference !== "newMortgage_advanceOfFunds" &&
          e?.processorReference !== "newMortgage_mortgageBroker" &&
          e?.processorReference !== "newMortgage_lenderFee" &&
          e?.processorReference !== "newMortgage_interestAdjusmentAmount" &&
          e?.processorReference !== "newMortgage_commitmentFee" &&
          e?.processorReference !== "newMortgage_commitmentFee2" &&
          e?.processorReference !== "newMortgage_prepaidAmount",
      );
      if (newMortgageExpenditures) {
        const sorted_newMortgageExpenditures = newMortgageExpenditures?.sort(
          sort_by_priority_and_cost_expenditures,
        );

        finalRankedTrustLedgerExpenditures = finalRankedTrustLedgerExpenditures.concat(
          sorted_newMortgageExpenditures,
        );
      }

      if (encumbranceExpenditures) {
        const sorted_encumbrance_entries = encumbranceExpenditures?.sort(
          sort_by_priority_and_cost_expenditures,
        );
        finalRankedTrustLedgerExpenditures = finalRankedTrustLedgerExpenditures.concat(
          sorted_encumbrance_entries,
        );
      }

      if (otherExpenditures) {
        otherExpenditures = OrganizeOtherEntries(otherExpenditures, "expenditure");
        finalRankedTrustLedgerExpenditures =
          finalRankedTrustLedgerExpenditures.concat(otherExpenditures);
      }
    } else if (fileType === "Purchase") {
      const newMortgageExpenditures = arrayExpenditures?.filter(
        (e) =>
          e?.processorReference === "newMortgage_appraisalFee" ||
          e?.processorReference === "newMortgage_advanceOfFunds" ||
          e?.processorReference === "newMortgage_mortgageBroker" ||
          e?.processorReference === "newMortgage_lenderFee" ||
          e?.processorReference === "newMortgage_interestAdjusmentAmount" ||
          e?.processorReference === "newMortgage_commitmentFee" ||
          e?.processorReference === "newMortgage_commitmentFee2" ||
          e?.processorReference === "newMortgage_prepaidAmount",
      );
      let otherExpenditures = arrayExpenditures?.filter(
        (e) =>
          e?.processorReference !== "trustLedgerTotal_owing_expenditure" &&
          e?.processorReference !== "StatementOfAccount" &&
          e?.processorReference !== "newMortgage_appraisalFee" &&
          e?.processorReference !== "newMortgage_advanceOfFunds" &&
          e?.processorReference !== "newMortgage_mortgageBroker" &&
          e?.processorReference !== "newMortgage_lenderFee" &&
          e?.processorReference !== "newMortgage_interestAdjusmentAmount" &&
          e?.processorReference !== "newMortgage_commitmentFee" &&
          e?.processorReference !== "newMortgage_commitmentFee2" &&
          e?.processorReference !== "newMortgage_prepaidAmount",
      );
      if (newMortgageExpenditures) {
        const sorted_newMortgageExpenditures = newMortgageExpenditures?.sort(
          sort_by_priority_and_cost_expenditures,
        );

        finalRankedTrustLedgerExpenditures = finalRankedTrustLedgerExpenditures.concat(
          sorted_newMortgageExpenditures,
        );
      }
      if (otherExpenditures) {
        otherExpenditures = OrganizeOtherEntries(otherExpenditures, "expenditure");
        finalRankedTrustLedgerExpenditures =
          finalRankedTrustLedgerExpenditures.concat(otherExpenditures);
      }
    }
    if (statement_of_account_expenditure)
      finalRankedTrustLedgerExpenditures = finalRankedTrustLedgerExpenditures.concat(
        statement_of_account_expenditure,
      );
    if (trustLedgerTotal_owing_expenditure)
      finalRankedTrustLedgerExpenditures = finalRankedTrustLedgerExpenditures.concat(
        trustLedgerTotal_owing_expenditure,
      );
    return finalRankedTrustLedgerExpenditures;
  } else return arrayExpenditures;
};

const OrganizeOtherEntries = (arrayOfEntries, receiptOrExpenditure) => {
  if (!Array.isArray(arrayOfEntries)) return [];
  let finalRankedArrayOfEntries = [];
  // In order
  // Encumbrances if they exist. Ranked by priority. If no priority then its last at the bottom
  let encumbrance_entries = arrayOfEntries?.filter(
    (e) =>
      e?.processorReference === "encumbrances_chequeForDischargeFee" ||
      e?.processorReference === "encumbrances_chequeForAdminFee" ||
      e?.processorReference === "encumbrances_dischargeFee",
  );
  if (encumbrance_entries) {
    const sorted_encumbrance_entries = encumbrance_entries?.sort(
      receiptOrExpenditure === "receipt"
        ? sort_by_priority_and_cost_receipts
        : sort_by_priority_and_cost_expenditures,
    );
    finalRankedArrayOfEntries = finalRankedArrayOfEntries.concat(sorted_encumbrance_entries);
  }

  // Bills and Holdbacks if they exist. Ranked by amount
  let billsHoldbacks_entries = arrayOfEntries?.filter(
    (e) => e?.processorReference === "billsHoldbacks",
  );
  if (billsHoldbacks_entries) {
    const sorted_billsHoldbacks_entries = billsHoldbacks_entries?.sort(
      receiptOrExpenditure === "receipt"
        ? sort_billsHoldback_entries_receipts
        : sort_billsHoldback_entries_expenditures,
    );
    finalRankedArrayOfEntries = finalRankedArrayOfEntries.concat(sorted_billsHoldbacks_entries);
  }

  // Realtor commission if they exist. Ranked by amount
  let realtorComission_entries = arrayOfEntries?.filter(
    (e) =>
      e?.processorReference === "realtorComission_vendorsBrokerOnly" ||
      e?.processorReference === "realtorComission_purchasersBrokerOnly",
  );
  if (realtorComission_entries) {
    const sorted_realtorComission_entries = realtorComission_entries?.sort(
      receiptOrExpenditure === "receipt"
        ? sort_broker_entries_receipts
        : sort_broker_entries_expenditures,
    );
    finalRankedArrayOfEntries = finalRankedArrayOfEntries.concat(sorted_realtorComission_entries);
  }

  // Land Transfer Tax if they exist. Ranked by amount
  let LTT_entries = arrayOfEntries?.filter(
    (e) =>
      e?.processorReference === "landTransferTax_ontario" ||
      e?.processorReference === "landTransferTax_municipal" ||
      e?.processorReference === "landTransferTax_nrst",
  );
  if (LTT_entries) {
    const sorted_LTT_entries = LTT_entries?.sort(
      receiptOrExpenditure === "receipt"
        ? sort_LTT_entries_receipts
        : sort_LTT_entries_expenditures,
    );
    finalRankedArrayOfEntries = finalRankedArrayOfEntries.concat(sorted_LTT_entries);
  }
  // Every other entry that is not included above.
  const other_entries = arrayOfEntries?.filter(
    (e) =>
      e?.processorReference !== "encumbrances_chequeForDischargeFee" &&
      e?.processorReference !== "encumbrances_chequeForAdminFee" &&
      e?.processorReference !== "encumbrances_dischargeFee" &&
      e?.processorReference !== "billsHoldbacks" &&
      e?.processorReference !== "realtorComission_vendorsBrokerOnly" &&
      e?.processorReference !== "realtorComission_purchasersBrokerOnly" &&
      e?.processorReference !== "landTransferTax_ontario" &&
      e?.processorReference !== "landTransferTax_municipal" &&
      e?.processorReference !== "landTransferTax_nrst",
  );
  if (other_entries) finalRankedArrayOfEntries = finalRankedArrayOfEntries.concat(other_entries);
  return finalRankedArrayOfEntries;
};

const sort_by_priority_and_cost_expenditures = (a, b) => {
  let a_priority = a?.priority;
  let b_priority = b?.priority;
  let a_cost = currency(a?.trustLedgerExpenditureAmount).value;
  let b_cost = currency(b?.trustLedgerExpenditureAmount).value;
  if (a_priority < b_priority) return -1;
  if (a_priority > b_priority) return 1;
  if (a_cost > b_cost) return -1;
  if (a_cost < b_cost) return 1;
};
const sort_by_priority_and_cost_receipts = (a, b) => {
  let a_priority = a?.priority;
  let b_priority = b?.priority;
  let a_cost = currency(a?.trustLedgerReceiptAmount).value;
  let b_cost = currency(b?.trustLedgerReceiptAmount).value;

  if (a_priority < b_priority) return -1;
  if (a_priority > b_priority) return 1;
  if (a_cost > b_cost) return -1;
  if (a_cost < b_cost) return 1;
};

const sort_billsHoldback_entries_expenditures = (a, b) => {
  let a_amount = currency(a?.trustLedgerExpenditureAmount).value;
  let b_amount = currency(b?.trustLedgerExpenditureAmount).value;
  if (a_amount > b_amount) return -1;
  else if (a_amount < b_amount) return 1;
  else return 0;
};
const sort_billsHoldback_entries_receipts = (a, b) => {
  let a_amount = currency(a?.trustLedgerReceiptAmount).value;
  let b_amount = currency(b?.trustLedgerReceiptAmount).value;
  if (a_amount > b_amount) return -1;
  else if (a_amount < b_amount) return 1;
  else return 0;
};
const sort_LTT_entries_expenditures = (a, b) => {
  let a_amount = currency(a?.trustLedgerExpenditureAmount).value;
  let b_amount = currency(b?.trustLedgerExpenditureAmount).value;
  if (a_amount > b_amount) return -1;
  else if (a_amount < b_amount) return 1;
  else return 0;
};
const sort_LTT_entries_receipts = (a, b) => {
  let a_amount = currency(a?.trustLedgerReceiptAmount).value;
  let b_amount = currency(b?.trustLedgerReceiptAmount).value;
  if (a_amount > b_amount) return -1;
  else if (a_amount < b_amount) return 1;
  else return 0;
};

const sort_broker_entries_expenditures = (a, b) => {
  let a_amount = currency(a?.trustLedgerExpenditureAmount).value;
  let b_amount = currency(b?.trustLedgerExpenditureAmount).value;
  if (a_amount > b_amount) return -1;
  else if (a_amount < b_amount) return 1;
  else return 0;
};
const sort_broker_entries_receipts = (a, b) => {
  let a_amount = currency(a?.trustLedgerReceiptAmount).value;
  let b_amount = currency(b?.trustLedgerReceiptAmount).value;
  if (a_amount > b_amount) return -1;
  else if (a_amount < b_amount) return 1;
  else return 0;
};

const sort_by_index = (a, b) => {
  if (a.indexToSortBy < b.indexToSortBy) return -1;
  if (a.indexToSortBy > b.indexToSortBy) return 1;
};
