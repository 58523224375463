import currency from "currency.js";
import Money from "src/reusable/Money";

const ProcessLandTransferTax = async ({ dataToProcess, processedData }) => {
  if (
    dataToProcess?.landTransferTax &&
    dataToProcess?.property?.propertyPurchaseType !== "mobileHome"
  ) {
    let listOfLTTEntries = [];
    const netOntarioLTTPayable = Money(dataToProcess?.landTransferTax?.netOntarioLTTPayable);
    const municipality = dataToProcess?.landTransferTax?.municipality;
    const netMunicipalLTTPayable = Money(dataToProcess?.landTransferTax?.netMunicipalLTTPayable);
    if (netOntarioLTTPayable && currency(netOntarioLTTPayable).value > 0) {
      listOfLTTEntries = listOfLTTEntries.concat({
        type: "expenditure",
        trustLedgerExpenditureAmount: currency(netOntarioLTTPayable).value,
        trustLedgerItem: "Paid Ontario Land Transfer Tax",
        trustLedgerReceiptAmount: "",
        uniqueID: dataToProcess?.landTransferTax?.uniqueID,
        processorReference: "landTransferTax_ontario",
        typeOfGeneratedEntry: "automaticEntry",
      });
    }
    if (municipality === "yes") {
      if (netMunicipalLTTPayable && currency(netMunicipalLTTPayable).value > 0) {
        listOfLTTEntries = listOfLTTEntries.concat({
          type: "expenditure",
          trustLedgerExpenditureAmount: currency(netMunicipalLTTPayable).value,
          trustLedgerItem: "Paid Municipal Land Transfer Tax",
          trustLedgerReceiptAmount: "",
          uniqueID: dataToProcess?.landTransferTax?.uniqueID,
          processorReference: "landTransferTax_municipal",
          typeOfGeneratedEntry: "automaticEntry",
        });
      }
    }
    if (
      dataToProcess?.landTransferTax?.nrst === "yes" &&
      currency(dataToProcess?.landTransferTax?.nrstAmount).value > 0
    ) {
      listOfLTTEntries = listOfLTTEntries.concat({
        type: "expenditure",
        trustLedgerExpenditureAmount: currency(dataToProcess?.landTransferTax?.nrstAmount).value,
        trustLedgerItem: "Paid Non Resident Speculation Tax",
        trustLedgerReceiptAmount: "",
        uniqueID: dataToProcess?.landTransferTax?.uniqueID,
        processorReference: "landTransferTax_nrst",
        typeOfGeneratedEntry: "automaticEntry",
      });
    }

    return processedData.concat(listOfLTTEntries);
  }
  return processedData;
};

export default ProcessLandTransferTax;
