import { doc, getDoc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import { loadFile } from "src/reducers";
import HnHHandleDate from "src/reusable/HnHHandleDate";
import ProcessBillsAndHoldbacksData from "./ProcessBillsAndHoldbacksData";
import ProcessLandTransferTaxForStatementOfAccount from "./ProcessLandTransferTaxForStatementOfAccount";

const useSaveStatementOfAccountToDataBase = () => {
  const db = useFirestore();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);
  const { id: currentFileId } = useParams();
  const dispatch = useDispatch();
  const [deletingManualEntry, setdeletingManualEntry] = useState(false);
  const [deletingRoutineEntry, setdeletingRoutineEntry] = useState(false);
  const [deletingCondoEntry, setDeletingCondoEntry] = useState(false);
  const [deletingTeranetEntry, setDeletingTeranetEntry] = useState(false);
  const [deletingChicagoTitleInsuranceEntry, setDeletingChicagoTitleInsuranceEntry] =
    useState(false);
  const [deletingStewartTitleInsuranceEntry, setDeletingStewartTitleInsuranceEntry] =
    useState(false);
  const [deletingFCTTitleInsuranceEntry, setDeletingFCTTitleInsuranceEntry] = useState(false);
  const [deletingBillsAndHoldBacksEntry, setDeletingBillsAndHoldBacksEntry] = useState(false);
  const [deletingLTTMunicipalEntry, setDeletingLTTMunicipalEntry] = useState(false);
  const [deletingLTTOntarioEntry, setDeletingLTTOntarioEntry] = useState(false);

  /// /////////////////////////////////////////////////
  const currentFileDocRef = doc(db, `lawFirm/${lawFirmID}/files/${currentFileId}`);
  const StatementOfAccountRoutineEntriesDocRef = doc(db, `lawFirm/${lawFirmID}`);
  const editingStatementOfAccountEntry = async (entryToEdit) => {
    const fileData = (await getDoc(currentFileDocRef)).data();
    let statementOfAccountData;
    let filteredStatementOfAccountData;
    let pathToSaveTo;
    let finalStatementOfAccountData;
    if (entryToEdit.entryType === "manual") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.ManualCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.ManualCostsOnAccount";
    } else if (entryToEdit.entryType === "routine") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.RoutineCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.RoutineCostsOnAccount";
    } else if (entryToEdit.entryType === "condominium") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.CondominiumCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.CondominiumCostsOnAccount";
    } else if (entryToEdit.entryType === "teranet") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.TeranetCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.TeranetCostsOnAccount";
    } else if (entryToEdit.entryType === "ctic") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.ChicagoCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.ChicagoCostsOnAccount";
    } else if (entryToEdit.entryType === "stewart") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.StewartCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.StewartCostsOnAccount";
    } else if (entryToEdit.entryType === "fct") {
      statementOfAccountData = fileData?.StatementOfAccountFile?.CostsOnAccount?.FCTCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.FCTCostsOnAccount";
    } else if (entryToEdit.entryType === "billsAndHoldbacks") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.BillsAndHoldbacksCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.BillsAndHoldbacksCostsOnAccount";
    } else if (entryToEdit.entryType === "LTTOntarioTax") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.LTT?.OntarioCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.LTT.OntarioCostsOnAccount";
    } else if (entryToEdit.entryType === "LTTMunicipalTax") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.LTT?.MunicipalCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.LTT.MunicipalCostsOnAccount";
    }

    if (statementOfAccountData) {
      filteredStatementOfAccountData = statementOfAccountData.filter(
        (e) => e.uniqueID !== entryToEdit.uniqueID,
      );
      finalStatementOfAccountData = filteredStatementOfAccountData.concat(entryToEdit);
      await updateDoc(currentFileDocRef, pathToSaveTo, finalStatementOfAccountData);
      await savingTotalAccountCostsToDatabase();
      dispatch(loadFile(currentFileId));
    }
  };
  const resetToLCSGeneratedData = async (entryToReset) => {
    const fileData = (await getDoc(currentFileDocRef)).data();
    let statementOfAccountData;
    let filteredStatementOfAccountData;
    let pathToSaveTo;
    let finalStatementOfAccountData;
    if (entryToReset.entryType === "manual") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.ManualCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.ManualCostsOnAccount";
    } else if (entryToReset.entryType === "routine") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.RoutineCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.RoutineCostsOnAccount";
    } else if (entryToReset.entryType === "condominium") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.CondominiumCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.CondominiumCostsOnAccount";
    } else if (entryToReset.entryType === "teranet") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.TeranetCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.TeranetCostsOnAccount";
    } else if (entryToReset.entryType === "ctic") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.ChicagoCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.ChicagoCostsOnAccount";
    } else if (entryToReset.entryType === "stewart") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.StewartCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.StewartCostsOnAccount";
    } else if (entryToReset.entryType === "fct") {
      statementOfAccountData = fileData?.StatementOfAccountFile?.CostsOnAccount?.FCTCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.FCTCostsOnAccount";
    } else if (entryToReset.entryType === "billsAndHoldbacks") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.BillsAndHoldbacksCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.BillsAndHoldbacksCostsOnAccount";
    } else if (entryToReset.entryType === "LTTOntarioTax") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.LTT?.OntarioCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.LTT.OntarioCostsOnAccount";
    } else if (entryToReset.entryType === "LTTMunicipalTax") {
      statementOfAccountData =
        fileData?.StatementOfAccountFile?.CostsOnAccount?.LTT?.MunicipalCostsOnAccount;
      pathToSaveTo = "StatementOfAccountFile.CostsOnAccount.LTT.MunicipalCostsOnAccount";
    }
    if (statementOfAccountData) {
      filteredStatementOfAccountData = statementOfAccountData.filter(
        (e) => e.uniqueID !== entryToReset.uniqueID,
      );
      entryToReset.hasBeenEdited = false;
      entryToReset.lawClerkEdit = {};
      finalStatementOfAccountData = filteredStatementOfAccountData.concat(entryToReset);
      await updateDoc(currentFileDocRef, pathToSaveTo, finalStatementOfAccountData);
      await savingTotalAccountCostsToDatabase();
      dispatch(loadFile(currentFileId));
    }
  };
  const savingManualAccountCostsToDatabase = async (data) => {
    const fileData = (await getDoc(currentFileDocRef)).data();
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.ManualCostsOnAccount) {
      const finalCostsOnAccount =
        fileData.StatementOfAccountFile.CostsOnAccount.ManualCostsOnAccount.concat(data);
      await updateDoc(
        currentFileDocRef,
        "StatementOfAccountFile.CostsOnAccount.ManualCostsOnAccount",
        finalCostsOnAccount,
      );
    } else {
      await updateDoc(
        currentFileDocRef,
        "StatementOfAccountFile.CostsOnAccount.ManualCostsOnAccount",
        [data],
      );
    }
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const deleteManualAccountCostsToDatabase = async (data) => {
    // console.log("data", data);
    setdeletingManualEntry(true);
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.ManualCostsOnAccount",
      data,
    );
    setdeletingManualEntry(false);
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const savingRoutineCostsToDatabase = async (data) => {
    const fileData = (await getDoc(currentFileDocRef)).data();
    const routineEntriesData = await (await getDoc(StatementOfAccountRoutineEntriesDocRef)).data()
      ?.StatementOfAccountRoutineEntries;
    if (data !== undefined) {
      if (
        !fileData?.StatementOfAccountFile?.CostsOnAccount?.RoutineCostsOnAccount &&
        HnHHandleDate(routineEntriesData?.creationDate) <
          HnHHandleDate(fileData?.createFile?.dateFileCreated)
      ) {
        await updateDoc(
          currentFileDocRef,
          "StatementOfAccountFile.CostsOnAccount.RoutineCostsOnAccount",
          data,
        );
        await savingTotalAccountCostsToDatabase();
        dispatch(loadFile(currentFileId));
      }
    }
  };
  const deleteRoutineAccountCostsFromDatabase = async (data) => {
    // console.log("data", data);
    setdeletingRoutineEntry(true);
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.RoutineCostsOnAccount",
      data,
    );
    setdeletingRoutineEntry(false);
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };

  const savingTeranetAccountCostsToDatabase = async (data) => {
    let finalTeranetData = data || [];
    const fileData = (await getDoc(currentFileDocRef)).data();
    const currentTeranetData =
      fileData?.StatementOfAccountFile?.CostsOnAccount?.TeranetCostsOnAccount;

    if (currentTeranetData && data) {
      // Check if they have been edited, if so, only update the original fields, not the edited fields.
      // That way if the user wants to override with the newly fetched data they can.
      finalTeranetData = currentTeranetData?.map((currentData) => {
        const theUpdatedversionOfCurrentRow = data?.find(
          (e) => e.accountDescription === currentData?.accountDescription,
        );
        return {
          ...theUpdatedversionOfCurrentRow,
          lawClerkEdit: currentData?.lawClerkEdit || {},
          hasBeenEdited: currentData?.hasBeenEdited === true,
          hasBeenDeleted: currentData?.hasBeenDeleted === true,
        };
      });
      data?.forEach((fetchedTeranetData) => {
        if (
          !currentTeranetData?.some(
            (currentData) =>
              currentData?.accountDescription === fetchedTeranetData?.accountDescription,
          )
        ) {
          finalTeranetData = finalTeranetData?.concat(fetchedTeranetData);
        }
      });
    }
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.TeranetCostsOnAccount",
      finalTeranetData,
    );
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const deleteTeranetCostsFromDatabase = async (data) => {
    // console.log("data", data);
    setDeletingTeranetEntry(true);
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.TeranetCostsOnAccount",
      data,
    );
    setDeletingTeranetEntry(false);
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const savingChicagoTitleInsuranceInvoiceToDatabase = async (data) => {
    let finalChicagoData = data || [];
    const fileData = (await getDoc(currentFileDocRef)).data();
    const currentChicagoData =
      fileData?.StatementOfAccountFile?.CostsOnAccount?.ChicagoCostsOnAccount;
    if (currentChicagoData && data) {
      // Check if they have been edited, if so, only update the original fields, not the edited fields.
      // That way if the user wants to override with the newnly fetched data they can.
      finalChicagoData = currentChicagoData?.map((currentData) => {
        const theUpdatedversionOfCurrentRow = data?.find(
          (e) => e.accountDescription === currentData?.accountDescription,
        );

        return {
          ...theUpdatedversionOfCurrentRow,
          lawClerkEdit: currentData?.lawClerkEdit || {},
          hasBeenEdited: currentData?.hasBeenEdited === true,
          hasBeenDeleted: currentData?.hasBeenDeleted === true,
        };
      });
      data?.forEach((fetchedChicagodata) => {
        if (
          !currentChicagoData?.some(
            (currentData) =>
              currentData?.accountDescription === fetchedChicagodata?.accountDescription,
          )
        ) {
          finalChicagoData = finalChicagoData?.concat(fetchedChicagodata);
        }
      });
    }
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.ChicagoCostsOnAccount",
      finalChicagoData,
    );
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const savingStewartTitleInsuranceInvoiceToDatabase = async (data) => {
    let finalStewartData = data || [];
    const fileData = (await getDoc(currentFileDocRef)).data();
    const currentStewartData =
      fileData?.StatementOfAccountFile?.CostsOnAccount?.StewartCostsOnAccount;
    if (currentStewartData && data) {
      // Check if they have been edited, if so, only update the original fields, not the edited fields.
      // That way if the user wants to override with the newnly fetched data they can.
      finalStewartData = currentStewartData?.map((currentData) => {
        const theUpdatedversionOfCurrentRow = data?.find(
          (e) => e.accountDescription === currentData?.accountDescription,
        );

        return {
          ...theUpdatedversionOfCurrentRow,
          lawClerkEdit: currentData?.lawClerkEdit || {},
          hasBeenEdited: currentData?.hasBeenEdited === true,
          hasBeenDeleted: currentData?.hasBeenDeleted === true,
        };
      });
      data?.forEach((fetchedStewartData) => {
        if (
          !currentStewartData?.some(
            (currentData) =>
              currentData?.accountDescription === fetchedStewartData?.accountDescription,
          )
        ) {
          finalStewartData = finalStewartData?.concat(fetchedStewartData);
        }
      });
    }
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.StewartCostsOnAccount",
      finalStewartData,
    );
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const savingFCTTitleInsuranceInvoiceToDatabase = async (data) => {
    let finalFCTData = data || [];
    const fileData = (await getDoc(currentFileDocRef)).data();
    const currentFCTData = fileData?.StatementOfAccountFile?.CostsOnAccount?.FCTCostsOnAccount;
    if (currentFCTData && data) {
      // Check if they have been edited, if so, only update the original fields, not the edited fields.
      // That way if the user wants to override with the newnly fetched data they can.
      finalFCTData = currentFCTData?.map((currentData) => {
        const theUpdatedversionOfCurrentRow = data?.find(
          (e) => e.accountDescription === currentData?.accountDescription,
        );

        return {
          ...theUpdatedversionOfCurrentRow,
          lawClerkEdit: currentData?.lawClerkEdit || {},
          hasBeenEdited: currentData?.hasBeenEdited === true,
          hasBeenDeleted: currentData?.hasBeenDeleted === true,
        };
      });
      data?.forEach((fetchedFCTData) => {
        if (
          !currentFCTData?.some(
            (currentData) => currentData?.accountDescription === fetchedFCTData?.accountDescription,
          )
        ) {
          finalFCTData = finalFCTData?.concat(fetchedFCTData);
        }
      });
    }
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.FCTCostsOnAccount",
      finalFCTData,
    );
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const deleteChicagoTitleInsuranceCostsFromDatabase = async (data) => {
    // console.log("data", data);
    setDeletingChicagoTitleInsuranceEntry(true);
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.ChicagoCostsOnAccount",
      data,
    );
    setDeletingChicagoTitleInsuranceEntry(false);
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const deleteStewartTitleInsuranceCostsFromDatabase = async (data) => {
    // console.log("data", data);
    setDeletingStewartTitleInsuranceEntry(true);
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.StewartCostsOnAccount",
      data,
    );
    setDeletingStewartTitleInsuranceEntry(false);
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const deleteFCTTitleInsuranceCostsFromDatabase = async (data) => {
    // console.log("data", data);
    setDeletingFCTTitleInsuranceEntry(true);
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.FCTCostsOnAccount",
      data,
    );
    setDeletingFCTTitleInsuranceEntry(false);
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const savingCondominiumAccountCostsToDatabase = async (data) => {
    const fileData = (await getDoc(currentFileDocRef)).data();
    const currentCondoData =
      fileData?.StatementOfAccountFile?.CostsOnAccount?.CondominiumCostsOnAccount;
    // We are checking that hasBeenDeleted!==true, as we do not want to overwrite the data with our processor if it has been marked as deleted already or Edited
    if (
      (currentCondoData &&
        currentCondoData?.[0]?.hasBeenDeleted !== true &&
        currentCondoData?.[0]?.hasBeenEdited !== true) ||
      !currentCondoData
    ) {
      if (!_.isEmpty(data)) {
        await updateDoc(
          currentFileDocRef,
          "StatementOfAccountFile.CostsOnAccount.CondominiumCostsOnAccount",
          [data],
        );
        await savingTotalAccountCostsToDatabase();
        dispatch(loadFile(currentFileId));
      }
    }
  };
  const deleteCondominiumCostsFromDatabase = async (data) => {
    // console.log("data", data);
    setDeletingCondoEntry(true);
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.CondominiumCostsOnAccount",
      data,
    );
    setDeletingCondoEntry(false);
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };

  // Handle both fire inspection and Zoning Report
  const savingBillsAndHoldbackCostsToStatementOfAccount = async (billsAndHoldbackData) => {
    // Filter the current uniqueID out of the BillsAndHoldbacksCostsOnAccount data if it exists there
    // Process the current data
    // If the processed data is not empty add it back to the BillsAndHoldbacksCostsOnAccount
    // Save the bills and holdbacks cost on account
    const fileData = (await getDoc(currentFileDocRef)).data();
    const currentBillsAndHoldbacksCostsOnAccount =
      fileData?.StatementOfAccountFile?.CostsOnAccount?.BillsAndHoldbacksCostsOnAccount;
    let filteredBillsAndHoldbacksCostsOnAccount =
      currentBillsAndHoldbacksCostsOnAccount?.filter(
        (e) => e?.uniqueID !== billsAndHoldbackData?.uniqueID,
      ) || [];

    const processedBillsAndHoldbackData = ProcessBillsAndHoldbacksData(billsAndHoldbackData);
    if (processedBillsAndHoldbackData)
      filteredBillsAndHoldbacksCostsOnAccount = filteredBillsAndHoldbacksCostsOnAccount?.concat(
        processedBillsAndHoldbackData,
      );
    if (filteredBillsAndHoldbacksCostsOnAccount) {
      await updateDoc(
        currentFileDocRef,
        "StatementOfAccountFile.CostsOnAccount.BillsAndHoldbacksCostsOnAccount",
        filteredBillsAndHoldbacksCostsOnAccount,
      );
      await savingTotalAccountCostsToDatabase();
      dispatch(loadFile(currentFileId));
    }
  };
  const deleteBillsAndHoldbackCostsFromDatabase = async (data) => {
    setDeletingBillsAndHoldBacksEntry(true);
    const fileData = (await getDoc(currentFileDocRef)).data();
    const currentBillsAndHoldbacksCostsOnAccount =
      fileData?.StatementOfAccountFile?.CostsOnAccount?.BillsAndHoldbacksCostsOnAccount;
    const filteredBillsAndHoldbacksCostsOnAccount =
      currentBillsAndHoldbacksCostsOnAccount?.filter((e) => e?.uniqueID !== data?.uniqueID) || [];
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.BillsAndHoldbacksCostsOnAccount",
      filteredBillsAndHoldbacksCostsOnAccount,
    );
    setDeletingBillsAndHoldBacksEntry(false);
    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };

  const savingTotalAccountCostsToDatabase = async () => {
    const fileData = (await getDoc(currentFileDocRef)).data();
    let totalCostsOnAccount = [];
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.ManualCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.ManualCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.TeranetCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.TeranetCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.ChicagoCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.ChicagoCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.StewartCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.StewartCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.FCTCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.FCTCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.RoutineCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.RoutineCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.CondominiumCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.CondominiumCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.BillsAndHoldbacksCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.BillsAndHoldbacksCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.LTT?.OntarioCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.LTT?.OntarioCostsOnAccount,
      );
    }
    if (fileData?.StatementOfAccountFile?.CostsOnAccount?.LTT?.MunicipalCostsOnAccount) {
      totalCostsOnAccount = totalCostsOnAccount.concat(
        fileData.StatementOfAccountFile.CostsOnAccount.LTT?.MunicipalCostsOnAccount,
      );
    }
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.TotalCostsOnAccount",
      totalCostsOnAccount,
    );
    dispatch(loadFile(currentFileId));
  };

  const savingManualAcountReceivedToDatabase = async (data) => {
    // console.log("data", data);
    const fileData = (await getDoc(currentFileDocRef)).data();
    if (fileData?.StatementOfAccountFile?.ReceivedOnAccount?.ManualReceivedOnAccount) {
      const finalReceivedOnAccount =
        fileData.StatementOfAccountFile.ReceivedOnAccount.ManualReceivedOnAccount.concat(data);
      await updateDoc(
        currentFileDocRef,
        "StatementOfAccountFile.ReceivedOnAccount.ManualReceivedOnAccount",
        finalReceivedOnAccount,
      );
    } else {
      await updateDoc(
        currentFileDocRef,
        "StatementOfAccountFile.ReceivedOnAccount.ManualReceivedOnAccount",
        [data],
      );
    }
    savingTotalAccountReceivedToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const deleteManualAccountRecievedToDatabase = async (data) => {
    // console.log("data", data);
    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.ReceivedOnAccount.ManualReceivedOnAccount",
      data,
    );
    savingTotalAccountReceivedToDatabase();
    dispatch(loadFile(currentFileId));
  };
  const savingTotalAccountReceivedToDatabase = async () => {
    const fileData = (await getDoc(currentFileDocRef)).data();
    let totalReceivedOnAccount = [];
    if (fileData?.StatementOfAccountFile?.ReceivedOnAccount?.ManualReceivedOnAccount) {
      totalReceivedOnAccount = totalReceivedOnAccount.concat(
        fileData.StatementOfAccountFile.ReceivedOnAccount.ManualReceivedOnAccount,
      );
      await updateDoc(
        currentFileDocRef,
        "StatementOfAccountFile.ReceivedOnAccount.TotalReceivedOnAccount",
        totalReceivedOnAccount,
      );
    }
    dispatch(loadFile(currentFileId));
  };

  const savingStatementOfAccountSummary = async (formData) => {
    await updateDoc(currentFileDocRef, "StatementOfAccountFile.Summary", formData);
  };

  const savingLTTCostsOnAccountToToDatabase = async ({ data }) => {
    const { ontarioTax, municipalTax } = ProcessLandTransferTaxForStatementOfAccount({
      data: data,
    });
    const fileData = (await getDoc(currentFileDocRef)).data();

    const currentOntarioTaxInSOAcc =
      fileData?.StatementOfAccountFile?.CostsOnAccount?.LTT?.OntarioCostsOnAccount?.[0];
    const currentMunicipalTaxInSOAcc =
      fileData?.StatementOfAccountFile?.CostsOnAccount?.LTT?.MunicipalCostsOnAccount?.[0];
    // We are checking that hasBeenDeleted!==true, as we do not want to overwrite the data with our processor if it has been marked as deleted already or Edited

    if (
      (currentOntarioTaxInSOAcc &&
        currentOntarioTaxInSOAcc?.hasBeenDeleted !== true &&
        currentOntarioTaxInSOAcc?.hasBeenEdited !== true) ||
      !currentOntarioTaxInSOAcc
    ) {
      if (!_.isEmpty(ontarioTax)) {
        await updateDoc(
          currentFileDocRef,
          "StatementOfAccountFile.CostsOnAccount.LTT.OntarioCostsOnAccount",
          ontarioTax,
        );
        await savingTotalAccountCostsToDatabase();
        dispatch(loadFile(currentFileId));
      }
    }
    if (
      (currentMunicipalTaxInSOAcc &&
        currentMunicipalTaxInSOAcc?.hasBeenDeleted !== true &&
        currentMunicipalTaxInSOAcc?.hasBeenEdited !== true) ||
      !currentMunicipalTaxInSOAcc
    ) {
      if (!_.isEmpty(municipalTax)) {
        await updateDoc(
          currentFileDocRef,
          "StatementOfAccountFile.CostsOnAccount.LTT.MunicipalCostsOnAccount",
          municipalTax,
        );
        await savingTotalAccountCostsToDatabase();
        dispatch(loadFile(currentFileId));
      }
    }
  };
  const deleteLTTOntarioCostsFromDatabase = async (data) => {
    setDeletingLTTOntarioEntry(true);

    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.LTT.OntarioCostsOnAccount",
      [data],
    );
    await savingTotalAccountCostsToDatabase();
    setDeletingLTTOntarioEntry(false);
    dispatch(loadFile(currentFileId));
  };
  const deleteLTTMunicipalCostsFromDatabase = async (data) => {
    setDeletingLTTMunicipalEntry(true);

    await updateDoc(
      currentFileDocRef,
      "StatementOfAccountFile.CostsOnAccount.LTT.MunicipalCostsOnAccount",
      [data],
    );
    setDeletingLTTMunicipalEntry(false);

    await savingTotalAccountCostsToDatabase();
    dispatch(loadFile(currentFileId));
  };

  return {
    savingManualAccountCostsToDatabase,
    deleteManualAccountCostsToDatabase,
    savingManualAcountReceivedToDatabase,
    deleteManualAccountRecievedToDatabase,
    savingStatementOfAccountSummary,
    savingTeranetAccountCostsToDatabase,
    savingChicagoTitleInsuranceInvoiceToDatabase,
    savingStewartTitleInsuranceInvoiceToDatabase,
    savingFCTTitleInsuranceInvoiceToDatabase,
    savingRoutineCostsToDatabase,
    deleteRoutineAccountCostsFromDatabase,
    savingCondominiumAccountCostsToDatabase,
    deletingManualEntry,
    deletingRoutineEntry,
    deleteCondominiumCostsFromDatabase,
    deletingCondoEntry,
    deleteTeranetCostsFromDatabase,
    deletingTeranetEntry,
    deleteChicagoTitleInsuranceCostsFromDatabase,
    deletingStewartTitleInsuranceEntry,
    deletingChicagoTitleInsuranceEntry,
    deleteStewartTitleInsuranceCostsFromDatabase,
    deleteFCTTitleInsuranceCostsFromDatabase,
    deletingFCTTitleInsuranceEntry,
    savingBillsAndHoldbackCostsToStatementOfAccount,
    deleteBillsAndHoldbackCostsFromDatabase,
    deletingBillsAndHoldBacksEntry,
    editingStatementOfAccountEntry,
    resetToLCSGeneratedData,
    savingLTTCostsOnAccountToToDatabase,
    deletingLTTOntarioEntry,
    deletingLTTMunicipalEntry,
    deleteLTTMunicipalCostsFromDatabase,
    deleteLTTOntarioCostsFromDatabase,
  };
};
export default useSaveStatementOfAccountToDataBase;
