const handleDataTrimming = ({ transformedData }) => {
  console.log("handleDataTrimming properties object:", transformedData?.properties?.[0]);
  return {
    ...transformedData,
    lawyerMatterNumber: transformedData?.lawyerMatterNumber?.slice(0, 25),
    transactionId: transformedData?.transactionId?.slice(0, 11),
    transactionType: transformedData?.transactionType?.slice(0, 11),
    titleInsurancePolicies: transformedData?.titleInsurancePolicies?.map((item) => ({
      ...item,
      insuranceCompany: item?.insuranceCompany?.slice(0, 50),
      policyNumber: item?.policyNumber?.slice(0, 50),
      nameOfInsured: item?.nameOfInsured?.slice(0, 500),
    })),
    attorneys: transformedData?.attorneys?.map((item) => ({
      ...item,
      fullName: item?.fullName?.slice(0, 100),
      occupation: item?.occupation?.slice(0, 100),
    })),
    mortgagors: transformedData?.mortgagors?.map((item) => ({
      ...item,
      mortgagorType: item?.mortgagorType?.slice(0, 10),
      companyName: item?.companyName?.slice(0, 200),
      firstName: item?.firstName?.slice(0, 100),
      middleName: item?.middleName?.slice(0, 50),
      lastName: item?.lastName?.slice(0, 50),
      phone: item?.phone?.slice(0, 50),
      businessPhone: item?.businessPhone?.slice(0, 25),
      occupation: item?.occupation?.slice(0, 25),
      address: {
        streetNumber: item?.address?.streetNumber?.slice(0, 10),
        streetAddress1: item?.address?.streetAddress1?.slice(0, 100),
        streetAddress2: item?.address?.streetAddress2?.slice(0, 100),
        city: item?.address?.city?.slice(0, 100),
        province: item?.address?.province?.slice(0, 2),
        postalCode: item?.address?.postalCode?.slice(0, 10),
        country: item?.address?.country?.slice(0, 50),
        unitNumber: item?.address?.unitNumber?.slice(0, 10),
      },
      provinceOfIncorporation: item?.provinceOfIncorporation?.slice(0, 2),
      identifications: item?.identifications?.map((item) => ({
        ...item,
        identificationType: item?.identificationType?.slice(0, 100),
        identificationNumber: item?.identificationNumber?.slice(0, 50),
        issuingJurisdiction: item?.issuingJurisdiction?.slice(0, 100),
        fullName: item?.fullName?.slice(0, 200),
        issuingCountry: item?.issuingCountry?.slice(0, 100),
      })),
      signatories: item?.signatories?.map((item) => ({
        ...item,
        firstName: item?.firstName?.slice(0, 100),
        middleName: item?.middleName?.slice(0, 50),
        lastName: item?.lastName?.slice(0, 50),
        occupation: item?.occupation?.slice(0, 100),
        identifications: item?.identifications?.map((subItem) => ({
          ...subItem,
          identificationType: subItem?.identificationType?.slice(0, 100),
          identificationNumber: subItem?.identificationNumber?.slice(0, 50),
          issuingJurisdiction: subItem?.issuingJurisdiction?.slice(0, 100),
          fullName: subItem?.fullName?.slice(0, 200),
          issuingCountry: subItem?.issuingCountry?.slice(0, 100),
        })),
      })),
    })),
    guarantors: transformedData?.guarantors?.map((item) => ({
      ...item,
      guarantorType: item?.guarantorType?.slice(0, 10),
      companyName: item?.companyName?.slice(0, 200),
      firstName: item?.firstName?.slice(0, 100),
      middleName: item?.middleName?.slice(0, 50),
      lastName: item?.lastName?.slice(0, 50),
      phone: item?.phone?.slice(0, 50),
      businessPhone: item?.businessPhone?.slice(0, 25),
      occupation: item?.occupation?.slice(0, 50),
      address: {
        streetNumber: item?.address?.streetNumber?.slice(0, 10),
        streetAddress1: item?.address?.streetAddress1?.slice(0, 100),
        streetAddress2: item?.address?.streetAddress2?.slice(0, 100),
        city: item?.address?.city?.slice(0, 100),
        province: item?.address?.province?.slice(0, 2),
        postalCode: item?.address?.postalCode?.slice(0, 10),
        country: item?.address?.country?.slice(0, 50),
        unitNumber: item?.address?.unitNumber?.slice(0, 10),
      },
      identifications: item?.identifications?.map((item) => ({
        ...item,
        identificationType: item?.identificationType?.slice(0, 100),
        identificationNumber: item?.identificationNumber?.slice(0, 50),
        issuingJurisdiction: item?.issuingJurisdiction?.slice(0, 100),
        fullName: item?.fullName?.slice(0, 200),
        issuingCountry: item?.issuingCountry?.slice(0, 100),
      })),
      signatories: item?.signatories?.map((item) => ({
        ...item,
        firstName: item?.firstName?.slice(0, 100),
        middleName: item?.middleName?.slice(0, 50),
        lastName: item?.lastName?.slice(0, 50),
        occupation: item?.occupation?.slice(0, 100),
        identifications: item?.identifications?.map((subItem) => ({
          ...subItem,
          identificationType: subItem?.identificationType?.slice(0, 100),
          identificationNumber: subItem?.identificationNumber?.slice(0, 50),
          issuingJurisdiction: subItem?.issuingJurisdiction?.slice(0, 100),
          fullName: subItem?.fullName?.slice(0, 200),
          issuingCountry: subItem?.issuingCountry?.slice(0, 100),
        })),
      })),
    })),
    vendorSolicitor: {
      firmName: transformedData?.vendorSolicitor?.firmName?.slice(0, 200),
      firstName: transformedData?.vendorSolicitor?.firstName?.slice(0, 100),
      lastName: transformedData?.vendorSolicitor?.lastName?.slice(0, 100),
    },
    properties: transformedData?.properties?.map((item) => ({
      ...item,
      legalDescription: item?.legalDescription?.slice(0, 4000),
      condoLevel: item?.condoLevel?.slice(0, 50),
      condoUnitNumber: item?.condoUnitNumber?.slice(0, 30),
      condoPlanNumber: item?.condoPlanNumber?.slice(0, 50),
      otherEstateTypeDescription: item?.condoPlanNumber?.slice(0, 100),
      instrumentNumber: item?.instrumentNumber?.slice(0, 50),
      propertyIdentificationNumbers: item?.propertyIdentificationNumbers?.map((subItem) =>
        subItem?.slice(0, 50),
      ),
      existingMortgages: item?.existingMortgages?.map((subItem) => ({
        ...subItem,
        mortgagee: subItem?.mortgagee?.slice(0, 100),
      })),
      assessmentRollNumbers: item?.assessmentRollNumbers?.slice(0, 50),
      fireInsurancePolicy: {
        ...item?.fireInsurancePolicy,
        insuranceCompanyName: item?.fireInsurancePolicy?.insuranceCompanyName?.slice(0, 100),
        phone: item?.fireInsurancePolicy?.phone?.slice(0, 50),
        fax: item?.fireInsurancePolicy?.fax?.slice(0, 50),
        policyNumber: item?.fireInsurancePolicy?.policyNumber?.slice(0, 50),
        insuranceAmount: item?.fireInsurancePolicy?.insuranceAmount?.slice(0, 50),
        broker: item?.fireInsurancePolicy?.broker?.slice(0, 200),
        agentFirstName: item?.fireInsurancePolicy?.agentFirstName?.slice(0, 50),
        agentLastName: item?.fireInsurancePolicy?.agentLastName?.slice(0, 50),
        brokerPhone: item?.fireInsurancePolicy?.brokerPhone?.slice(0, 15),
        unitNumber: item?.fireInsurancePolicy?.unitNumber?.slice(0, 10),
        streetNumber: item?.fireInsurancePolicy?.streetNumber?.slice(0, 10),
        address: item?.fireInsurancePolicy?.address?.slice(0, 100),
        address2: item?.fireInsurancePolicy?.address2?.slice(0, 100),
        city: item?.fireInsurancePolicy?.city?.slice(0, 50),
        province: item?.fireInsurancePolicy?.province?.slice(0, 2),
        postalCode: item?.fireInsurancePolicy?.postalCode?.slice(0, 7),
        country: item?.fireInsurancePolicy?.country?.slice(0, 50),
      },
      address: {
        streetNumber: item?.address?.streetNumber?.slice(0, 10),
        streetAddress1: item?.address?.streetAddress1?.slice(0, 100),
        streetAddress2: item?.address?.streetAddress2?.slice(0, 100),
        city: item?.address?.city?.slice(0, 100),
        province: item?.address?.province?.slice(0, 2),
        postalCode: item?.address?.postalCode?.slice(0, 10),
        country: item?.address?.country?.slice(0, 50),
        unitNumber: item?.address?.unitNumber?.slice(0, 10),
      },
    })),
  };
};

export default handleDataTrimming;
