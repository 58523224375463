/* eslint-disable array-callback-return */
import currency from "currency.js";
import _ from "lodash";

const ProcessExistingEncumbrances = async ({ dataToProcess, processedData, currentFile }) => {
  dataToProcess = dataToProcess?.existingEncumbrances?.encumbrances;
  const mortgageActingFor = currentFile?.fileOpen?.mortgageActingFor;
  const filesPrimaryClient = currentFile?.fileOpen?.filesPrimaryClient;
  const shouldExitFlag =
    mortgageActingFor === "mortgagee" ||
    (mortgageActingFor === "both" && filesPrimaryClient === "mortgagee");
  if (dataToProcess && shouldExitFlag !== true) {
    const listOfAddedEntries = dataToProcess.map((encumbrance, index) => {
      let listOfCurrentEncumbranceTrustLedgerEntries = [];
      if (
        (encumbrance?.category?.value === "Mortgage" ||
          encumbrance?.category?.value === "Bond Mortgage" ||
          encumbrance?.category?.value === "Charge by Religious Organization" ||
          encumbrance?.category?.value === "Charge (with a Transfer of Charge)" ||
          encumbrance?.category?.value === "Vendor take-back mortgage") &&
        encumbrance?.encumbranceIsTo === "be discharged" &&
        encumbrance?.fileRequiresSeparateChequeForDischargeFeePerDiem === "yes" &&
        encumbrance?.chequePayableToPerDiem &&
        currency(encumbrance?.dischargeFeePerDiem).value > 0
      ) {
        const dischargeFee = currency(encumbrance?.dischargeFeePerDiem).value;
        const chequePayableTo = encumbrance?.chequePayableToPerDiem;
        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: `Cheque for discharge fee payable to (${chequePayableTo})`,
            trustLedgerExpenditureAmount: dischargeFee,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_chequeForDischargeFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,

            typeOfGeneratedEntry: "automaticEntry",
          });
      }
      if (
        !(
          encumbrance?.category?.value === "Mortgage" ||
          encumbrance?.category?.value === "Bond Mortgage" ||
          encumbrance?.category?.value === "Charge by Religious Organization" ||
          encumbrance?.category?.value === "Charge (with a Transfer of Charge)" ||
          encumbrance?.category?.value === "Vendor take-back mortgage" ||
          encumbrance?.category?.value === "Bridge loan"
        ) &&
        encumbrance?.encumbranceIsTo === "be discharged" &&
        encumbrance?.fileRequiresSeparateChequeForDischargeFee === "yes" &&
        encumbrance?.chequePayableTo &&
        currency(encumbrance?.dischargeFee).value > 0
      ) {
        const dischargeFee = currency(encumbrance?.dischargeFee).value;
        const chequePayableTo = encumbrance?.chequePayableTo;
        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: `Cheque for discharge fee payable to (${chequePayableTo})`,
            trustLedgerExpenditureAmount: dischargeFee,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_chequeForDischargeFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,
            typeOfGeneratedEntry: "automaticEntry",
          });
      }
      if (
        (encumbrance?.category?.value === "Mortgage" ||
          encumbrance?.category?.value === "Bond Mortgage" ||
          encumbrance?.category?.value === "Charge by Religious Organization" ||
          encumbrance?.category?.value === "Charge (with a Transfer of Charge)") &&
        encumbrance?.encumbranceIsTo === "be discharged" &&
        currency(encumbrance?.finalAmountToDischargeTheEncumbrance).value > 0
      ) {
        const finalAmountToBeDischarged = encumbrance?.finalAmountToDischargeTheEncumbrance || 0.0;
        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: `Paid to discharge previous ${encumbrance.documentTitle} (${
              encumbrance?.listOfLastNames || ""
            })`,
            trustLedgerExpenditureAmount: currency(finalAmountToBeDischarged).value,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_dischargeFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,
            typeOfGeneratedEntry: "automaticEntry",
          });
      }

      if (
        encumbrance?.category?.value === "Vendor take-back mortgage" &&
        encumbrance?.encumbranceIsTo === "be discharged" &&
        currency(encumbrance?.finalAmountToDischargeTheEncumbrance).value > 0
      ) {
        const finalAmountToBeDischarged = encumbrance?.finalAmountToDischargeTheEncumbrance;

        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: `Paid to discharge ${encumbrance.documentTitle} (${
              encumbrance?.listOfLastNames || ""
            })`,
            trustLedgerExpenditureAmount: currency(finalAmountToBeDischarged).value,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_dischargeFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,
            typeOfGeneratedEntry: "automaticEntry",
          });
      }
      if (
        encumbrance?.category?.value === "Bridge loan" &&
        currency(encumbrance?.finalAmountToPayoutBridgeLoan).value > 0
      ) {
        const finalAmountToPayoutBridgeLoan = encumbrance?.finalAmountToPayoutBridgeLoan;
        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: `To payout bridge loan (${encumbrance?.listOfLastNames || ""})`,
            trustLedgerExpenditureAmount: currency(finalAmountToPayoutBridgeLoan).value,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_dischargeFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,
            typeOfGeneratedEntry: "automaticEntry",
          });
      }

      if (
        !(
          encumbrance?.category?.value === "Mortgage" ||
          encumbrance?.category?.value === "Bond Mortgage" ||
          encumbrance?.category?.value === "Charge by Religious Organization" ||
          encumbrance?.category?.value === "Charge (with a Transfer of Charge)" ||
          encumbrance?.category?.value === "Vendor take-back mortgage" ||
          encumbrance?.category?.value === "Bridge loan" ||
          encumbrance?.category?.value === "Other" ||
          encumbrance?.category?.value?.split(" ")[0] === "Application" ||
          encumbrance?.category?.value?.split(" ")[0] === "Caution" ||
          encumbrance?.category?.value?.split(" ")[0] === "Lease"
        ) &&
        encumbrance?.encumbranceIsTo === "be discharged" &&
        currency(encumbrance?.finalAmountToDischargeTheEncumbrance).value > 0
      ) {
        const finalAmountToBeDischarged = currency(
          encumbrance?.finalAmountToDischargeTheEncumbrance,
        ).value;
        const descriptionToRender = `Paid to discharge ${encumbrance?.documentTitle}`;
        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: descriptionToRender,
            trustLedgerExpenditureAmount: currency(finalAmountToBeDischarged).value,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_dischargeFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,
            typeOfGeneratedEntry: "automaticEntry",
          });
      }
      if (
        encumbrance?.category?.value === "Other" &&
        encumbrance?.encumbranceIsTo === "be discharged" &&
        currency(encumbrance?.finalAmountToDischargeTheEncumbrance).value > 0
      ) {
        const finalAmountToBeDischarged = currency(
          encumbrance?.finalAmountToDischargeTheEncumbrance,
        ).value;
        const specifyDescription = encumbrance?.specify;
        const descriptionToRender = `Paid to discharge ${specifyDescription}`;
        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: descriptionToRender,
            trustLedgerExpenditureAmount: currency(finalAmountToBeDischarged).value,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_dischargeFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,
            typeOfGeneratedEntry: "automaticEntry",
          });
      }

      if (
        encumbrance?.fileRequiresSeparateChequeForAdministrationFee === "yes" &&
        encumbrance?.category?.value === "Bridge loan" &&
        encumbrance?.administrationFeeChequePayableTo &&
        currency(encumbrance?.flatAdministrationFee).value > 0
      ) {
        const administrationFee = currency(encumbrance?.flatAdministrationFee).value;
        const chequePayableTo = encumbrance?.administrationFeeChequePayableTo;
        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: `Cheque for flat administration fee payable to (${chequePayableTo}) `,
            trustLedgerExpenditureAmount: currency(administrationFee).value,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_chequeForAdminFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,
            typeOfGeneratedEntry: "automaticEntry",
          });
      }
      if (
        encumbrance?.fileRequiresSeparateChequeForDischargeFee === "yes" &&
        encumbrance?.category?.value === "Bridge loan" &&
        encumbrance?.chequePayableTo &&
        currency(encumbrance?.dischargeFee).value > 0
      ) {
        const dischargeFee = currency(encumbrance?.dischargeFee).value;

        const chequePayableTo = encumbrance?.chequePayableTo;
        listOfCurrentEncumbranceTrustLedgerEntries =
          listOfCurrentEncumbranceTrustLedgerEntries.concat({
            type: "expenditure",
            trustLedgerItem: `Cheque for discharge fee payable to (${chequePayableTo}) `,
            trustLedgerExpenditureAmount: currency(dischargeFee).value,
            trustLedgerReceiptAmount: "",
            uniqueID: encumbrance.uniqueID,
            processorReference: "encumbrances_chequeForDischargeFee",
            priority: encumbrance?.priority ? parseInt(encumbrance.priority?.slice(0, 1)) : 99,
            typeOfGeneratedEntry: "automaticEntry",
          });
      }
      /// /////////////////////////////////////////////
      return listOfCurrentEncumbranceTrustLedgerEntries;
    });
    return processedData.concat(_.flatten(listOfAddedEntries));
  }
  return processedData;
};

export default ProcessExistingEncumbrances;
