import { collection, getDocs } from "firebase/firestore";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore } from "reactfire";

const useGetCollectionManual = () => {
  const [collectionData, setCollectionData] = useState([]);
  const [status, setStatus] = useState("false");

  const db = useFirestore();
  const { id: urlParamFileID } = useParams();
  const fileID = urlParamFileID;
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);
  const getCollection = async (nameOfCollection) => {
    const lawFirmCollection = collection(
      db,
      `lawFirm/${lawFirmID}/files/${fileID}/${nameOfCollection}`,
    );
    return await getDocs(lawFirmCollection).then((quereySnapShot) => {
      return quereySnapShot.docs.map((docRef) => {
        return docRef.data();
      });
    });
  };

  const getCollectionFromPath = async (pathToCollection) => {
    setStatus("loading");
    const lawFirmCollection = collection(db, pathToCollection);
    const data = await getDocs(lawFirmCollection).then((quereySnapShot) => {
      return quereySnapShot.docs.map((docRef) => {
        return { id: docRef.id, NO_ID_FIELD: docRef.id, ...docRef.data() };
      });
    });
    setCollectionData(data);
    setStatus("false");
    return data;
  };
  return { getCollection, getCollectionFromPath, collectionData, status };
};

export default useGetCollectionManual;
