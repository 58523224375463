import currency from "currency.js";
import HnHHandleDate from "../HnHHandleDate";
import Money from "../Money";

const processOccupancyFees = (finalResult, currentFile) => {
  // Data from backend table
  const occupancyFeesFile = currentFile?.condoSaleOccupancyFees;
  if (occupancyFeesFile) {
    if (currency(occupancyFeesFile?.monthlyOccupancyFees).value > 0) {
      const monthlyOccupancyFees = currency(occupancyFeesFile?.monthlyOccupancyFees).value;
      const currentMonthPaidFor = occupancyFeesFile?.purchaserPaidFullAmountInMonthOfClosing;
      const adjustFor = occupancyFeesFile?.adjustFor;

      /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Data for the date work
      let daysBetweenFirstOfMonthToClosingDay;
      let daysInClosingDateMonth;
      const adjustmentDateTimeStamp = currentFile?.fileOpen?.adjustOnSpecifiedDate;
      const whichDateToChoose = currentFile?.fileOpen?.adjustOn;

      if (whichDateToChoose === "specify") {
        if (adjustmentDateTimeStamp) {
          let adjustmentDate = adjustmentDateTimeStamp;
          if (adjustmentDate.seconds) {
            adjustmentDate = HnHHandleDate(adjustmentDate);
          }
          const adjustmentDateDay = adjustmentDate.getDate();
          daysBetweenFirstOfMonthToClosingDay = adjustmentDateDay - 1;

          const month = adjustmentDate.getMonth() + 1;
          const year = adjustmentDate.getFullYear();
          daysInClosingDateMonth = new Date(year, month, 0).getDate();
        }
      } else {
        const closingDateTimeStamp = currentFile?.createFile?.closingDate;
        const closingDate = new Date(closingDateTimeStamp.seconds * 1000);
        const closingDateDay = closingDate.getDate();
        daysBetweenFirstOfMonthToClosingDay = closingDateDay - 1;
        const month = closingDate.getMonth() + 1;
        const year = closingDate.getFullYear();
        daysInClosingDateMonth = new Date(year, month, 0).getDate();
      }
      /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Data for form work
      let monthlyWithTax = monthlyOccupancyFees;
      let shareForXDays = 0;
      /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const OccupancyFeesRows = {
        adjustmentItem: "Occupancy Fees",
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
        _classes: "font-weight-bold main-adjustment-text",
      };
      finalResult.push(OccupancyFeesRows);
      const monthlyOccupancyFeesRow = {
        adjustmentItem: `Monthly occupancy fees: ${Money(monthlyOccupancyFees)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(monthlyOccupancyFeesRow);
      if (adjustFor === "none") monthlyWithTax = monthlyOccupancyFees;
      if (adjustFor === "hst") {
        monthlyWithTax = currency(monthlyWithTax).multiply(1.13);
        const hstRow = {
          adjustmentItem: `HST: ${Money(currency(monthlyOccupancyFees).multiply(0.13).value)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(hstRow);
      }
      if (adjustFor === "gst") {
        monthlyWithTax *= 1.05;
        const gstRow = {
          adjustmentItem: `GST: ${Money(currency(monthlyOccupancyFees).multiply(0.05).value)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(gstRow);
      }
      shareForXDays =
        (currency(monthlyWithTax).value / daysInClosingDateMonth) *
        daysBetweenFirstOfMonthToClosingDay;
      const purchasersShareForXDaysRow = {
        adjustmentItem: `Purchaser's share for ${daysBetweenFirstOfMonthToClosingDay} days: ${Money(
          shareForXDays,
        )}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(purchasersShareForXDaysRow);

      if (currentMonthPaidFor === "yes") {
        const creditPurchaser = currency(monthlyWithTax).subtract(shareForXDays);
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          credit: Money(creditPurchaser),
          monthlyOccupancyFee: Money(monthlyOccupancyFees),
          hst: Money(currency(monthlyOccupancyFees).multiply(0.13)),
          daysToClosing: daysBetweenFirstOfMonthToClosingDay,
          shareForXDays: Money(shareForXDays),
          adjustmentType: "occupancyFees",

          purchaserCredit: Money(creditPurchaser, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditPurchaserRow);
      } else {
        const creditVendor = shareForXDays;
        const creditVendorRow = {
          adjustmentItem: "Credit Vendor",
          adjustmentType: "occupancyFees",
          credit: Money(creditVendor),
          monthlyOccupancyFee: Money(monthlyOccupancyFees),
          hst: Money(currency(monthlyOccupancyFees).multiply(0.13)),
          daysToClosing: daysBetweenFirstOfMonthToClosingDay,
          shareForXDays: Money(shareForXDays),

          purchaserCredit: "",
          vendorCredit: Money(creditVendor, "none"),
          automatic: "true",
        };
        finalResult.push(creditVendorRow);
      }
    }
  }
};
export default processOccupancyFees;
