import { useSelector } from "react-redux";
import useGetCollectionManual from "src/reusable/firestoreHooks/useGetCollectionManual";
import processFinalSummary from "src/reusable/stateOfAdjustmentLogic/SatementOfAdjustment-processFinalSummary";
import StatementOfAdjustmentProcessor from "src/reusable/stateOfAdjustmentLogic/SatementOfAdjustmentProcessor";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";
import useGenerateTrustChequeMadePayableTo from "../functions/useGenerateTrustChequeMadePayableTo";

const useUpdateStatementOfAdjustments = () => {
  const { replaceFields, fetchAllFiles } = useSaveFileToDatabase();
  const { GenerateTrustChequeMadePayableTo } = useGenerateTrustChequeMadePayableTo();
  const { getCollectionFromPath } = useGetCollectionManual();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);

  const UpdateStatementOfAdjustments = async ({ currentFile, calledFromSOA = false }) => {
    if (
      currentFile?.statementOfAdjustments?.manualBalanaceDueOnClosing === "yes" &&
      calledFromSOA === false
    )
      return [];
    let finalData = [];
    const processedAutomaticData = StatementOfAdjustmentProcessor(currentFile);
    const hstFederalPortion = processedAutomaticData.find(
      (e) => e?.handlebarLookup === "hstFederalPortion",
    )?.handlebarValue;
    if (hstFederalPortion) {
      await replaceFields({
        path: "statementOfAdjustments.hstFederalPortion",
        data: hstFederalPortion,
      });
    }
    const monthlyCommonExpenses = processedAutomaticData.find(
      (e) => e?.handlebarLookup === "monthlyCommonExpenses",
    )?.monthlyCommonExpensesData;
    if (monthlyCommonExpenses) {
      await replaceFields({
        path: "statementOfAdjustments.monthlyCommonExpenses",
        data: monthlyCommonExpenses,
      });
    }
    await replaceFields({
      path: "statementOfAdjustments.automaticAdjustments",
      data: processedAutomaticData,
    });
    const prepaidRentArray = processedAutomaticData.filter(
      (e) => e?.handlebarLookup === "prepaidRent",
    );
    const filteredRentArray = prepaidRentArray.map((e) => {
      return e.prepaidRentData;
    });
    if (filteredRentArray) {
      await replaceFields({
        path: "statementOfAdjustments.prepaidRent",
        data: filteredRentArray,
      });
    }
    const manualData = currentFile?.statementOfAdjustments?.manualAdjustments;
    if (processedAutomaticData) finalData = finalData.concat(processedAutomaticData);
    if (manualData) finalData = finalData.concat(manualData);

    const trustChequeMadePayableTo = await GenerateTrustChequeMadePayableTo({
      currentFile: await fetchAllFiles(),
      users: await getCollectionFromPath(`lawFirm/${lawFirmID}/users`),
    });

    const finalProcessedDataWithSummary = processFinalSummary({
      finalResult: finalData,
      firmName: trustChequeMadePayableTo,
    });
    await replaceFields({
      path: "statementOfAdjustments.adjustments",
      data: finalProcessedDataWithSummary,
    });
    await calculateTotalCredits(finalProcessedDataWithSummary);
    return await fetchAllFiles();
  };
  const calculateTotalCredits = async (formData) => {
    let vendorCredit = Number(0);
    let purchaserCredit = Number(0);
    // eslint-disable-next-line array-callback-return
    formData?.map((item) => {
      if (item?.adjustmentItem === "Credit Purchaser") {
        purchaserCredit += Number(item?.purchaserCredit?.replaceAll(",", ""));
      }
      if (item?.adjustmentItem === "Credit Vendor") {
        vendorCredit += Number(item?.vendorCredit?.replaceAll(",", ""));
      }
    });
    await replaceFields({
      path: "statementOfAdjustments.vendorCredit",
      data: vendorCredit,
    });
    await replaceFields({
      path: "statementOfAdjustments.purchaserCredit",
      data: purchaserCredit,
    });
    await replaceFields({
      path: "statementOfAdjustments.balanceDueOnClosing",
      data: vendorCredit - purchaserCredit,
    });
  };

  return { UpdateStatementOfAdjustments };
};
export default useUpdateStatementOfAdjustments;
