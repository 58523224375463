const GetTarionFee = (propertyPrice) => {
  let finalFee;
  switch (true) {
    case propertyPrice >= 0 && propertyPrice <= 100000:
      finalFee = 330;
      break;
    case propertyPrice > 100000 && propertyPrice <= 150000:
      finalFee = 375;
      break;
    case propertyPrice > 150000 && propertyPrice <= 200000:
      finalFee = 445;
      break;
    case propertyPrice > 200000 && propertyPrice <= 250000:
      finalFee = 515;
      break;
    case propertyPrice > 250000 && propertyPrice <= 300000:
      finalFee = 585;
      break;
    case propertyPrice > 300000 && propertyPrice <= 350000:
      finalFee = 655;
      break;
    case propertyPrice > 350000 && propertyPrice <= 400000:
      finalFee = 725;
      break;
    case propertyPrice > 400000 && propertyPrice <= 450000:
      finalFee = 815;
      break;
    case propertyPrice > 450000 && propertyPrice <= 500000:
      finalFee = 890;
      break;
    case propertyPrice > 500000 && propertyPrice <= 550000:
      finalFee = 970;
      break;
    case propertyPrice > 550000 && propertyPrice <= 600000:
      finalFee = 1020;
      break;
    case propertyPrice > 600000 && propertyPrice <= 650000:
      finalFee = 1075;
      break;
    case propertyPrice > 650000 && propertyPrice <= 700000:
      finalFee = 1155;
      break;
    case propertyPrice > 700000 && propertyPrice <= 750000:
      finalFee = 1205;
      break;
    case propertyPrice > 750000 && propertyPrice <= 800000:
      finalFee = 1260;
      break;
    case propertyPrice > 800000 && propertyPrice <= 850000:
      finalFee = 1310;
      break;
    case propertyPrice > 850000 && propertyPrice <= 900000:
      finalFee = 1430;
      break;
    case propertyPrice > 900000 && propertyPrice <= 950000:
      finalFee = 1485;
      break;
    case propertyPrice > 950000 && propertyPrice <= 1000000:
      finalFee = 1540;
      break;
    case propertyPrice > 1000000 && propertyPrice <= 1500000:
      finalFee = 1670;
      break;
    case propertyPrice > 1500000:
      finalFee = 1745;
      break;
    default:
      break;
  }
  return finalFee;
};

export default GetTarionFee;
