import currency from "currency.js";
import CalculateDaysBetweenDateStamps from "../CalculateDaysBetweenDates";
import HnHHandleDate from "../HnHHandleDate";
import Money from "../Money";
import CalculateOntarioResidentialTenanciesActAmount from "./prepaidRent/StatementOfAdjustment-PrepaidRent-ontarioResidentialTenanciesActCalculation";

const processPrepaidRentAndCurrentRentalPeriod = (finalResult, currentFile) => {
  const propertyCurrentRentalPeriodFile = currentFile?.property?.tenants;
  const propertyRentalProperty = currentFile?.property?.propertyRentalProperty;

  if (propertyCurrentRentalPeriodFile && propertyRentalProperty === "yes") {
    const whichDateToChoose = currentFile?.fileOpen?.adjustOn;
    const adjustmentDateTimeStamp = currentFile?.fileOpen?.adjustOnSpecifiedDate;

    // Current Rental Period
    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    let rentalAmount;
    let rentalAmountWithTax;
    let rentCollected;
    let currentRentalPeriodAdjustFor;
    let daysInBetweenDates;
    let vendorsShareForXDays;

    // Prepaid rent
    /// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    let prepaidRentWithTax_prepaid_rent;
    let interestRate_prepaid_rent = 0;
    let amountOfPrepaidRentOnDeposit_prepaid_rent;
    let daysInBetweenDates_prepaid_rent;
    let daysInThatYear_prepaid_rent;
    let yearToCheck_prepaid_rent;
    let closingDate_prepaid_rent;
    let accuredInterestAtYforZdays_prepaid_rent;
    let taxAmount_prepaid_rent;

    const closingDateToUse =
      whichDateToChoose === "specify" && HnHHandleDate(adjustmentDateTimeStamp)
        ? HnHHandleDate(adjustmentDateTimeStamp)
        : HnHHandleDate(currentFile?.createFile?.closingDate);
    closingDate_prepaid_rent = HnHHandleDate(closingDateToUse);
    yearToCheck_prepaid_rent = closingDate_prepaid_rent?.getFullYear();
    if (leapYear(yearToCheck_prepaid_rent)) {
      daysInThatYear_prepaid_rent = 366;
    } else {
      daysInThatYear_prepaid_rent = 365;
    }

    /// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line array-callback-return
    propertyCurrentRentalPeriodFile.map((currentRentalPeriod) => {
      // Prepaid rent
      const prepaidModal = currentRentalPeriod;
      if (prepaidModal?.tenantDescription && prepaidModal?.amountOfPrepaidRentOnDeposit) {
        const prepaidRentDescriptionRow = {
          adjustmentItem: `Prepaid Rent for ${prepaidModal?.tenantDescription}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
          _classes: "font-weight-bold main-adjustment-text",
        };
        finalResult.push(prepaidRentDescriptionRow);
      }
      if (prepaidModal?.amountOfPrepaidRentOnDeposit) {
        amountOfPrepaidRentOnDeposit_prepaid_rent = prepaidModal?.amountOfPrepaidRentOnDeposit;
        const amountOfPrepaidRentOnDepositRow = {
          adjustmentItem: `Amount of prepaid rent on deposit: ${Money(
            amountOfPrepaidRentOnDeposit_prepaid_rent,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(amountOfPrepaidRentOnDepositRow);
      }
      if (prepaidModal?.prepaidRentAdjustFor === "none") {
        prepaidRentWithTax_prepaid_rent = prepaidModal?.amountOfPrepaidRentOnDeposit;
        taxAmount_prepaid_rent = 0;
      } else if (prepaidModal?.prepaidRentAdjustFor === "hst") {
        prepaidRentWithTax_prepaid_rent = currency(
          prepaidModal?.amountOfPrepaidRentOnDeposit,
        ).multiply(1.13).value;
        taxAmount_prepaid_rent = currency(prepaidModal?.amountOfPrepaidRentOnDeposit).multiply(
          0.13,
        ).value;
        const hstRow = {
          adjustmentItem: `HST: ${Money(taxAmount_prepaid_rent)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(hstRow);
      } else if (prepaidModal?.prepaidRentAdjustFor === "gst") {
        prepaidRentWithTax_prepaid_rent = currency(
          prepaidModal?.amountOfPrepaidRentOnDeposit,
        ).multiply(1.05).value;
        taxAmount_prepaid_rent = currency(prepaidModal?.amountOfPrepaidRentOnDeposit).multiply(
          0.05,
        ).value;
        const hstRow = {
          adjustmentItem: `GST: ${Money(taxAmount_prepaid_rent)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(hstRow);
      }
      if (prepaidModal?.creditOnAdjustmentsIncludeInterest === "no") {
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          adjustmentType: "prepaidRent",
          credit: Money(prepaidRentWithTax_prepaid_rent),
          purchaserCredit: Money(prepaidRentWithTax_prepaid_rent, "none"),
          vendorCredit: "",
          automatic: "true",
          handlebarLookup: "prepaidRent",

          prepaidRentData: {
            tenantDescription: prepaidModal?.tenantDescription || "",
            amountOfPrepaidRent: prepaidModal?.amountOfPrepaidRentOnDeposit || "",
            includeInterest: prepaidModal?.creditOnAdjustmentsIncludeInterest || "",
            adjustFor: prepaidModal?.prepaidRentAdjustFor || "",
            taxAmount_prepaid_rent: taxAmount_prepaid_rent || "",
            finalCredit: Money(prepaidRentWithTax_prepaid_rent),
          },
        };

        finalResult.push(creditPurchaserRow);
      } else if (
        prepaidModal?.creditOnAdjustmentsIncludeInterest === "yes" &&
        prepaidModal?.calculatedBasedOn === "fixedInterestRate" &&
        prepaidModal?.interestRate &&
        prepaidModal?.commencementOfInterestPayment
      ) {
        interestRate_prepaid_rent = prepaidModal?.interestRate;
        const commencementOfInterestPaymentDate = HnHHandleDate(
          prepaidModal?.commencementOfInterestPayment,
        );
        daysInBetweenDates_prepaid_rent = Math.abs(
          Math.round(
            (closingDate_prepaid_rent - commencementOfInterestPaymentDate) / (1000 * 60 * 60 * 24),
          ),
        );
        const perDiemAmount =
          currency(amountOfPrepaidRentOnDeposit_prepaid_rent).value / daysInThatYear_prepaid_rent;
        accuredInterestAtYforZdays_prepaid_rent =
          perDiemAmount *
          currency(interestRate_prepaid_rent).divide(100).multiply(daysInBetweenDates_prepaid_rent)
            .value;
        const accuredInterestAtYforZdays_prepaid_rentRow = {
          adjustmentItem: `Accrued interest at ${interestRate_prepaid_rent}% for ${daysInBetweenDates_prepaid_rent} days: ${Money(
            accuredInterestAtYforZdays_prepaid_rent,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(accuredInterestAtYforZdays_prepaid_rentRow);

        const creditPurchaser =
          prepaidRentWithTax_prepaid_rent + accuredInterestAtYforZdays_prepaid_rent;

        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          adjustmentType: "prepaidRent",
          credit: Money(creditPurchaser),
          purchaserCredit: Money(creditPurchaser, "none"),
          vendorCredit: "",
          automatic: "true",
          handlebarLookup: "prepaidRent",

          prepaidRentData: {
            tenantDescription: prepaidModal?.tenantDescription || "",
            amountOfPrepaidRent: prepaidModal?.amountOfPrepaidRentOnDeposit || "",
            includeInterest: prepaidModal?.creditOnAdjustmentsIncludeInterest || "",
            interestAmount: Money(accuredInterestAtYforZdays_prepaid_rent),
            commencementOfInterestPaymentDate: commencementOfInterestPaymentDate || "",
            adjustFor: prepaidModal?.prepaidRentAdjustFor || "",
            taxAmount_prepaid_rent: taxAmount_prepaid_rent || "",
            finalCredit: Money(creditPurchaser),
          },
        };

        finalResult.push(creditPurchaserRow);
      }

      if (
        prepaidModal?.creditOnAdjustmentsIncludeInterest === "yes" &&
        prepaidModal?.calculatedBasedOn === "ontarioResidentialTenanciesAct" &&
        prepaidModal?.commencementOfInterestPayment
      ) {
        const commencementOfInterestPaymentDate = HnHHandleDate(
          prepaidModal?.commencementOfInterestPayment,
        );
        const { ontarioResidentialTenanciesActAmount, numberOfDays } =
          CalculateOntarioResidentialTenanciesActAmount({
            commencementDate: commencementOfInterestPaymentDate,
            prepaidRent: amountOfPrepaidRentOnDeposit_prepaid_rent,
            closingDate: closingDate_prepaid_rent,
          });
        const accuredInterestAtYforZdays_prepaid_rentRow = {
          adjustmentItem: `Accrued interest for ${numberOfDays} days: ${Money(
            ontarioResidentialTenanciesActAmount,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(accuredInterestAtYforZdays_prepaid_rentRow);
        const finalCredit =
          taxAmount_prepaid_rent +
          ontarioResidentialTenanciesActAmount +
          currency(amountOfPrepaidRentOnDeposit_prepaid_rent).value;
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          adjustmentType: "prepaidRent",
          purchaserCredit: Money(finalCredit, "none"),
          vendorCredit: "",
          automatic: "true",
          handlebarLookup: "prepaidRent",
          credit: Money(finalCredit),

          prepaidRentData: {
            tenantDescription: prepaidModal?.tenantDescription || "",
            amountOfPrepaidRent: prepaidModal?.amountOfPrepaidRentOnDeposit || "",
            includeInterest: prepaidModal?.creditOnAdjustmentsIncludeInterest || "",
            interestAmount: Money(ontarioResidentialTenanciesActAmount),
            commencementOfInterestPaymentDate: commencementOfInterestPaymentDate || "",
            adjustFor: prepaidModal?.prepaidRentAdjustFor || "",
            taxAmount_prepaid_rent: taxAmount_prepaid_rent || "",
            finalCredit: Money(finalCredit),
          },
        };
        finalResult.push(creditPurchaserRow);
      }
      // Current rental period
      /// ///////////////////////////////////////////////////////
      const currentRentalPeriodModal = currentRentalPeriod;
      if (
        currentRentalPeriodModal?.tenantDescription &&
        currentRentalPeriodModal?.rentAmount &&
        currentRentalPeriodModal?.startDate &&
        currentRentalPeriodModal?.endDate
      ) {
        const startDateTimeStamp = currentRentalPeriodModal?.startDate;
        const endDateTimeStamp = currentRentalPeriodModal?.endDate;
        daysInBetweenDates =
          1 +
          CalculateDaysBetweenDateStamps({
            startDate: startDateTimeStamp,
            endDate: endDateTimeStamp,
          });

        let daysFromStartDateToClosingDate = CalculateDaysBetweenDateStamps({
          startDate: HnHHandleDate(startDateTimeStamp),
          endDate: closingDateToUse,
        });
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const prepaidRentDescriptionRow = {
          adjustmentItem: `Current rental period for ${currentRentalPeriodModal?.tenantDescription}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
          _classes: "font-weight-bold main-adjustment-text",
        };
        finalResult.push(prepaidRentDescriptionRow);
        rentalAmount = currentRentalPeriodModal?.rentAmount;
        const rentalAmountRow = {
          adjustmentItem: `Rental amount: ${Money(rentalAmount)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(rentalAmountRow);
        currentRentalPeriodAdjustFor = currentRentalPeriodModal?.currentRentalPeriodAdjustFor;
        if (currentRentalPeriodAdjustFor === "none") {
          rentalAmountWithTax = rentalAmount;
        }
        if (currentRentalPeriodAdjustFor === "hst") {
          rentalAmountWithTax = currency(rentalAmount).multiply(1.13).value;
          const hstRow = {
            adjustmentItem: `HST: ${Money(currency(rentalAmount).multiply(0.13).value)}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(hstRow);
        }

        vendorsShareForXDays =
          currency(rentalAmountWithTax).multiply(daysFromStartDateToClosingDate).value /
          daysInBetweenDates;
        const vendorsShareForXDaysRow = {
          adjustmentItem: `Vendor's share for ${daysFromStartDateToClosingDate} days: ${Money(
            vendorsShareForXDays,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(vendorsShareForXDaysRow);
        rentCollected = currentRentalPeriodModal?.rentCollected;
        if (rentCollected === "yes") {
          const creditPurchaserRow = {
            adjustmentItem: "Credit Purchaser",
            rentCollected,
            adjustmentType: "currentRentalPeriod",
            tenantDescription: currentRentalPeriodModal?.tenantDescription,
            rentalAmount: Money(rentalAmount),
            currentRentalPeriodAdjustFor,
            hstAmount: Money(currency(rentalAmount).multiply(0.13).value),
            startDate: currentRentalPeriodModal?.startDate,
            endDate: currentRentalPeriodModal?.endDate,
            shareForXDays: Money(vendorsShareForXDays),
            credit: Money(currency(rentalAmountWithTax).subtract(vendorsShareForXDays).value),
            purchaserCredit: Money(
              currency(rentalAmountWithTax).subtract(vendorsShareForXDays).value,
              "none",
            ),
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(creditPurchaserRow);
        }
        if (rentCollected === "no") {
          const creditVendorRow = {
            adjustmentItem: "Credit Vendor",
            rentCollected,
            adjustmentType: "currentRentalPeriod",
            tenantDescription: currentRentalPeriodModal?.tenantDescription,
            rentalAmount: Money(rentalAmount),
            currentRentalPeriodAdjustFor,
            hstAmount: Money(currency(rentalAmount).multiply(0.13).value),
            startDate: currentRentalPeriodModal?.startDate,
            endDate: currentRentalPeriodModal?.endDate,
            shareForXDays: Money(vendorsShareForXDays),
            credit: Money(vendorsShareForXDays),

            purchaserCredit: "",
            vendorCredit: Money(vendorsShareForXDays, "none"),
            automatic: "true",
          };
          finalResult.push(creditVendorRow);
        }
      }
    });
  }
};

const leapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export default processPrepaidRentAndCurrentRentalPeriod;
