import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GetVendorSolicitorOnFile from "src/reusable/GetVendorSolicitorOnFile";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";

const useGenerateTrustChequeMadePayableTo = () => {
  const { fetchLawfirmFields } = useSaveFileToDatabase();
  const { type } = useParams();
  const lcsUser = useSelector((state) => state?.old?.lcsUser);

  const GenerateTrustChequeMadePayableTo = async ({ currentFile, users }) => {
    const {
      solicitorOnFile: vendorSolicitorOnFile,
      solicitorLawFirmOnFile,
      solicitorObject,
    } = GetVendorSolicitorOnFile(currentFile);
    const lawFirmFields = await fetchLawfirmFields();
    const currentFileOffice = lawFirmFields?.offices?.find(
      (office) => currentFile?.createFile?.office?.value === office.id,
    );
    const isFreeLancer = lawFirmFields?.freelanceClerk === "yes";
    let trustChequeMadePayableTo = "";
    let trustChequePayableToToggle;
    let userObjectMatchingSolicitorObject = users?.find((e) => e?.id === solicitorObject?.id);
    if (isFreeLancer) {
      trustChequePayableToToggle = userObjectMatchingSolicitorObject?.trustChequePayableTo;
    } else {
      trustChequePayableToToggle = currentFileOffice?.trustChequePayableTo;
    }

    if (type === "Sale") {
      if (trustChequePayableToToggle) {
        if (trustChequePayableToToggle === "custom") {
          trustChequeMadePayableTo = isFreeLancer
            ? userObjectMatchingSolicitorObject?.trustChequePayableToCustomName
            : currentFileOffice?.trustChequePayableToCustomName || "";
        } else if (trustChequePayableToToggle === "lawFirm") {
          trustChequeMadePayableTo = isFreeLancer
            ? userObjectMatchingSolicitorObject?.lawFirmName
            : lawFirmFields?.name || "";
        } else if (trustChequePayableToToggle === "lawyer") {
          trustChequeMadePayableTo = vendorSolicitorOnFile || "";
        }
      } else {
        trustChequeMadePayableTo = isFreeLancer ? lcsUser?.lawFirmName : lawFirmFields?.name || "";
      }
    } else if (type === "Purchase") {
      const fileOpenPage = currentFile?.fileOpen;
      const purchaseActingFor = fileOpenPage?.purchaseActingFor;
      if (purchaseActingFor === "both") {
        if (trustChequePayableToToggle) {
          if (trustChequePayableToToggle === "custom") {
            trustChequeMadePayableTo = isFreeLancer
              ? userObjectMatchingSolicitorObject?.trustChequePayableToCustomName ||
                "vendor’s solicitor"
              : currentFileOffice?.trustChequePayableToCustomName || "";
          } else if (trustChequePayableToToggle === "lawFirm") {
            trustChequeMadePayableTo = isFreeLancer
              ? userObjectMatchingSolicitorObject?.lawFirmName
              : lawFirmFields?.name || "";
          } else if (trustChequePayableToToggle === "lawyer") {
            trustChequeMadePayableTo = vendorSolicitorOnFile || "";
          }
        } else {
          trustChequeMadePayableTo = isFreeLancer
            ? userObjectMatchingSolicitorObject?.lawFirmName
            : lawFirmFields?.name || "";
        }
      } else {
        trustChequeMadePayableTo = solicitorLawFirmOnFile;
      }
    }

    return trustChequeMadePayableTo;
  };
  return { GenerateTrustChequeMadePayableTo };
};
export default useGenerateTrustChequeMadePayableTo;
