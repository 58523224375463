/* eslint-disable array-callback-return */
import currency from "currency.js";
import _ from "lodash";

const ProcessNewMortgages = async ({ dataToProcess, processedData }) => {
  const currentFileData = dataToProcess;
  const fileType = currentFileData?.createFile?.fileType;
  dataToProcess = dataToProcess?.newMortgagesFile?.newMortgages;
  if (dataToProcess && currentFileData) {
    const listOfAddedEntries = dataToProcess.map((mortgageTab, index) => {
      let listOfCurrentMortgageTrustLedgerEntries = [];
      const listOfMortgageeNamesToRender = mortgageTab?.listOfMortgageesLastNames || "";
      const priority = mortgageTab?.priority || "";
      if (mortgageTab.brokerageUsed === "yes" && mortgageTab?.category !== "bridge loan") {
        mortgageTab.mortgageBrokerTable?.map((broker, brokerIndex) => {
          if (broker?.appraisalFee !== "" && currency(broker?.appraisalFee).value > 0) {
            listOfCurrentMortgageTrustLedgerEntries =
              listOfCurrentMortgageTrustLedgerEntries.concat({
                trustLedgerItem: `Paid mortgage broker appraisal fee (${broker?.brokerage?.name}) ${priority} mortgage`,
                trustLedgerExpenditureAmount: currency(broker?.appraisalFee)?.value,
                trustLedgerReceiptAmount: "",
                id: `brokerFeeRow${brokerIndex}`,
                type: "expenditure",
                uniqueID: mortgageTab.uniqueID,
                processorReference: "newMortgage_appraisalFee",
                typeOfGeneratedEntry: "automaticEntry",
                priority: mortgageTab?.priority ? parseInt(mortgageTab.priority?.slice(0, 1)) : 99,
              });
          }
        });
      }
      if (fileType === "Purchase") {
        if (
          (mortgageTab.category === "new mortgage" || mortgageTab.category === "bridge loan") &&
          currency(mortgageTab?.netAdvanceOfFunds).value > 0
        ) {
          let advanceOfFundsDescriptionToRender;
          if (mortgageTab.category === "new mortgage")
            advanceOfFundsDescriptionToRender = `Received from ${priority} new mortgage (${listOfMortgageeNamesToRender})`;
          else if (mortgageTab.category === "bridge loan")
            advanceOfFundsDescriptionToRender = `Received from bridge loan (${listOfMortgageeNamesToRender})`;
          listOfCurrentMortgageTrustLedgerEntries = listOfCurrentMortgageTrustLedgerEntries.concat({
            type: "receipt",
            trustLedgerItem: advanceOfFundsDescriptionToRender,
            trustLedgerExpenditureAmount: "",
            trustLedgerReceiptAmount: currency(mortgageTab?.netAdvanceOfFunds).value,
            id: `netAdvanceOfFundsRow${index}`,
            uniqueID: mortgageTab.uniqueID,
            processorReference: "newMortgage_advanceOfFunds",
            typeOfGeneratedEntry: "automaticEntry",
            priority: mortgageTab?.priority ? parseInt(mortgageTab.priority?.slice(0, 1)) : 99,
          });
        }

        if (
          mortgageTab.category === "new mortgage" ||
          mortgageTab.category === "assumed mortgage" ||
          mortgageTab.category === "vendor take back mortgage"
        ) {
          if (mortgageTab.brokerageUsed === "yes") {
            mortgageTab.mortgageBrokerTable?.map((broker, brokerIndex) => {
              if (currency(broker?.brokerFee).value > 0) {
                const brokerFeeDescriptionToRender = `Paid to ${priority} mortgage broker (${broker?.brokerage?.name})`;
                listOfCurrentMortgageTrustLedgerEntries =
                  listOfCurrentMortgageTrustLedgerEntries.concat({
                    trustLedgerItem: brokerFeeDescriptionToRender,
                    trustLedgerExpenditureAmount: currency(broker?.brokerFee).value,
                    trustLedgerReceiptAmount: "",
                    id: `brokerFeeRow${brokerIndex}`,
                    type: "expenditure",
                    uniqueID: mortgageTab.uniqueID,
                    processorReference: "newMortgage_mortgageBroker",
                    typeOfGeneratedEntry: "automaticEntry",
                    priority: mortgageTab?.priority
                      ? parseInt(mortgageTab.priority?.slice(0, 1))
                      : 99,
                  });
              }
            });
          }
        }
      }
      if (fileType === "Mortgage") {
        let advanceOfFundsDescriptionToRender;
        if (mortgageTab.category === "new mortgage")
          advanceOfFundsDescriptionToRender = `Received from new ${priority} mortgagee on closing (${listOfMortgageeNamesToRender})`;
        if (mortgageTab.category === "bridge loan")
          advanceOfFundsDescriptionToRender = `Received from bridge loan (${listOfMortgageeNamesToRender})`;
        if (mortgageTab?.netAdvanceOfFunds && currency(mortgageTab?.netAdvanceOfFunds).value > 0)
          listOfCurrentMortgageTrustLedgerEntries = listOfCurrentMortgageTrustLedgerEntries.concat({
            trustLedgerItem: advanceOfFundsDescriptionToRender,
            trustLedgerExpenditureAmount: "",
            trustLedgerReceiptAmount: currency(mortgageTab?.netAdvanceOfFunds).value,
            id: `netAdvanceOfFundsRow${index}`,
            type: "receipt",
            uniqueID: mortgageTab.uniqueID,
            processorReference: "newMortgage_advanceOfFunds",
            typeOfGeneratedEntry: "automaticEntry",
            priority: mortgageTab?.priority ? parseInt(mortgageTab.priority?.slice(0, 1)) : 99,
          });
        if (mortgageTab.category === "new mortgage") {
          if (mortgageTab.brokerageUsed === "yes") {
            mortgageTab.mortgageBrokerTable?.map((broker, brokerIndex) => {
              if (currency(broker?.brokerFee).value > 0) {
                const brokerFeeDescriptionToRender = `Paid to ${priority} mortgage broker (${broker?.brokerage?.name})`;
                listOfCurrentMortgageTrustLedgerEntries =
                  listOfCurrentMortgageTrustLedgerEntries.concat({
                    trustLedgerItem: brokerFeeDescriptionToRender,
                    trustLedgerExpenditureAmount: currency(broker?.brokerFee).value,
                    trustLedgerReceiptAmount: "",
                    id: `brokerFeeRow${brokerIndex}`,
                    type: "expenditure",
                    uniqueID: mortgageTab.uniqueID,
                    processorReference: "newMortgage_mortgageBroker",
                    typeOfGeneratedEntry: "automaticEntry",
                    priority: mortgageTab?.priority
                      ? parseInt(mortgageTab.priority?.slice(0, 1))
                      : 99,
                  });
              }
            });
          }
        }
      }

      // prepaid amount and number of prepaid months
      if (
        mortgageTab &&
        mortgageTab?.prepaidAmount &&
        currency(mortgageTab?.prepaidAmount).value > 0 &&
        mortgageTab?.numberOfPrepaidMonths
      ) {
        const prepaidAmountDescriptionToRender = `Paid ${`${priority} `}new mortgage interest ${
          mortgageTab?.numberOfPrepaidMonths
        } months prepaid`;
        listOfCurrentMortgageTrustLedgerEntries = listOfCurrentMortgageTrustLedgerEntries.concat({
          trustLedgerItem: prepaidAmountDescriptionToRender,
          trustLedgerExpenditureAmount: currency(mortgageTab?.prepaidAmount).value,
          trustLedgerReceiptAmount: "",
          id: `prepaidAmount${index}`,
          type: "expenditure",
          uniqueID: mortgageTab.uniqueID,
          processorReference: "newMortgage_prepaidAmount",
          typeOfGeneratedEntry: "automaticEntry",
          priority: mortgageTab?.priority ? parseInt(mortgageTab.priority?.slice(0, 1)) : 99,
        });
      }
      // Commitment broker and fee
      if (
        mortgageTab &&
        mortgageTab?.commitmentFee &&
        !_.isEmpty(mortgageTab?.commitmentBroker) &&
        currency(mortgageTab?.commitmentFee).value > 0
      ) {
        const commitmentFeeDescriptionToRender = `Paid  ${`${priority} `}new mortgage commitment fee to ${
          mortgageTab?.commitmentBroker
        }`;
        listOfCurrentMortgageTrustLedgerEntries = listOfCurrentMortgageTrustLedgerEntries.concat({
          trustLedgerItem: commitmentFeeDescriptionToRender,
          trustLedgerExpenditureAmount: currency(mortgageTab?.commitmentFee).value,
          trustLedgerReceiptAmount: "",
          id: `commitmentFee${index}`,
          type: "expenditure",
          uniqueID: mortgageTab.uniqueID,
          processorReference: "newMortgage_commitmentFee",
          typeOfGeneratedEntry: "automaticEntry",
          priority: mortgageTab?.priority ? parseInt(mortgageTab.priority?.slice(0, 1)) : 99,
        });
      }
      if (
        mortgageTab &&
        mortgageTab?.commitmentFee2 &&
        !_.isEmpty(mortgageTab?.commitmentBroker2) &&
        currency(mortgageTab?.commitmentFee2).value > 0
      ) {
        const commitmentFeeDescriptionToRender = `Paid  ${`${priority} `}new mortgage commitment fee to ${
          mortgageTab?.commitmentBroker2
        }`;
        listOfCurrentMortgageTrustLedgerEntries = listOfCurrentMortgageTrustLedgerEntries.concat({
          trustLedgerItem: commitmentFeeDescriptionToRender,
          trustLedgerExpenditureAmount: currency(mortgageTab?.commitmentFee2).value,
          trustLedgerReceiptAmount: "",
          id: `commitmentFee${index}`,
          type: "expenditure",
          uniqueID: mortgageTab.uniqueID,
          processorReference: "newMortgage_commitmentFee2",
          typeOfGeneratedEntry: "automaticEntry",
          priority: mortgageTab?.priority ? parseInt(mortgageTab.priority?.slice(0, 1)) : 99,
        });
      }
      // Lender fee
      if (mortgageTab && mortgageTab?.lenderFee && currency(mortgageTab?.lenderFee).value > 0) {
        const lenderFeeDescriptionToRender = `Paid ${`${priority} `}new mortgage lender fee`;
        listOfCurrentMortgageTrustLedgerEntries = listOfCurrentMortgageTrustLedgerEntries.concat({
          trustLedgerItem: lenderFeeDescriptionToRender,
          trustLedgerExpenditureAmount: currency(mortgageTab?.lenderFee).value,
          id: `lenderFee${index}`,
          trustLedgerReceiptAmount: "",
          type: "expenditure",
          uniqueID: mortgageTab.uniqueID,
          processorReference: "newMortgage_lenderFee",
          typeOfGeneratedEntry: "automaticEntry",
          priority: mortgageTab?.priority ? parseInt(mortgageTab.priority?.slice(0, 1)) : 99,
        });
      }
      if (
        mortgageTab &&
        mortgageTab?.interestAdjustmentAmount &&
        currency(mortgageTab?.interestAdjustmentAmount).value > 0 &&
        mortgageTab?.category !== "bridge loan"
      ) {
        const interestAdjusmentAmountDescriptionToRender = `Paid ${`${priority} `}mortgage interest adjustment amount`;
        listOfCurrentMortgageTrustLedgerEntries = listOfCurrentMortgageTrustLedgerEntries.concat({
          trustLedgerItem: interestAdjusmentAmountDescriptionToRender,
          trustLedgerExpenditureAmount: currency(mortgageTab?.interestAdjustmentAmount).value,
          id: `interestAdjusmentAmount${index}`,
          trustLedgerReceiptAmount: "",
          type: "expenditure",
          uniqueID: mortgageTab.uniqueID,
          processorReference: "newMortgage_interestAdjusmentAmount",
          typeOfGeneratedEntry: "automaticEntry",
          priority: mortgageTab?.priority ? parseInt(mortgageTab.priority?.slice(0, 1)) : 99,
        });
      }

      return listOfCurrentMortgageTrustLedgerEntries;
    });
    return processedData.concat(_.flatten(listOfAddedEntries));
  }
  return processedData;
};

export default ProcessNewMortgages;
