import currency from "currency.js";

const ProcessLandTransferTaxForStatementOfAccount = ({ data }) => {
  const netOntarioLTTPayable = currency(data?.netOntarioLTTPayable).value;
  const municipality = data?.municipality;
  const netMunicipalLTTPayable = currency(data?.netMunicipalLTTPayable).value;
  let ontarioTax = {};
  let municipalTax = {};
  if (data) {
    if (netOntarioLTTPayable && netOntarioLTTPayable > 0)
      ontarioTax = {
        accountCost: data?.netOntarioLTTPayable,
        accountDescription: "Ontario Land Transfer Tax",
        accountHST: "No",
        accountHSTAmount: "",
        accountTotal: data?.netOntarioLTTPayable,
        type: "disbursement",
        uniqueID: Math.random(),
        entryType: "LTTOntarioTax",
      };

    if (municipality === "yes" && netMunicipalLTTPayable && netMunicipalLTTPayable > 0)
      municipalTax = {
        accountCost: data?.netMunicipalLTTPayable,
        accountDescription: "Municipal Land Transfer Tax",
        accountHST: "No",
        accountHSTAmount: "",
        accountTotal: data?.netMunicipalLTTPayable,
        type: "disbursement",
        uniqueID: Math.random(),
        entryType: "LTTMunicipalTax",
      };
  }
  return { ontarioTax: [ontarioTax], municipalTax: [municipalTax] };
};

export default ProcessLandTransferTaxForStatementOfAccount;
