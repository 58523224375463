import classNames from "classnames";

import { useSelector } from "react-redux";
// import "./TheLayout.css";
import { CheckList, TheContent, TheFooter, TheHeader, TheSidebar } from "./index";

function TheLayout() {
  const darkMode = useSelector((state) => state?.old?.darkMode);
  const classes = classNames("c-app c-default-layout", darkMode && "c-dark-theme");
  return (
    <div className={classes}>
      <TheSidebar />
      <CheckList />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent fluidContainer />
        </div>
        <TheFooter />
      </div>
    </div>
  );
}

export default TheLayout;
