import currency from "currency.js";
import Money from "../Money";

const processNewMortgageVTBMortgage = (finalResult, currentFile) => {
  const newMortgageFile = currentFile?.newMortgagesFile?.newMortgages;
  if (newMortgageFile) {
    // eslint-disable-next-line array-callback-return
    newMortgageFile.map((newMortgage) => {
      if (newMortgage.category === "vendor take back mortgage" && newMortgage.principalAmount) {
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const priority = newMortgage?.priority;
        const principalAmount = currency(newMortgage?.principalAmount).value;
        /// //////////////////////////////////////////////////////////////////////////
        const newMortgageRow = {
          adjustmentItem: `${priority} Priority Mortgage Back to Vendor`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
          _classes: "font-weight-bold main-adjustment-text",
        };
        finalResult.push(newMortgageRow);
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          purchaserCredit: Money(principalAmount, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditPurchaserRow);
      }
    });
  }
};
export default processNewMortgageVTBMortgage;
