import CIcon from "@coreui/icons-react";
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth, useUser } from "reactfire";

export default function TheHeaderDropdown() {
  const { status, data: user } = useUser();
  const lcsUser = useSelector((state) => state?.old?.lcsUser);

  // TODO: investigate if these are the same?
  const auth = useAuth();
  // const auth = useAuth();
  const dispatch = useDispatch();
  const logout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("teranetCredentials");
        // Sign-out successful.
        dispatch({ type: "set", payload: { signedOut: true } });
        //= ======================================================
        // fix from GitHub of reactfire thread on this bug
        //= ======================================================
        const map = global._reactFirePreloadedObservables;
        Array.from(map.keys()).forEach((key) => key.includes("firestore") && map.delete(key));
        //= ======================================================
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const history = useHistory();
  return (
    <>
      <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
        <CDropdownToggle className="c-header-nav-link" caret>
          <div>Settings</div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem header tag="div" color="light" className="text-center">
            <strong>
              {user?.displayName} ({lcsUser?.occupation})
            </strong>
          </CDropdownItem>
          {/* <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Settings</strong>
        </CDropdownItem> */}
          <CDropdownItem onClick={() => history.push("/invoices")}>
            <CIcon name="cil-list" className="mfe-2" />
            Invoices
          </CDropdownItem>
          {lcsUser?.role === "admin" ? (
            <CDropdownItem onClick={() => history.push("/manageUsers")}>
              <FontAwesomeIcon icon={faUsers} className="mfe-2" />
              Manage Users
            </CDropdownItem>
          ) : (
            <></>
          )}
          {lcsUser?.role === "admin" ? (
            <CDropdownItem onClick={() => history.push("/lawFirmSettings")}>
              <CIcon name="cil-settings" className="mfe-2" />
              Law Firm Settings
            </CDropdownItem>
          ) : (
            <></>
          )}
          {lcsUser?.role === "admin" ? (
            <CDropdownItem onClick={() => history.push(`/templateSettings`)}>
              <FontAwesomeIcon icon={faFileAlt} className="mfe-2" />
              Template Settings
            </CDropdownItem>
          ) : (
            <></>
          )}
          {lcsUser?.role === "admin" ? (
            <CDropdownItem onClick={() => history.push("/statementSettings")}>
              {/* <CIcon name="cil-settings" className="mfe-2" /> */}
              <FontAwesomeIcon icon={faFileAlt} className="mfe-2" />
              Statement Settings
            </CDropdownItem>
          ) : (
            <></>
          )}

          <CDropdownItem onClick={() => history.push("/customResponseToRequisition")}>
            {/* <CIcon name="cil-settings" className="mfe-2" /> */}
            <FontAwesomeIcon icon={faFileAlt} className="mfe-2" />
            Requisition Settings
          </CDropdownItem>
          <CDropdownItem onClick={() => history.push("/resources")}>
            {/* <CIcon name="cil-settings" className="mfe-2" /> */}
            <FontAwesomeIcon icon={faFileAlt} className="mfe-2" />
            Resources
          </CDropdownItem>
          <CDropdownItem onClick={logout}>
            <CIcon name="cil-lock-locked" className="mfe-2" />
            {status === "loading" ? "logging out..." : "Log Out"}
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  );
}
