/* eslint-disable array-callback-return */
import currency from "currency.js";

const ProcessBillsHoldbacks = async ({ dataToProcess, processedData }) => {
  if (dataToProcess) {
    const filteredDataToProcess = dataToProcess.filter(
      (e) => e.category !== "fireInspection" && e.category !== "zoningReport",
    );
    const trustLedgerData = filteredDataToProcess?.map((data) => {
      if (
        data.category === "holdback" ||
        data.category === "taxArrears" ||
        data.category === "billOrdebt" ||
        data.category === "waterArrears"
      ) {
        const entryLabel =
          data.category === "holdback"
            ? `Holdback ${data.description}`
            : data.category === "taxArrears"
            ? `Paid tax arrears to ${data?.chequePayableTo}`
            : data.category === "billOrdebt"
            ? `Paid ${data.description} to ${data.chequePayableTo}`
            : data.category === "waterArrears"
            ? `Paid water arrears to ${data?.chequePayableTo}`
            : "";
        return {
          type: "expenditure",
          trustLedgerExpenditureAmount: currency(data.amountCurrency).value || 0,
          trustLedgerItem: entryLabel,
          trustLedgerReceiptAmount: "",
          uniqueID: data.uniqueID,
          processorReference: "billsHoldbacks",
          typeOfGeneratedEntry: "automaticEntry",
        };
      }
    });
    return processedData.concat(trustLedgerData);
  }
};

export default ProcessBillsHoldbacks;
