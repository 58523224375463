import currency from "currency.js";

const ProcessStatementOfAdjustments = async ({ dataToProcess, typeOfFile }) => {
  const statementOfAdjustments = dataToProcess?.statementOfAdjustments;
  if (statementOfAdjustments) {
    const vendorCredit = currency(statementOfAdjustments?.vendorCredit).value;
    const purchaserCredit = currency(statementOfAdjustments?.purchaserCredit).value;
    const balanceDueOnClosing = Math.abs(
      currency(statementOfAdjustments?.balanceDueOnClosing).value,
    );
    let descriptionOfBalanceDueToRender;
    if (vendorCredit > purchaserCredit) {
      if (typeOfFile === "Purchase") {
        const vendorSolicitorLawFirm = dataToProcess?.vendor?.vendorSolicitorLawFirm;
        descriptionOfBalanceDueToRender = vendorSolicitorLawFirm
          ? `Paid to Vendor's solicitor at ${vendorSolicitorLawFirm} on closing`
          : "Paid to Vendor's solicitor on closing";
        return {
          trustLedgerItem: descriptionOfBalanceDueToRender,
          trustLedgerExpenditureAmount: balanceDueOnClosing,
          trustLedgerReceiptAmount: "",
          type: "expenditure",

          uniqueID: "StatementOfAdjustment",
          processorReference: "StatementOfAdjustment",
          typeOfGeneratedEntry: "automaticEntry",
        };
      }
      if (typeOfFile === "Sale") {
        const purchaseSolicitorLawFirm = dataToProcess?.purchaser?.purchaseSolicitorLawFirm;
        descriptionOfBalanceDueToRender = `Received from Purchaser's solicitor on closing ${
          purchaseSolicitorLawFirm ? `- (${purchaseSolicitorLawFirm})` : ""
        }`;
        return {
          trustLedgerItem: descriptionOfBalanceDueToRender,
          trustLedgerExpenditureAmount: "",
          trustLedgerReceiptAmount: balanceDueOnClosing,
          type: "receipt",
          uniqueID: "StatementOfAdjustment",
          processorReference: "StatementOfAdjustment",
          typeOfGeneratedEntry: "automaticEntry",
        };
      }
    } else if (purchaserCredit > vendorCredit) {
      const vendorSolicitorLawFirm = dataToProcess?.vendor?.vendorSolicitorLawFirm;
      descriptionOfBalanceDueToRender = vendorSolicitorLawFirm
        ? `Received from Vendor's solicitor at ${vendorSolicitorLawFirm} on closing`
        : "Received from Vendor's solicitor on closing";
      if (typeOfFile === "Purchase") {
        return {
          trustLedgerItem: descriptionOfBalanceDueToRender,
          trustLedgerExpenditureAmount: "",
          trustLedgerReceiptAmount: balanceDueOnClosing,
          type: "receipt",
          uniqueID: "StatementOfAdjustment",
          processorReference: "StatementOfAdjustment",
          typeOfGeneratedEntry: "automaticEntry",
        };
      }
      if (typeOfFile === "Sale") {
        const purchaseSolicitorLawFirm = dataToProcess?.purchaser?.purchaseSolicitorLawFirm;
        descriptionOfBalanceDueToRender = purchaseSolicitorLawFirm
          ? `Paid to Purchaser's solicitor at ${purchaseSolicitorLawFirm} on closing`
          : "Paid to Purchaser's solicitor on closing";
        return {
          trustLedgerItem: descriptionOfBalanceDueToRender,
          trustLedgerExpenditureAmount: balanceDueOnClosing,
          trustLedgerReceiptAmount: "",
          type: "expenditure",
          uniqueID: "StatementOfAdjustment",
          processorReference: "StatementOfAdjustment",
          typeOfGeneratedEntry: "automaticEntry",
        };
      }
    }
  }
};

export default ProcessStatementOfAdjustments;
