import currency from "currency.js";
import Money from "../Money";

const processVtbMortgage = (finalResult, currentFile) => {
  const vtbMortgageFile = currentFile?.vtbMortgages?.vtbMortgages;
  if (vtbMortgageFile) {
    // eslint-disable-next-line array-callback-return
    vtbMortgageFile.map((vtbMortgage) => {
      if (vtbMortgage?.principalAmount) {
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const priority = vtbMortgage?.priority;
        const principalAmount = currency(vtbMortgage?.principalAmount).value;
        /// //////////////////////////////////////////////////////////////////////////
        const newMortgageRow = {
          adjustmentItem: `${priority} Priority Mortgage Back to Vendor`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
          _classes: "font-weight-bold main-adjustment-text",
        };
        finalResult.push(newMortgageRow);
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          purchaserCredit: Money(principalAmount, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditPurchaserRow);
      }
    });
  }
};
export default processVtbMortgage;
