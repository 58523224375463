import Money from "../Money";

const processDeposit = (finalResult, currentFile) => {
  const propertyDepositFile = currentFile?.propertyDeposits;
  if (propertyDepositFile) {
    if (propertyDepositFile?.depositAmount || propertyDepositFile?.propertyTotalDeposits) {
      const propertyDeposit = propertyDepositFile?.depositAmount;
      const multipleDeposits = propertyDepositFile?.multipleDeposits;
      const depostList = propertyDepositFile?.deposits;
      const totalDeposit = propertyDepositFile?.propertyTotalDeposits;
      if (multipleDeposits === "no") {
        const depositRow = {
          adjustmentItem: "Deposit",
          purchaserCredit: "",
          vendorCredit: "",
          credit: Money(propertyDeposit, "none"),
          _classes: "font-weight-bold main-adjustment-text",
          automatic: "true",
        };
        finalResult.push(depositRow);
        const creditToPurchaser = {
          adjustmentItem: "Credit Purchaser",
          purchaserCredit: Money(propertyDeposit, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditToPurchaser);
      }
      if (multipleDeposits === "yes") {
        const depositRow = {
          adjustmentItem: "Deposit",
          purchaserCredit: "",
          vendorCredit: "",
          credit: Money(totalDeposit, "none"),
          _classes: "font-weight-bold main-adjustment-text",
          automatic: "true",
        };
        finalResult.push(depositRow);
        depostList.map((deposit) => {
          const subDepositRow = {
            adjustmentItem: `${deposit?.label}: ${Money(deposit.additionalDepositAmount)}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          return finalResult.push(subDepositRow);
        });
        const creditToPurchaser = {
          adjustmentItem: "Credit Purchaser",
          purchaserCredit: Money(totalDeposit, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditToPurchaser);
      }
    }
  }
};
export default processDeposit;
