import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import { collection, doc, query, where, getDocs, deleteDoc } from "firebase/firestore";

const useDeleteMultipleDocumentsInTrustLedger = (nameOfCollection) => {
  const db = useFirestore();
  const { id: urlParamFileID } = useParams();
  const fileID = urlParamFileID;
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);

  const deleteMultipleDocumentsInTrustLedger = async ({ queryByFieldValue, queryByFieldName }) => {
    const fileRef = `lawFirm/${lawFirmID}/files/${fileID}/${nameOfCollection}`;
    const q = query(collection(db, fileRef), where(queryByFieldName, "==", queryByFieldValue));
    const querySnapshot = await getDocs(q);
    let docIDs = [];
    querySnapshot.forEach((doc) => {
      docIDs = docIDs.concat(doc.id);
    });
    const listOfPromises = docIDs.map(async (docToDelete) => {
      const docToDeleteRef = doc(
        db,
        `lawFirm/${lawFirmID}/files/${fileID}/${nameOfCollection}/${docToDelete}`,
      );
      await deleteDoc(docToDeleteRef).catch((e) =>
        console.log("Error saving document to collection"),
      );
    });
    await Promise.all(listOfPromises);
  };
  const deleteMultipleDocumentsInTrustLedgerUnlessEditedOrAlreadyDeleted = async ({
    queryByFieldValue,
    queryByFieldName,
  }) => {
    const fileRef = `lawFirm/${lawFirmID}/files/${fileID}/${nameOfCollection}`;
    const q = query(collection(db, fileRef), where(queryByFieldName, "==", queryByFieldValue));
    const querySnapshot = await getDocs(q);
    let docIDs = [];
    querySnapshot.forEach((doc) => {
      if (
        !(
          doc.data().editedByUser === true ||
          doc.data().deletedByUser === true ||
          doc.data().processorReference === "routineEntry"
        )
      )
        docIDs = docIDs.concat(doc.id);
    });
    const listOfPromises = docIDs.map(async (docToDelete) => {
      const docToDeleteRef = doc(
        db,
        `lawFirm/${lawFirmID}/files/${fileID}/${nameOfCollection}/${docToDelete}`,
      );
      await deleteDoc(docToDeleteRef).catch((e) =>
        console.log("Error saving document to collection"),
      );
    });
    await Promise.all(listOfPromises);
  };
  return {
    deleteMultipleDocumentsInTrustLedger,
    deleteMultipleDocumentsInTrustLedgerUnlessEditedOrAlreadyDeleted,
  };
};
export default useDeleteMultipleDocumentsInTrustLedger;
