import currency from "currency.js";

const ProcessBillsAndHoldbacksData = (data) => {
  if (data) {
    if (data?.category === "fireInspection" || data?.category === "zoningReport") {
      const accountDescription =
        data?.category === "fireInspection"
          ? "Fire inspection"
          : data?.category === "zoningReport"
          ? "Zoning report"
          : "";
      return {
        type: "disbursement",
        accountHST: "No",
        accountDescription,
        accountCost: currency(data.amount).value,
        accountHSTAmount: "",
        accountTotal: currency(data.amount).value,
        uniqueID: data.uniqueID,
        entryType: "billsAndHoldbacks",
      };
    }
  }
};
export default ProcessBillsAndHoldbacksData;
