import flatten from "flat";

const GetFieldFromSolicitorOnFile = (document, fieldName) => {
  let solicitorOnFile = "";
  let fieldValueToReturn = "";
  if (document) {
    const type = document?.createFile?.fileType;
    if (type === "Purchase" && document?.purchaser?.solicitor) {
      solicitorOnFile = document?.purchaser?.solicitor;
    } else if (type === "Sale" && document?.vendor?.solicitor) {
      solicitorOnFile = document?.vendor?.solicitor;
    } else if (type === "Mortgage") {
      let mortgageActingFor = document?.fileOpen?.mortgageActingFor;
      let filesPrimaryClient = document?.fileOpen?.filesPrimaryClient;
      if (
        (mortgageActingFor === "mortgagor" ||
          (mortgageActingFor === "both" && filesPrimaryClient === "mortgagor")) &&
        document?.mortgagor?.solicitor
      ) {
        solicitorOnFile = document?.mortgagor?.solicitor;
      } else if (
        (mortgageActingFor === "mortgagee" ||
          (mortgageActingFor === "both" && filesPrimaryClient === "mortgagee")) &&
        document?.mortgageesSolicitor
      ) {
        solicitorOnFile = {
          addressInfo: {
            AddressLine1: document?.mortgageesSolicitor?.mortgageeSolicitorAddressLine1 || "",
            AddressLine2: document?.mortgageesSolicitor?.mortgageeSolicitorAddressLine2 || "",
            City: document?.mortgageesSolicitor?.mortgageeSolicitorCity || "",
            Country: document?.mortgageesSolicitor?.mortgageeSolicitorCountry || "",
            FullAddress: document?.mortgageesSolicitor?.mortgageeSolicitorFullAddress || "",
            PostalCode: document?.mortgageesSolicitor?.mortgageeSolicitorPostalCode || "",
            Province: document?.mortgageesSolicitor?.mortgageeSolicitorProvince || "",
            POBox: document?.mortgageesSolicitor?.mortgageeSolicitorPOBox || "",
          },
          email: document?.mortgageesSolicitor?.otherSolicitorEmail || "",
          faxNumber: document?.mortgageesSolicitor?.otherSolicitorFaxNumber || "",
          firstName: document?.mortgageesSolicitor?.otherSolicitorFirstName || "",
          lastName: document?.mortgageesSolicitor?.otherSolicitorLastName || "",
          middleName: document?.mortgageesSolicitor?.otherSolicitorMiddleName || "",
          primaryPhoneNumber: document?.mortgageesSolicitor?.otherSolicitorNumber || "",
          primaryPhoneNumberExtension:
            document?.mortgageesSolicitor?.otherSolicitorNumberExtension || "",
          lawFirm: document?.mortgageesSolicitor?.otherSolicitorLawFirm || "",
          initials: document?.mortgageesSolicitor?.otherSolicitorInitials || "",
        };
      }
    }
    solicitorOnFile = flatten(solicitorOnFile);
    fieldValueToReturn = solicitorOnFile?.[fieldName] || "";
  }
  return fieldValueToReturn;
};
export const CleanUpObjectsBeforeGetFieldFromSolicitorOnFile = (object) => {
  let ObjectAfterDeleting = { ...object };
  delete ObjectAfterDeleting?.userObject?.stripe;
  delete ObjectAfterDeleting?.userObject?.stewartProfileId;
  delete ObjectAfterDeleting?.userObject?.hubspot;
  delete ObjectAfterDeleting?.userObject?.elasticSearch;
  delete ObjectAfterDeleting?.userObject?.LLCUserToken;
  delete ObjectAfterDeleting?.userObject?.offices;
  return ObjectAfterDeleting;
};
export default GetFieldFromSolicitorOnFile;
