import processCommonExpenses from "./StatementOfAdjustment-CommonExpenses";
import processDeposit from "./StatementOfAdjustment-Deposit";
import processExistingEncumbrances from "./StatementOfAdjustment-ExistingEncumbrances";
import processHSTOnTotalConsideration from "./StatementOfAdjustment-HSTOnTotalConsideration";
import processHstRebate from "./StatementOfAdjustment-HstRebate";
import processNewMortgageAssumedMortgage from "./StatementOfAdjustment-NewMortgageAssumedMortgage";
import processNewMortgageVTBMortgage from "./StatementOfAdjustment-NewMortgageVTBMortgage";
import processOccupancyFees from "./StatementOfAdjustment-OccupancyFees";
import processPrepaidRentAndCurrentRentalPeriod from "./StatementOfAdjustment-prepaidRentAndCurrentRentalPeriod";
import processPropertyTax from "./StatementOfAdjustment-PropertyTax";
import processSalePrice from "./StatementOfAdjustment-SalePrice";
import processTarionWarranty from "./StatementOfAdjustment-TarionWarranty";
import processVtbMortgage from "./StatementOfAdjustment-VtbMortgage";

const StatementOfAdjustmentProcessor = (currentFile) => {
  const finalResult = [];
  /// /////////////////////////////////*Outside the Adjustment Modal*/////////////////////////////////////////////
  processSalePrice(finalResult, currentFile);
  processDeposit(finalResult, currentFile);
  processHSTOnTotalConsideration(finalResult, currentFile);
  processHstRebate(finalResult, currentFile);
  processPropertyTax(finalResult, currentFile);
  processTarionWarranty(finalResult, currentFile);
  processCommonExpenses(finalResult, currentFile);
  processOccupancyFees(finalResult, currentFile);
  processVtbMortgage(finalResult, currentFile);
  processPrepaidRentAndCurrentRentalPeriod(finalResult, currentFile);
  // processCurrentRentalPeriod(finalResult, currentFile);
  // processPrepaidRent(finalResult, currentFile);
  processExistingEncumbrances(finalResult, currentFile);
  processNewMortgageAssumedMortgage(finalResult, currentFile);
  processNewMortgageVTBMortgage(finalResult, currentFile);
  return finalResult;
};
export default StatementOfAdjustmentProcessor;
