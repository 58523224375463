import currency from "currency.js";
import DaysBetweenDates from "src/reusable/DaysBetweenDates";
import GetDaysInYears from "src/reusable/GetDaysInYear";
import useGetOntarioResidentialTenanciesActInterestRates from "./ontarioResidentialTenanciesAct-interestRates";

const CalculateOntarioResidentialTenanciesActAmount = (data) => {
  const { closingDate, commencementDate, prepaidRent } = data;
  const { GetInterestRate } = useGetOntarioResidentialTenanciesActInterestRates();
  const prepaidRentNumber = currency(prepaidRent).value;
  let total = 0;
  const commencementYear = getYear(commencementDate);
  const closingYear = getYear(closingDate);
  let numberOfDays = 0;

  if (commencementYear === closingYear) {
    total =
      ((prepaidRentNumber * (GetInterestRate(commencementYear) / 100)) /
        GetDaysInYears(commencementYear)) *
      DaysBetweenDates({
        startDate: commencementDate,
        endDate: closingDate,
      });
    numberOfDays = DaysBetweenDates({
      startDate: commencementDate,
      endDate: closingDate,
    });
  } else {
    // Exclusive (You want the start date, but not the end date (You do not want to pay the different tax rate of the next year))
    const daysUntilEndOfCommencementYear = DaysBetweenDates({
      startDate: commencementDate,
      endDate: newDateOfYear(commencementYear + 1),
    });

    // Inclusive (You want to add the start and end date. January 1st to 27th, is 27 days. January 1st to January 1st is 1 day.)
    const daysUntilClosingDate = DaysBetweenDates({
      startDate: newDateOfYear(closingYear),
      endDate: closingDate,
    });
    for (let currentYear = commencementYear; currentYear <= closingYear; currentYear++) {
      const daysInBetweenDates =
        currentYear === commencementYear
          ? daysUntilEndOfCommencementYear
          : currentYear === closingYear
          ? daysUntilClosingDate
          : GetDaysInYears(currentYear);
      numberOfDays += daysInBetweenDates;
      total +=
        ((prepaidRentNumber * (GetInterestRate(currentYear) / 100)) / GetDaysInYears(currentYear)) *
        daysInBetweenDates;
    }
  }
  return {
    ontarioResidentialTenanciesActAmount: total,
    numberOfDays,
  };
};
export default CalculateOntarioResidentialTenanciesActAmount;

const newDateOfYear = (year) => {
  return new Date(year, 0, 1);
};

const getYear = (date) => {
  if (date) return date.getFullYear();
};
