import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import { doc, collection, query, where, getDocs, deleteDoc } from "firebase/firestore";

const useDeleteDocumentInSeperateCollection = (nameOfCollection) => {
  const db = useFirestore();
  const { id: urlParamFileID } = useParams();
  const fileID = urlParamFileID;
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);

  const deleteDocumentInSeperateCollection = async ({ uniqueID, fieldToSearchBy }) => {
    const fileRef = `lawFirm/${lawFirmID}/files/${fileID}/${nameOfCollection}`;
    const q = query(collection(db, fileRef), where(fieldToSearchBy || "uniqueID", "==", uniqueID));
    const querySnapshot = await getDocs(q);
    let docID = "";
    querySnapshot.forEach((doc) => {
      docID = doc.id;
    });
    if (docID) {
      const docToDeleteRef = doc(
        db,
        `lawFirm/${lawFirmID}/files/${fileID}/${nameOfCollection}/${docID}`,
      );
      await deleteDoc(docToDeleteRef).catch((e) =>
        console.log("Error saving document to collection"),
      );
    }
  };
  return { deleteDocumentInSeperateCollection };
};
export default useDeleteDocumentInSeperateCollection;
