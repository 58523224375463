/* eslint-disable array-callback-return */
import currency from "currency.js";
import Money from "../Money";

const processFinalSummary = ({ finalResult, firmName }) => {
  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Setting the total values for vendor and purchaser credit
  let purchaserCredit = Number(0);
  let vendorCredit = Number(0);
  let processedFinalResults = [];
  processedFinalResults = processedFinalResults.concat(finalResult);

  finalResult?.map((data) => {
    if (data?.adjustmentItem === "Credit Purchaser") {
      purchaserCredit = currency(purchaserCredit).add(data?.purchaserCredit).value;
    } else if (data?.adjustmentItem === "Credit Vendor") {
      vendorCredit = currency(vendorCredit).add(data?.vendorCredit).value;
    }
  });
  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Setting the value of the balance on closing
  let balanceOnClosing = vendorCredit - purchaserCredit;
  balanceOnClosing = Math.abs(balanceOnClosing);
  let typeOfCreditPayable = "";
  const maxValue = Math.max(purchaserCredit, vendorCredit);

  if (purchaserCredit < maxValue) {
    typeOfCreditPayable = "purchaser";
  }
  if (vendorCredit < maxValue) {
    typeOfCreditPayable = "vendor";
  }
  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if (typeOfCreditPayable === "purchaser") {
    const creditPayableToRow = {
      adjustmentItem: `Balance payable to ${firmName || "vendor's solicitor"}, in trust on closing`,
      purchaserCredit: Money(balanceOnClosing),
      vendorCredit: "",
      automatic: "true",
      _classes: "font-weight-bold final-adjustment-summary-text",
      adjustmentType: "finalAdjustment",
      creditTo: "Purchaser",
      credit: Money(balanceOnClosing),
    };
    processedFinalResults.push(creditPayableToRow);
  }
  if (typeOfCreditPayable === "vendor") {
    const creditVendorRow = {
      adjustmentItem: `Balance payable to ${firmName}, in trust on closing`,
      purchaserCredit: "",
      vendorCredit: Money(balanceOnClosing),
      automatic: "true",
      _classes: "font-weight-bold final-adjustment-summary-text",
      adjustmentType: "finalAdjustment",
      creditTo: "Vendor",
      credit: Money(balanceOnClosing),
    };
    processedFinalResults.push(creditVendorRow);
  }
  const totalCreditRowWithAccounting = {
    adjustmentItem: "Total",
    purchaserCredit: Money(maxValue),
    vendorCredit: Money(maxValue),
    automatic: "true",
    _classes: "font-weight-bold",
  };
  processedFinalResults.push(totalCreditRowWithAccounting);
  return processedFinalResults;
};
export default processFinalSummary;
