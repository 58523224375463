import React from "react";
import { trpc } from "src/shared/utils/trpc";
import { httpLink } from "@trpc/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { isEmulator, isProdEnv } from "src/new-folder-structure/shared/config/env-var";

const queryClient = new QueryClient();
const trpcClient = trpc.createClient({
  links: [
    httpLink({
      url: isEmulator()
        ? "http://localhost:5001/lcs-test-11489/northamerica-northeast1/trpc"
        : isProdEnv()
        ? "https://northamerica-northeast1-quill-master-project.cloudfunctions.net/trpc"
        : "https://northamerica-northeast1-lcs-test-11489.cloudfunctions.net/trpc",
      // You can pass any HTTP headers you wish here
      //   async headers() {
      //     return {
      //       authorization: getAuthCookie(),
      //     };
      //   },
    }),
  ],
  // transformer: undefined,
});
export default function WithTRPC(Component: React.FC) {
  return function Wrapper() {
    return (
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <Component />
        </QueryClientProvider>
      </trpc.Provider>
    );
  };
}
