import currency from "currency.js";
import { intervalToDuration } from "date-fns";
import Handlebars from "handlebars/dist/handlebars";
import HnHHandleDate from "src/reusable/HnHHandleDate";
import Money from "src/reusable/Money";
import {
  FormatDateUtil_date,
  FormatDateUtil_day,
  FormatDateUtil_month,
  getFirstDayOfMonth,
} from "src/util/FormatDateUtil";
import _ from "lodash";
import moment from "moment";
import CalculateDaysBetweenDateStamps from "src/reusable/CalculateDaysBetweenDates";
import GetSolicitorOnFile from "src/reusable/GetSolicitorOnFile";
import GetFieldFromSolicitorOnFile from "src/reusable/GetFieldFromSolicitorOnFile";
import PopulateListOfLastNamesAndFullNames from "src/reusable/PopulateListOfLastNamesAndFullNamesFunction";

let states = 0;
let paragraphToReplace = "";
let addingTotal = 0;

Handlebars.registerHelper("trim", function (string) {
  return string?.trim() || "";
});
Handlebars.registerHelper("greaterThan", function (firstNumber, secondNumber) {
  let firstNumberAsValue = _.isString(firstNumber)
    ? firstNumber?.replaceAll(",", "")?.replaceAll("$", "")
    : firstNumber;
  let secondNumberAsValue = _.isString(secondNumber)
    ? secondNumber?.replaceAll(",", "")?.replaceAll("$", "")
    : secondNumber;
  if (!isNaN(firstNumberAsValue) && !isNaN(secondNumberAsValue)) {
    return _.gt(currency(firstNumberAsValue).value, currency(secondNumberAsValue).value);
  } else return "NaN";
});

Handlebars.registerHelper(
  "PopulateListOfLastNamesAndFullNames",
  function (arrayOfNames, fieldToReturn) {
    let objectOfProcessedNames = PopulateListOfLastNamesAndFullNames({
      arrayOfNames: arrayOfNames,
    });
    let nameFieldToReturn = objectOfProcessedNames[fieldToReturn];
    return nameFieldToReturn;
  },
);

Handlebars.registerHelper("resetParagraph", function () {
  paragraphToReplace = "";
});
Handlebars.registerHelper("appendToParagraph", function (textToAdd) {
  paragraphToReplace += `${textToAdd} `;
});

Handlebars.registerHelper("GetSolicitorOnFileName", function (currentFile) {
  return GetSolicitorOnFile(currentFile);
});
Handlebars.registerHelper("GetFieldFromSolicitorOnFile", function (currentFile, field) {
  return GetFieldFromSolicitorOnFile(currentFile, field);
});
Handlebars.registerHelper("getParagraph", function () {
  return paragraphToReplace;
});
Handlebars.registerHelper("paragraphIsEmpty", function () {
  if (paragraphToReplace.length === 0) return true;
  return false;
});
Handlebars.registerHelper("if_eq", function (a, b, opts) {
  if (a === b) {
    return opts.fn(this);
  }
  return opts.inverse(this);
});
Handlebars.registerHelper("checkForValueInArray", function (valueArray, field, value, opts) {
  const check = valueArray?.filter((item) => item[field] !== value);
  if (check?.length === 0) {
    return opts.fn(this);
  }
  return opts.inverse(this);
});
Handlebars.registerHelper("checkFirstAppearance", function (valueArray, field, value, returnValue) {
  let valueToReturn = "";
  valueArray?.forEach((item) => {
    if (item[field] === value) {
      valueToReturn = item[returnValue];
    }
  });
  return valueToReturn;
});
Handlebars.registerHelper("if_noteq", function (a, b, opts) {
  if (a !== b) {
    return opts.fn(this);
  }
  return opts.inverse(this);
});
Handlebars.registerHelper("isdefined", function (value) {
  return value !== undefined && value !== "";
});

Handlebars.registerHelper("length", function (value) {
  if (Array.isArray(value)) {
    return value.length;
  }
});
Handlebars.registerHelper("returnFirst5Digits", function (value) {
  if (typeof value === "string") return value.substr(0, 5);
  if (typeof value === "number") return value.toString().substr(0, 5);
});

Handlebars.registerHelper("incState", function () {
  const currentValue = states;
  const currentValueIncremented = currentValue + 1;
  states = currentValueIncremented;
  return states;
});
Handlebars.registerHelper("setState", function (value) {
  states = value;
});
Handlebars.registerHelper("getState", function () {
  return states;
});
Handlebars.registerHelper("returnLast4Digits", function (value) {
  if (typeof value === "string") return value.substr(value.length - 4, value.length);
  if (typeof value === "number") {
    return value.toString().substr(value.toString().length - 4, value.toString().length);
  }
});
Handlebars.registerHelper("returnOnlyStreetNumber", function (value) {
  if (typeof value === "string") {
    value = value.replaceAll("&nbsp;", "");
    value = value.trim();
    return value.substr(0, value.indexOf(" "));
  }
});
Handlebars.registerHelper("returnOnlyFirstWordInString", function (value) {
  if (typeof value === "string") {
    value = value.replaceAll("&nbsp;", "");
    value = value.trim();
    return value.substr(0, value.indexOf(" "));
  }
});
Handlebars.registerHelper("returnOnlyStreetName", function (value) {
  if (typeof value === "string") {
    value = value.replaceAll("&nbsp;", "");
    value = value.trim();
    return value.substr(value.indexOf(" ") + 1);
  }
});

Handlebars.registerHelper("currentTime", function () {
  return FormatDateUtil_date(new Date());
});
Handlebars.registerHelper("currentTimeAsDate", function () {
  return new Date();
});
Handlebars.registerHelper("stringify", function (object) {
  return JSON.stringify(object);
});
Handlebars.registerHelper("currentYear", function () {
  return new Date().getFullYear();
});

Handlebars.registerHelper("getTheYear", function (date) {
  if (date) return HnHHandleDate(date)?.getFullYear();
});

Handlebars.registerHelper("toDate", function (date) {
  return HnHHandleDate(date, "string");
});
Handlebars.registerHelper("toDateTime", function (date) {
  if (date?.seconds) return moment(date?.seconds * 1000).format("yyyy-MM-DDTHH:mm:ss");
});
Handlebars.registerHelper("toDateYMD", function (date) {
  return FormatDateUtil_date(HnHHandleDate(date), "yyyy/MM/dd");
});
Handlebars.registerHelper("toDateDay", function (date) {
  return FormatDateUtil_day(HnHHandleDate(date));
});
Handlebars.registerHelper("toDateMonth", function (date) {
  return FormatDateUtil_month(HnHHandleDate(date));
});

Handlebars.registerHelper("toMoney", function (amount) {
  return Money(amount);
});
Handlebars.registerHelper("toNumber", function (amount) {
  return currency(amount).value;
});
Handlebars.registerHelper("toAmount", function (amount) {
  return Money(amount, "none");
});
Handlebars.registerHelper("toCapital", function (string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
});
Handlebars.registerHelper("toCapitalAll", function (string) {
  if (string) return string.toUpperCase();
});
Handlebars.registerHelper("getAddTotal", function () {
  return addingTotal;
});
Handlebars.registerHelper("resetAddTotal", function () {
  addingTotal = 0;
});

Handlebars.registerHelper("addState", function (numberToAdd) {
  if (numberToAdd === undefined || numberToAdd === "") return "";
  addingTotal = currency(numberToAdd).add(addingTotal).value;
});

Handlebars.registerHelper("add", function (firstNumber, secondNumber) {
  if (
    firstNumber === undefined ||
    firstNumber === "" ||
    secondNumber === undefined ||
    secondNumber === ""
  )
    return "";
  let firstNumberAsNumber;
  let secondNumberAsNumber;

  if (isNaN(firstNumber)) {
    try {
      firstNumberAsNumber = firstNumber?.replaceAll("$", "");
      firstNumberAsNumber = firstNumberAsNumber?.replaceAll(",", "");
    } catch (e) {
      firstNumberAsNumber = 0;
    }
  } else {
    firstNumberAsNumber = firstNumber;
  }
  if (isNaN(secondNumber)) {
    try {
      secondNumberAsNumber = secondNumber?.replaceAll("$", "");
      secondNumberAsNumber = secondNumberAsNumber?.replaceAll(",", "");
    } catch (e) {
      secondNumberAsNumber = 0;
    }
  } else {
    secondNumberAsNumber = secondNumber;
  }
  const finalTotal = Number(firstNumberAsNumber) + Number(secondNumberAsNumber);
  if (isNaN(finalTotal)) {
    return "";
  }
  return finalTotal;
});
Handlebars.registerHelper("sub", function (firstNumber, secondNumber) {
  if (
    firstNumber === undefined ||
    firstNumber === "" ||
    secondNumber === undefined ||
    secondNumber === ""
  )
    return "";
  let firstNumberAsNumber;
  let secondNumberAsNumber;
  if (isNaN(firstNumber)) {
    firstNumberAsNumber = firstNumber.replaceAll("$", "");
    firstNumberAsNumber = firstNumberAsNumber.replaceAll(",", "");
  } else {
    firstNumberAsNumber = firstNumber;
  }
  if (isNaN(secondNumber)) {
    secondNumberAsNumber = secondNumber.replaceAll("$", "");
    secondNumberAsNumber = secondNumberAsNumber.replaceAll(",", "");
  } else {
    secondNumberAsNumber = secondNumber;
  }
  const finalTotal = Number(firstNumberAsNumber) - Number(secondNumberAsNumber);
  if (isNaN(finalTotal)) {
    return "";
  }
  return finalTotal;
});
Handlebars.registerHelper("multiply", function (firstNumber, secondNumber) {
  if (
    firstNumber === undefined ||
    firstNumber === "" ||
    secondNumber === undefined ||
    secondNumber === ""
  )
    return "";
  let firstNumberAsNumber;
  let secondNumberAsNumber;
  if (isNaN(firstNumber)) {
    firstNumberAsNumber = firstNumber.replaceAll("$", "");
    firstNumberAsNumber = firstNumberAsNumber.replaceAll(",", "");
  } else {
    firstNumberAsNumber = firstNumber;
  }
  if (isNaN(secondNumber)) {
    secondNumberAsNumber = secondNumber.replaceAll("$", "");
    secondNumberAsNumber = secondNumberAsNumber.replaceAll(",", "");
  } else {
    secondNumberAsNumber = secondNumber;
  }
  const finalTotal = Number(firstNumberAsNumber) * Number(secondNumberAsNumber);
  if (isNaN(finalTotal)) {
    return "";
  }
  return finalTotal;
});
Handlebars.registerHelper("divide", function (firstNumber, secondNumber) {
  if (
    firstNumber === undefined ||
    firstNumber === "" ||
    secondNumber === undefined ||
    secondNumber === ""
  )
    return "";
  let firstNumberAsNumber;
  let secondNumberAsNumber;
  if (isNaN(firstNumber)) {
    firstNumberAsNumber = firstNumber.replaceAll("$", "");
    firstNumberAsNumber = firstNumberAsNumber.replaceAll(",", "");
  } else {
    firstNumberAsNumber = firstNumber;
  }
  if (isNaN(secondNumber)) {
    secondNumberAsNumber = secondNumber.replaceAll("$", "");
    secondNumberAsNumber = secondNumberAsNumber.replaceAll(",", "");
  } else {
    secondNumberAsNumber = secondNumber;
  }
  const finalTotal = Number(firstNumberAsNumber) / Number(secondNumberAsNumber);
  if (isNaN(finalTotal)) {
    return "";
  }
  return finalTotal;
});
Handlebars.registerHelper(
  "principalAmountTotalPercentage",
  function (principalAmount, totalPrincipalAmounts) {
    if (principalAmount && totalPrincipalAmounts)
      return ((Number(principalAmount) / Number(totalPrincipalAmounts)) * 100).toFixed(2);
  },
);
Handlebars.registerHelper(
  "netAdvanceOfFundsTotalPercentage",
  function (totalNetAdvanceOfFunds, propertyPrice) {
    if (totalNetAdvanceOfFunds && propertyPrice)
      return (
        (Number(totalNetAdvanceOfFunds) / Number(propertyPrice.replaceAll(",", ""))) *
        100
      ).toFixed(2);
  },
);
Handlebars.registerHelper("timeBetweenDates", function (firstDate, secondDate) {
  if (firstDate && secondDate) {
    const duration = intervalToDuration({
      start: HnHHandleDate(firstDate),
      end: HnHHandleDate(secondDate),
    });
    return `${duration.days !== 0 ? `${duration.days} days` : ""} ${
      duration.months !== 0 ? `, ${duration.months} months` : ""
    } ${duration.years !== 0 ? `, ${duration.years} years` : ""}`;
  }
});
Handlebars.registerHelper("timeBetweenDatesMonthsAndDays", function (firstDate, secondDate) {
  if (firstDate && secondDate) {
    const duration = intervalToDuration({
      start: HnHHandleDate(firstDate),
      end: HnHHandleDate(secondDate),
    });
    return `${duration.days !== 0 ? `${duration.days} day(s)` : ""} ${
      duration.months + duration.years * 12 !== 0
        ? ` ${duration.months + duration.years * 12} month(s)`
        : ""
    }`;
  }
});

Handlebars.registerHelper("timeBetweenDatesDaysActual", function (firstDate, secondDate) {
  if (firstDate && secondDate) {
    return CalculateDaysBetweenDateStamps({
      startDate: firstDate,
      endDate: secondDate,
    });
  }
});
Handlebars.registerHelper("extractLT", function (description) {
  return description?.substr(description.lastIndexOf("LT"), 8) || "";
});
Handlebars.registerHelper("replaceAll", function (description, replaceCharacters, replaceWith) {
  return description?.replaceAll(replaceCharacters, replaceWith) || "";
});

Handlebars.registerHelper(
  "replaceLastOccurrenceWith",
  function (paragraph, ToReplace, ToReplaceWith) {
    if (paragraph && ToReplace && ToReplaceWith) {
      const lastIndex = paragraph.lastIndexOf(ToReplace);
      if (lastIndex !== -1) {
        const replaced =
          paragraph.substring(0, lastIndex) +
          ToReplaceWith +
          paragraph.substring(lastIndex + ToReplace.length);
        return replaced.replaceAll('"', '\\"').replaceAll(/\r\n|\r|\n/g, "\\n");
      }
    }
  },
);
Handlebars.registerHelper("calculateCustomObjectArrayTotal", function (array, fieldName) {
  return array.map((x) => x[fieldName]).reduce((x, acc) => acc + x);
});
Handlebars.registerHelper("printArrayAsJSONString", function (array) {
  if (_.isEmpty(array)) return "[]";
  return JSON.stringify(array);
});

Handlebars.registerHelper("escapeNewLinePageBreak", function (text) {
  if (text) return text.replaceAll('"', '\\"').replaceAll(/\r\n|\r|\n/g, "\\n");
  return "";
});
Handlebars.registerHelper("getFirstDayOfMonthString", function (date) {
  if (date && HnHHandleDate(date)) {
    return HnHHandleDate(getFirstDayOfMonth(HnHHandleDate(date)), "string");
  }
  return "";
});
Handlebars.registerHelper("getFirstDayOfMonthDate", function (date) {
  if (date && HnHHandleDate(date)) {
    return HnHHandleDate(getFirstDayOfMonth(HnHHandleDate(date)));
  }
  return "";
});

Handlebars.registerHelper(
  "getNewestInstrumentNumberFromInstrumentArray",
  function (arrayOfInstruments) {
    if (arrayOfInstruments && Array.isArray(arrayOfInstruments)) {
      let arrayOfInstrumentsAsDates = arrayOfInstruments.map((element) => {
        let elementRegDate = element?.regdate;
        if (elementRegDate !== "" && elementRegDate !== undefined && elementRegDate !== null) {
          return new Date(HnHHandleDate(elementRegDate));
        } else return 0;
      });
      let maximumDateValue = Math.max(arrayOfInstrumentsAsDates);
      let maxDate = new Date(maximumDateValue);
      let regNumber =
        arrayOfInstruments?.find(
          (instrument) =>
            HnHHandleDate(instrument?.regdate, "string") === HnHHandleDate(maxDate, "string"),
        )?.regnumber || "";
      return regNumber;
    }
  },
);
Handlebars.registerHelper(
  "getOldestInstrumentNumberFromInstrumentArray",
  function (arrayOfInstruments) {
    if (arrayOfInstruments && Array.isArray(arrayOfInstruments)) {
      let arrayOfInstrumentsAsDates = arrayOfInstruments.map((element) => {
        let elementRegDate = element?.regdate;
        if (elementRegDate !== "" && elementRegDate !== undefined && elementRegDate !== null)
          return new Date(HnHHandleDate(elementRegDate));
        else return 0;
      });
      let minDateValue = Math.min(arrayOfInstrumentsAsDates);
      let minDate = new Date(minDateValue);
      let regNumber =
        arrayOfInstruments?.find(
          (instrument) =>
            HnHHandleDate(instrument?.regdate, "string") === HnHHandleDate(minDate, "string"),
        ).regnumber || "";
      return regNumber;
    }
  },
);
Handlebars.registerHelper(
  "checkIfFirstElementOfStringIsEqualToThisLetter",
  function (stringToCheck, letterToCheckAgainst) {
    if (!(stringToCheck && letterToCheckAgainst)) return "";
    if (stringToCheck[0]?.toLowerCase() === letterToCheckAgainst?.toLowerCase()) return true;
  },
);

Handlebars.registerHelper("modifyTime", function (datePassed, operation, valueToModifyBy) {
  if (datePassed && operation && valueToModifyBy) {
    let newDate = "";
    let dateToModify = HnHHandleDate(datePassed);
    if (operation === "add" && !isNaN(valueToModifyBy)) {
      newDate = dateToModify.setDate(dateToModify.getDate() + valueToModifyBy);
    } else if (operation === "sub" && !isNaN(valueToModifyBy)) {
      newDate = dateToModify.setDate(dateToModify.getDate() - valueToModifyBy);
    }

    return HnHHandleDate(newDate);
  }
});
Handlebars.registerHelper("FirstDayOfNextMonth", function (value) {
  const inputAsDate = HnHHandleDate(value);
  const firstDayOfNextMonth = new Date(inputAsDate?.getFullYear(), inputAsDate?.getMonth() + 1, 1);
  return HnHHandleDate(firstDayOfNextMonth);
});
Handlebars.registerHelper("HandleDate", function (value, type) {
  if (type) {
    return HnHHandleDate(value, type);
  } else return HnHHandleDate(value);
});
Handlebars.registerHelper("isThisNumberPositive", function (number) {
  if (number && typeof number === "number") {
    if (number >= 0) return true;
    else return false;
  }
});
export default Handlebars;
