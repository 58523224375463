const useGetOntarioResidentialTenanciesActInterestRates = () => {
  const interestRates = {
    2023: 2.5,
    2022: 1.2,
    2021: 0,
    2020: 2.2,
    2019: 1.8,
    2018: 1.8,
    2017: 1.5,
    2016: 2.0,
    2015: 1.6,
    2014: 0.8,
    2013: 2.5,
    2012: 3.1,
    2011: 0.7,
    2010: 2.1,
    2009: 1.8,
    2008: 1.4,
    2007: 2.6,
    2006: 2.1,
    2005: 1.5,
    2004: 2.9,
    2003: 2.9,
    2002: 3.9,
    2001: 2.9,
    2000: 2.6,
    1999: 3.0,
    1998: 3.0,
    1997: 2.8,
    1996: 2.8,
    1995: 2.9,
    1994: 3.2,
    1993: 4.9,
    1992: 6.0,
    1991: 5.4,
  };
  const GetInterestRate = (year) => {
    if (year && interestRates[year]) {
      return interestRates[year];
    }
    return 0;
  };
  const GetYearIncluded = (year) => {
    if (year && interestRates[year]) {
      return true;
    }
    return false;
  };
  return { GetInterestRate, GetYearIncluded };
};
export default useGetOntarioResidentialTenanciesActInterestRates;
