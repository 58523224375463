const GetSolicitorOnFile = (document) => {
  let solicitorOnFile = "";
  if (document) {
    const type = document?.createFile?.fileType;
    if (
      type === "Purchase" &&
      document?.purchaser?.solicitor?.firstName &&
      document?.purchaser?.solicitor?.lastName
    ) {
      solicitorOnFile =
        document?.purchaser?.solicitor?.firstName &&
        document?.purchaser?.solicitor?.lastName &&
        document?.purchaser?.solicitor?.middleName
          ? `${document?.purchaser?.solicitor?.firstName} ${document?.purchaser?.solicitor?.middleName} ${document?.purchaser?.solicitor?.lastName}`
          : `${document?.purchaser?.solicitor?.firstName} ${document?.purchaser?.solicitor?.lastName}`;
    } else if (
      type === "Sale" &&
      document?.vendor?.solicitor?.firstName &&
      document?.vendor?.solicitor?.lastName
    ) {
      solicitorOnFile =
        document?.vendor?.solicitor?.firstName &&
        document?.vendor?.solicitor?.lastName &&
        document?.vendor?.solicitor?.middleName
          ? `${document?.vendor?.solicitor?.firstName} ${document?.vendor?.solicitor?.middleName} ${document?.vendor?.solicitor?.lastName}`
          : `${document?.vendor?.solicitor?.firstName} ${document?.vendor?.solicitor?.lastName}`;
    } else if (type === "Mortgage") {
      const mortgageActingFor = document?.fileOpen?.mortgageActingFor;
      const filesPrimaryClient = document?.fileOpen?.filesPrimaryClient;
      if (
        (mortgageActingFor === "mortgagor" ||
          (mortgageActingFor === "both" && filesPrimaryClient === "mortgagor")) &&
        document?.mortgagor?.solicitor?.firstName &&
        document?.mortgagor?.solicitor?.lastName
      ) {
        solicitorOnFile =
          document?.mortgagor?.solicitor?.firstName &&
          document?.mortgagor?.solicitor?.lastName &&
          document?.mortgagor?.solicitor?.middleName
            ? `${document?.mortgagor?.solicitor?.firstName} ${document?.mortgagor?.solicitor?.middleName} ${document?.mortgagor?.solicitor?.lastName}`
            : `${document?.mortgagor?.solicitor?.firstName} ${document?.mortgagor?.solicitor?.lastName}`;
      } else if (
        (mortgageActingFor === "mortgagee" ||
          (mortgageActingFor === "both" && filesPrimaryClient === "mortgagee")) &&
        document?.mortgageesSolicitor?.otherSolicitorFirstName &&
        document?.mortgageesSolicitor?.otherSolicitorLastName
      ) {
        solicitorOnFile =
          document?.mortgageesSolicitor?.otherSolicitorFirstName &&
          document?.mortgageesSolicitor?.otherSolicitorLastName &&
          document?.mortgageesSolicitor?.otherSolicitorMiddleName
            ? `${document?.mortgageesSolicitor?.otherSolicitorFirstName} ${document?.mortgageesSolicitor?.otherSolicitorMiddleName} ${document?.mortgageesSolicitor?.otherSolicitorLastName}`
            : `${document?.mortgageesSolicitor?.otherSolicitorFirstName} ${document?.mortgageesSolicitor?.otherSolicitorLastName}`;
      }
    }
  }
  return solicitorOnFile;
};
export default GetSolicitorOnFile;
