const GetVendorSolicitorOnFile = (document) => {
  let solicitorOnFile = "Vendor's solicitor";
  let solicitorLawFirmOnFile = "Vendor's solicitor";
  let solicitorObject = {};
  if (document) {
    const type = document?.createFile?.fileType;
    if (type === "Sale") {
      if (document?.vendor?.solicitor?.firstName && document?.vendor?.solicitor?.lastName) {
        solicitorOnFile =
          document?.vendor?.solicitor?.firstName &&
          document?.vendor?.solicitor?.lastName &&
          document?.vendor?.solicitor?.middleName
            ? `${document?.vendor?.solicitor?.firstName} ${document?.vendor?.solicitor?.middleName} ${document?.vendor?.solicitor?.lastName}`
            : `${document?.vendor?.solicitor?.firstName} ${document?.vendor?.solicitor?.lastName}`;
      }
      solicitorLawFirmOnFile = document?.vendor?.solicitor?.lawFirm
        ? document?.vendor?.solicitor?.lawFirm
        : "Vendor's solicitor";
      solicitorObject = document?.vendor?.solicitor || {};
    } else if (type === "Purchase") {
      const fileOpenPage = document?.fileOpen;
      const purchaseActingFor = fileOpenPage?.purchaseActingFor;
      if (purchaseActingFor === "both") {
        if (document?.vendor?.solicitor?.firstName && document?.vendor?.solicitor?.lastName) {
          solicitorOnFile =
            document?.vendor?.solicitor?.firstName &&
            document?.vendor?.solicitor?.lastName &&
            document?.vendor?.solicitor?.middleName
              ? `${document?.vendor?.solicitor?.firstName} ${document?.vendor?.solicitor?.middleName} ${document?.vendor?.solicitor?.lastName}`
              : `${document?.vendor?.solicitor?.firstName} ${document?.vendor?.solicitor?.lastName}`;
        }
        solicitorLawFirmOnFile = document?.vendor?.solicitor?.lawFirm
          ? document?.vendor?.solicitor?.lawFirm
          : "Vendor's solicitor";
        solicitorObject = document?.vendor?.solicitor || {};
      } else {
        if (
          document?.vendor?.solicitorTable?.[0]?.nameOnFile &&
          document?.vendor?.solicitorTable?.[0]?.nameOnFile !== ""
        ) {
          solicitorOnFile = document?.vendor?.solicitorTable?.[0]?.nameOnFile;
        }
        solicitorLawFirmOnFile = document?.vendor?.solicitorTable?.[0]?.solicitor?.lawFirm
          ? document?.vendor?.solicitorTable?.[0]?.solicitor?.lawFirm
          : "Vendor's solicitor";
        solicitorObject = document?.vendor?.solicitorTable?.[0]?.solicitor || {};
      }
    }
  }
  return { solicitorOnFile, solicitorLawFirmOnFile, solicitorObject };
};
export default GetVendorSolicitorOnFile;
