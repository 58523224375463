import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { PDFDocument } from "pdf-lib";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore, useStorage } from "reactfire";
import Handlebars from "src/util/HnhHandlebar";

import useErrorHandling from "../useErrorHandling.hook";

const useHnHPDFFormField = () => {
  // const HARD_CODED_PATH =
  //   "PDFFormFieldTemplates/GST_HST New Housing Rebate Application for Houses Purchased from a Builder.pdf";
  // const HARD_CODED_NAME =
  //   "GST_HST New Housing Rebate Application for Houses Purchased from a Builder.pdf";

  const storage = useStorage();
  const db = useFirestore();
  const currentFile = useSelector((state) => state?.old?.currentFile);
  const lawFirm = useSelector((state) => state?.old?.lawFirm);
  const { id: currentFileID } = useParams();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);
  const { processError } = useErrorHandling();
  const uploadedDocumentsRef = collection(
    db,
    `lawFirm/${lawFirmID}/files/${currentFileID}/generatedDocumentsMetadata`,
  );
  const TeranetPins = currentFile?.TeranetAPIData;
  const primaryTeranetPins = [];
  if (TeranetPins) {
    const keys = Object.keys(TeranetPins);
    keys.forEach((pin) => {
      primaryTeranetPins.push(TeranetPins[pin][0]);
    });
  }
  const fillPDFFormFields = async (firebaseStorageFileObject, metaDataID = false) => {
    const filePath = firebaseStorageFileObject?.fullPath;
    const fileName = firebaseStorageFileObject?.name;
    const fileId = firebaseStorageFileObject?.NO_ID_FIELD;
    try {
      const formFieldMappingRef = doc(db, `pdfFormFieldMapping/${fileName}`);
      const mappingData = (await getDoc(formFieldMappingRef)).data();

      if (!mappingData) {
        console.error("No mapping found for document:", fileName);
        return;
      }

      //= =================================================================
      // Handlebar
      //= =================================================================
      const generateFn = Handlebars.compile(mappingData.handlebar, {
        noEscape: true,
      });
      const dataForTemplate = {
        currentFile,
        lawFirm,
        primaryTeranetPins,
        // data: mortgage,
      };
      const mappingsJSONAsString = generateFn(dataForTemplate);
      const mappings = JSON.parse(mappingsJSONAsString);

      //= =================================================================
      // Firebase Storage
      //= =================================================================
      const docRef = ref(storage, filePath);
      // await updateMetadata(docRef, { contentDisposition: "attachment" });
      const downloadUrl = await getDownloadURL(docRef);

      //= =================================================================
      // Download form field PDF file
      //= =================================================================
      const formPdfBytes = await fetch(downloadUrl).then((res) => res.arrayBuffer());

      //= =================================================================
      // PDF library to access and set form fields
      //= =================================================================
      const pdfDoc = await PDFDocument.load(formPdfBytes);
      const form = pdfDoc.getForm();
      Object.keys(mappings).forEach((key) => {
        const nameField = form.getTextField(key);
        nameField.setText(mappings[key]);
      });
      const pdfBytes = await pdfDoc.save();
      // const fakeLink = document.createElement("a");
      // fakeLink.href = createObjectURL(pdfBytes);
      // fakeLink.download = "test-pdf-form-fields.pdf";
      // fakeLink.click();

      // save to firebase storage
      // Create a reference to new file to upload

      let docId = "";

      if (metaDataID) {
        docId = metaDataID
      } else {
        const resp = await addDoc(uploadedDocumentsRef, {
          fileId,
          documentName: fileName,
          nameOfDocument: fileName,
          formField: true,
          documentCode: firebaseStorageFileObject?.documentCode || "",
          category: firebaseStorageFileObject?.category || "",
          hasGeneratedPDF: true,
          fullPath: firebaseStorageFileObject?.fullPath || ""
        });
        docId = resp?.id
      }
      if (docId) {
        const newDocToUploadRef = ref(
          storage,
          `lawFirms/${lawFirmID}/files/${currentFileID}/formFieldDocs/${docId}`,
        );
        // 2nd argument is file metadata
        const metadata = {
          customMetadata: {
            formFieldTemplate: fileName,
            NO_ID_FIELD: fileId,
            generatedDocumentID: docId,
            nameOfDocument: fileName,
          },
        };

        await uploadBytes(newDocToUploadRef, pdfBytes, metadata);
      } else {
        console.log("Error generating and filling form field docs, name:", fileName);
      }

      // const newDocToUploadRef = ref(
      //   storage,
      //   `lawFirms/${lawFirmID}/files/${currentFileID}/formFieldDocs/` + fileName
      // );
      // // 2nd argument is file metadata
      // const metadata = {
      //   customMetadata: {
      //     formFieldTemplate: fileName,
      //     NO_ID_FIELD: fileId,
      //   },
      // };

      // const uploadResults = await uploadBytes(
      //   newDocToUploadRef,
      //   pdfBytes,
      //   metadata
      // );

      // if (uploadResults?.code) {
      //   console.log(
      //     "Error generating and filling form field docs, name:",
      //     fileName
      //   );
      // } else {
      //   await addDoc(uploadedDocumentsRef, {
      //     fileId: fileId,
      //     documentName: fileName,
      //   });
      // }
    } catch (e) {
      console.error(`fillPDFFormFields> form field doc name ${fileName}`, e);
      throw e;
      // return;
    }
  };
  const preprocessFormFieldDocuments = async (selectedDocxTemplates) => {
    const formFieldDocuments = selectedDocxTemplates.filter((doc) => doc.fileType === "Form Field");
    const formFieldErrorMessages = [];
    await Promise.all(
      formFieldDocuments.map(async (formFieldDoc) => {
        try {
          await fillPDFFormFields(formFieldDoc);
        } catch (err) {
          const errorMessage = processError(err, formFieldDoc);
          formFieldErrorMessages.push({
            message: errorMessage,
            type: "error",
          });
        }
      }),
    );
    const lcsTempaltes = selectedDocxTemplates.filter((doc) => doc.fileType !== "Form Field");
    return { lcsTempaltes, formFieldErrorMessages };
  };
  return {
    fillPDFFormFields,
    preprocessFormFieldDocuments,
  };
};

export default useHnHPDFFormField;
