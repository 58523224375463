/* eslint-disable array-callback-return */

import _ from "lodash";
import currency from "currency.js";

const ProcessAndSaveTrustLedgerTotalOwing = async ({
  type,
  trustLedgerData,
  saveFileToCollection,
  currentOwingEntry,
}) => {
  if (trustLedgerData && Array.isArray(trustLedgerData)) {
    let totalOwingEntry = {};
    let finalTotal = {
      totalExpenditures: 0,
      totalReceipts: 0,
    };
    const { totalExpenditures, totalReceipts } = calculateTotalExpendituresAndReceipts({
      trustLedgerData,
      currentOwingEntry,
    });
    if (currentOwingEntry && currentOwingEntry?.owingEntryEditedByUser === true) {
      totalOwingEntry = currentOwingEntry;
      finalTotal = {
        totalExpenditures,
        totalReceipts,
      }; 
    } else {
      totalOwingEntry = generateTotalOwingEntry({
        totalExpenditures,
        totalReceipts,
        typeOfFile: type,
      });

      finalTotal = calculateFinalTotalFromReceiptsAndExpenditures({
        totalExpenditures,
        totalReceipts,
      });
    }

    if (!_.isEmpty(totalOwingEntry)) {
      if (currentOwingEntry?.userSpecifiedIndex >= 0) {
        totalOwingEntry.userSpecifiedIndex = currentOwingEntry.userSpecifiedIndex;
      }
      await saveFileToCollection({ formData: totalOwingEntry });
    }
    return finalTotal;
  }
};

const calculateTotalExpendituresAndReceipts = ({ trustLedgerData, currentOwingEntry }) => {
  let trustLedgerDataToProcess = trustLedgerData;
  if (currentOwingEntry && currentOwingEntry?.owingEntryEditedByUser === true) {
    trustLedgerDataToProcess = trustLedgerDataToProcess?.concat(currentOwingEntry);
  }
  let totalExpenditures = 0;
  let totalReceipts = 0;
  trustLedgerDataToProcess?.forEach((item) => {
    if (item?.excludeFromTotal !== true && item?.deletedByUser !== true) {
      if (item?.trustLedgerItemization?.length > 0) {
        if (item?.type === "expenditure") {
          totalExpenditures = currency(totalExpenditures).add(item?.total).format();
        } else if (item?.type === "receipt") {
          totalReceipts = currency(totalReceipts).add(item?.total).format();
        }
      } else {
        totalExpenditures = currency(totalExpenditures)
          .add(item?.trustLedgerExpenditureAmount)
          .format();
        totalReceipts = currency(totalReceipts).add(item?.trustLedgerReceiptAmount).format();
      }
    }
  });
  return { totalExpenditures, totalReceipts };
};

const generateTotalOwingEntry = ({ totalExpenditures, totalReceipts, typeOfFile }) => {
  let totalOwingEntry = {};
  const finalDifference = currency(totalReceipts).subtract(totalExpenditures).value || 0;
  const toBePaidToClient = Math.abs(finalDifference);
  const { typeOfPayment, labelOfPayment } = generateLabelAndTypeOfPaymentOfTotalOwingEntry({
    finalDifferenceBetweenExpendituresAndReceipts: finalDifference,
    typeOfFile,
  });
  if (typeOfPayment === "receipt") {
    totalOwingEntry = {
      type: "receipt",
      trustLedgerItem: labelOfPayment,
      trustLedgerExpenditureAmount: "",
      trustLedgerReceiptAmount: toBePaidToClient,
      typeOfGeneratedEntry: "automaticEntry",
      processorReference: "trustLedgerTotal_owing_receipt",
      excludeFromTotal: true,
      doNotDelete: true,
      uniqueID: "trustLedgerTotal_owing_receipt",
    };
  } else if (typeOfPayment === "expenditure") {
    totalOwingEntry = {
      type: "expenditure",
      trustLedgerItem: labelOfPayment,
      trustLedgerExpenditureAmount: toBePaidToClient,
      trustLedgerReceiptAmount: "",
      processorReference: "trustLedgerTotal_owing_expenditure",
      uniqueID: "trustLedgerTotal_owing_expenditure",
      typeOfGeneratedEntry: "automaticEntry",
      excludeFromTotal: true,
      doNotDelete: true,
    };
  }

  return totalOwingEntry;
};

const generateLabelAndTypeOfPaymentOfTotalOwingEntry = ({
  finalDifferenceBetweenExpendituresAndReceipts,
  typeOfFile,
}) => {
  let typeOfPayment = "";
  let labelOfPayment = "";
  if (finalDifferenceBetweenExpendituresAndReceipts > 0) {
    typeOfPayment = "expenditure";
    if (typeOfFile === "Purchase") {
      labelOfPayment = "Returned to Purchaser";
    } else {
      labelOfPayment = "Paid to you on closing";
    }
  } else if (finalDifferenceBetweenExpendituresAndReceipts < 0) {
    typeOfPayment = "receipt";
    if (typeOfFile === "Purchase") {
      labelOfPayment = "Received from you";
    } else {
      labelOfPayment = "Balance owed by you";
    }
  }
  return { typeOfPayment, labelOfPayment };
};
const calculateFinalTotalFromReceiptsAndExpenditures = ({ totalReceipts, totalExpenditures }) => {
  const max = Math.max(currency(totalReceipts).value, currency(totalExpenditures).value) || 0;
  return {
    totalExpenditures: max,
    totalReceipts: max,
  };
};
export default ProcessAndSaveTrustLedgerTotalOwing;
