import currency from "currency.js";
import GetTarionFee from "../GetTarionFeeNoTax";
import Money from "../Money";

const processTarionWarranty = (finalResult, currentFile) => {
  const tarionWarrantyFile = currentFile?.tarionWarranty;
  const propertyPrice = currency(currentFile?.propertyPrice?.propertyPrice).value;
  const newBuild = currentFile?.property?.newBuild;
  const totalEnrolmentFeeAmount = currency(tarionWarrantyFile?.totalEnrolmentFeeAmount).value;
  const finalTarionValueToDisplay = currency(tarionWarrantyFile?.tarionWarrantyEnrolmentFee).value;
  const enrolmentDate = tarionWarrantyFile?.enrolmentDate;

  if (
    newBuild === "yes" &&
    tarionWarrantyFile &&
    ((propertyPrice && enrolmentDate === "onOrAfterFeb1") ||
      (totalEnrolmentFeeAmount && enrolmentDate === "beforeFeb1"))
  ) {
    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const creditToWhom = tarionWarrantyFile?.creditToWhom;
    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const tarionWarrantyRow = {
      adjustmentItem: "TARION warranty",
      purchaserCredit: "",
      vendorCredit: "",
      _classes: "font-weight-bold main-adjustment-text",
      automatic: "true",
    };
    finalResult.push(tarionWarrantyRow);
    if (enrolmentDate === "beforeFeb1") {
      if (creditToWhom === "vendor") {
        const creditVendorRow = {
          adjustmentItem: "Credit Vendor",
          purchaserCredit: "",
          vendorCredit: Money(finalTarionValueToDisplay, "none"),
          automatic: "true",
        };
        finalResult.push(creditVendorRow);
      }
      if (creditToWhom === "purchaser") {
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          purchaserCredit: Money(finalTarionValueToDisplay, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditPurchaserRow);
      }
      if (creditToWhom === "noAdjustment") {
        const noAdjustmentRow = {
          adjustmentItem: "No adjustment",
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(noAdjustmentRow);
      }
    }
    if (enrolmentDate === "onOrAfterFeb1") {
      const enrolmentFee = GetTarionFee(propertyPrice);
      const hstOfEnrolmentFee = currency(enrolmentFee).multiply(0.13).value;
      const totalHcraFee = 163.85;
      const enrolmentFeeRow = {
        adjustmentItem: `Enrolment fee: ${Money(enrolmentFee)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(enrolmentFeeRow);

      const hstRowRow = {
        adjustmentItem: `HST: ${Money(hstOfEnrolmentFee)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(hstRowRow);
      const totalHcraFeeRow = {
        adjustmentItem: `Total HCRA Fee: ${Money(totalHcraFee)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(totalHcraFeeRow);
      if (creditToWhom === "vendor") {
        const creditVendorRow = {
          adjustmentItem: "Credit Vendor",
          purchaserCredit: "",
          vendorCredit: Money(finalTarionValueToDisplay, "none"),
          automatic: "true",
        };
        finalResult.push(creditVendorRow);
      }
      if (creditToWhom === "purchaser") {
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          purchaserCredit: Money(finalTarionValueToDisplay, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditPurchaserRow);
      }
      if (creditToWhom === "noAdjustment") {
        const noAdjustmentRow = {
          adjustmentItem: "No adjustment",
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(noAdjustmentRow);
      }
    }
  }
};
export default processTarionWarranty;
