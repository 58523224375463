import currency from "currency.js";
import CalculateDaysBetweenDateStamps from "../CalculateDaysBetweenDates";
import Money from "../Money";

const processNewMortgageAssumedMortgage = (finalResult, currentFile) => {
  const newMortgageFile = currentFile?.newMortgagesFile?.newMortgages;
  if (newMortgageFile) {
    const fileOpen = currentFile?.fileOpen;
    const whichDateToChoose = fileOpen?.adjustOn;
    const adjustmentDateTimeStamp = fileOpen?.adjustOnSpecifiedDate;
    const closingDateTimeStamp = currentFile?.createFile?.closingDate;
    // eslint-disable-next-line array-callback-return
    newMortgageFile.map((newMortgage) => {
      if (newMortgage.category === "assumed mortgage" && newMortgage.principalPlusInterest) {
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const priority = newMortgage?.priority;
        const principalAmount = currency(newMortgage?.principalAmount).value;
        const interestRate = newMortgage?.interestRate || "";
        const amountInInterest = currency(newMortgage?.amountInInterest).value;
        const principalPlusInterest = currency(newMortgage?.principalPlusInterest).value;
        const startOfInterestPeriodTimeStamp = newMortgage?.startOfInterestPeriod;
        let daysInBetweenDates;
        let theClosingDate;

        if (whichDateToChoose === "specify") {
          if (adjustmentDateTimeStamp) {
            theClosingDate = adjustmentDateTimeStamp;
          }
        } else if (closingDateTimeStamp) {
          if (closingDateTimeStamp?.seconds) {
            theClosingDate = closingDateTimeStamp;
          }
        }
        daysInBetweenDates = CalculateDaysBetweenDateStamps({
          startDate: theClosingDate,
          endDate: startOfInterestPeriodTimeStamp,
        });
        /// //////////////////////////////////////////////////////////////////////////
        const newMortgageRow = {
          adjustmentItem: `${priority} Priority Assumed Mortgage`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
          _classes: "font-weight-bold main-adjustment-text",
        };
        finalResult.push(newMortgageRow);
        const principalAmountForZDaysRow = {
          adjustmentItem: `Principal amount for ${daysInBetweenDates} days: ${Money(
            principalAmount,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(principalAmountForZDaysRow);
        const totalInterestAccruedAtYforZDaysRow = {
          adjustmentItem: `Total interest accrued at ${interestRate}% for ${daysInBetweenDates} days: ${Money(
            amountInInterest,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(totalInterestAccruedAtYforZDaysRow);
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          purchaserCredit: Money(principalPlusInterest, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditPurchaserRow);
      }
    });
  }
};
export default processNewMortgageAssumedMortgage;
