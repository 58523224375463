/* eslint-disable no-sequences */
import CIcon from "@coreui/icons-react";
import {
  CNav,
  CNavItem,
  CNavLink,
  CSidebar,
  CSidebarClose,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import useCollections from "src/reusable/firestoreHooks/useCollections";
import CheckListBody from "./CheckList-body";

function CheckList() {
  const { GetCollection, collectionData, finishedGettingCollection } = useCollections();
  const dispatch = useDispatch();
  const setState = (state) => dispatch({ type: "set", payload: { showCheckList: state } });

  const showCheckList = useSelector((state) => state?.old?.showCheckList);
  const darkMode = useSelector((state) => state?.old?.darkMode);
  const fileId = useSelector((state) => state?.old?.checkListFileId);
  const fileType = useSelector((state) => state?.old?.checkListFileType);
  useEffect(() => {
    if (fileId) callGetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId, showCheckList]);
  const callGetCheckList = async () => {
    await GetCollection({
      nameOfCollection: "checkList",
      locationOfCollection: "currentFile",
      documentId: "fileClosing",
      fileId,
    });
  };

  return (
    <CSidebar
      aside
      colorScheme="light"
      size="xl"
      show={showCheckList}
      onShowChange={(state) => setState(state)}
    >
      <CSidebarClose onClick={() => setState(false)} />
      <CTabs>
        <CNav variant="tabs" className="nav-underline nav-underline-primary">
          <CNavItem>
            <CNavLink onClick={() => setState(false)}>
              {darkMode ? (
                <CIcon name="cil-list" style={{ color: "white" }} />
              ) : (
                <CIcon name="cil-list" />
              )}
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane>
            {showCheckList === true && finishedGettingCollection === true ? (
              <CheckListBody
                showCheckList={showCheckList}
                defaultValues={collectionData}
                fileId={fileId}
                fileType={fileType}
              />
            ) : (
              ""
            )}
          </CTabPane>
        </CTabContent>
      </CTabs>
      <hr />
    </CSidebar>
  );
}

export default React.memo(CheckList);
