/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CCol, CForm, CFormGroup, CLabel } from "@coreui/react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import DateInput from "src/reusable/DateInput";
import useSaveDocumentToCollection from "src/reusable/firestoreHooks/useSaveDocumentToCollection";
import HnHToggleWithoutLabel from "src/reusable/HnHToggleWithoutLabel";

const CheckListBody = ({ showCheckList, defaultValues, fileId, fileType }) => {
  // const { updateContactsCurrentAddress } = useUpdateContactsCurrentAddress();
  const methods = useForm({
    defaultValues:
      fileType === "Sale"
        ? {
            dealClosed: defaultValues?.dealClosed ? defaultValues.dealClosed : "no",
            dealClosedDate: defaultValues?.dealClosedDate ? defaultValues?.dealClosedDate : "",
            clientReport: defaultValues?.clientReport ? defaultValues?.clientReport : "no",
            clientReportDate: defaultValues?.clientReportDate
              ? defaultValues?.clientReportDate
              : "",
            undertakings: defaultValues?.undertakings ? defaultValues?.undertakings : "no",
            undertakingsDate: defaultValues?.undertakingsDate
              ? defaultValues?.undertakingsDate
              : "",
            fileStillActive: defaultValues?.fileStillActive
              ? defaultValues?.fileStillActive
              : "yes",
            fileInactiveDate: defaultValues?.fileInactiveDate
              ? defaultValues?.fileInactiveDate
              : "",
            transactionClosed: defaultValues?.transactionClosed
              ? defaultValues?.transactionClosed
              : "no",
          }
        : {
            dealClosed: defaultValues?.dealClosed ? defaultValues.dealClosed : "no",
            dealClosedDate: defaultValues?.dealClosedDate ? defaultValues?.dealClosedDate : "",
            changeOfOwnership: defaultValues?.changeOfOwnership
              ? defaultValues?.changeOfOwnership
              : "no",
            changeOfOwnershipDate: defaultValues?.changeOfOwnershipDate
              ? defaultValues?.changeOfOwnershipDate
              : "",
            titleInsurancePolicy: defaultValues?.titleInsurancePolicy
              ? defaultValues?.titleInsurancePolicy
              : "no",
            titleInsurancePolicyDate: defaultValues?.titleInsurancePolicyDate
              ? defaultValues?.titleInsurancePolicyDate
              : "",
            lenderReport: defaultValues?.lenderReport ? defaultValues?.lenderReport : "no",
            lenderReportDate: defaultValues?.lenderReportDate
              ? defaultValues?.lenderReportDate
              : "",
            clientReport: defaultValues?.clientReport ? defaultValues?.clientReport : "no",
            clientReportDate: defaultValues?.clientReportDate
              ? defaultValues?.clientReportDate
              : "",
            undertakings: defaultValues?.undertakings ? defaultValues?.undertakings : "no",
            undertakingsDate: defaultValues?.undertakingsDate
              ? defaultValues?.undertakingsDate
              : "",
            fileStillActive: defaultValues?.fileStillActive
              ? defaultValues?.fileStillActive
              : "yes",
            fileInactiveDate: defaultValues?.fileInactiveDate
              ? defaultValues?.fileInactiveDate
              : "",
            transactionClosed: defaultValues?.transactionClosed
              ? defaultValues?.transactionClosed
              : "no",
          },
  });

  const { handleSubmit, watch } = methods;
  const { saveFileToCollection } = useSaveDocumentToCollection("checkList");
  const theForm = watch();

  useEffect(() => {
    if (showCheckList === true) {
      onSubmit(theForm);
    }
  }, [theForm]);

  const onSubmit = async (formData) => {
    if (fileId) {
      await saveFileToCollection({
        formData: formData,
        currentFileId: fileId,
        documentId: "fileClosing",
      });
    }
  };

  const [
    dealClosed,
    dealClosedDate,
    changeOfOwnership,
    changeOfOwnershipDate,
    titleInsurancePolicy,
    titleInsurancePolicyDate,
    lenderReport,
    lenderReportDate,
    clientReport,
    clientReportDate,
    undertakings,
    undertakingsDate,
    fileStillActive,
    fileInactiveDate,
    transactionClosed,
    updateContactAddresses,
  ] = watch([
    "dealClosed",
    "dealClosedDate",
    "changeOfOwnership",
    "changeOfOwnershipDate",
    "titleInsurancePolicy",
    "titleInsurancePolicyDate",
    "lenderReport",
    "lenderReportDate",
    "clientReport",
    "clientReportDate",
    "undertakings",
    "undertakingsDate",
    "fileStillActive",
    "fileInactiveDate",
    "transactionClosed",
    "updateContactAddresses",
  ]);
  // const updateContacts = async () => {
  //   await updateContactsCurrentAddress();
  // };
  return (
    <>
      <div
        className="ml-4 mr-4"
        style={{
          height: "100vh",
          maxHeight: "90vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <br />
        <h2>File Closing Checklist</h2>
        <br />
        <FormProvider {...methods}>
          <CForm
            className="form-horizontal needs-validation"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <CFormGroup>
              <CCol md="12">
                <CLabel>Deal closed?</CLabel>
              </CCol>
              <CCol md="12">
                <HnHToggleWithoutLabel
                  name="dealClosed"
                  buttons={[
                    { name: "Yes", value: "yes" },
                    { name: "No", value: "no" },
                  ]}
                />
              </CCol>
            </CFormGroup>
            {dealClosed === "yes" ? (
              <>
                {/* <CFormGroup>
                  <CCol md="12">
                    <CTooltip content="By clicking yes, you will update the current address of each vendor and purchaser with their post closing address for future use. Please ensure that you have clicked the 'Save this client to your contacts', in order for this functionality to work">
                      <CButton color="primary" onClick={() => updateContacts()}>
                        Update current addresses
                      </CButton>
                    </CTooltip>
                  </CCol>
                </CFormGroup> */}

                <CFormGroup>
                  <CCol md="12">
                    <CLabel>Date done</CLabel>
                  </CCol>
                  <CCol md="12">
                    <DateInput name="dealClosedDate" />
                  </CCol>
                </CFormGroup>
              </>
            ) : (
              ""
            )}
            <hr />
            {fileType === "Purchase" || fileType === "Mortgage" ? (
              <>
                <CFormGroup>
                  <CCol md="12">
                    <CLabel>Change of Ownership sent for Tax Dept?</CLabel>
                  </CCol>
                  <CCol md="12">
                    <HnHToggleWithoutLabel
                      name="changeOfOwnership"
                      buttons={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                    />
                  </CCol>
                </CFormGroup>
                {changeOfOwnership === "yes" ? (
                  <>
                    <CFormGroup>
                      <CCol md="12">
                        <CLabel>Date done</CLabel>
                      </CCol>
                      <CCol md="12">
                        <DateInput name="changeOfOwnershipDate" />
                      </CCol>
                    </CFormGroup>
                  </>
                ) : (
                  ""
                )}
                <hr />
                <CFormGroup>
                  <CCol md="12">
                    <CLabel>Title Insurance Policy Paid?</CLabel>
                  </CCol>
                  <CCol md="12">
                    <HnHToggleWithoutLabel
                      name="titleInsurancePolicy"
                      buttons={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                    />
                  </CCol>
                </CFormGroup>
                {titleInsurancePolicy === "yes" ? (
                  <>
                    <CFormGroup>
                      <CCol md="12">
                        <CLabel>Date done</CLabel>
                      </CCol>
                      <CCol md="12">
                        <DateInput name="titleInsurancePolicyDate" />
                      </CCol>
                    </CFormGroup>
                  </>
                ) : (
                  ""
                )}
                <hr />
                <CFormGroup>
                  <CCol md="12">
                    <CLabel>Lender Report Completed?</CLabel>
                  </CCol>
                  <CCol md="12">
                    <HnHToggleWithoutLabel
                      name="lenderReport"
                      buttons={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                    />
                  </CCol>
                </CFormGroup>
                {lenderReport === "yes" ? (
                  <>
                    <CFormGroup>
                      <CCol md="12">
                        <CLabel>Date done</CLabel>
                      </CCol>
                      <CCol md="12">
                        <DateInput name="lenderReportDate" />
                      </CCol>
                    </CFormGroup>
                  </>
                ) : (
                  ""
                )}
                <hr />
              </>
            ) : (
              ""
            )}

            <CFormGroup>
              <CCol md="12">
                <CLabel>Client Report Completed?</CLabel>
              </CCol>
              <CCol md="12">
                <HnHToggleWithoutLabel
                  name="clientReport"
                  buttons={[
                    { name: "Yes", value: "yes" },
                    { name: "No", value: "no" },
                  ]}
                />
              </CCol>
            </CFormGroup>
            {clientReport === "yes" ? (
              <>
                <CFormGroup>
                  <CCol md="12">
                    <CLabel>Date done</CLabel>
                  </CCol>
                  <CCol md="12">
                    <DateInput name="clientReportDate" />
                  </CCol>
                </CFormGroup>
              </>
            ) : (
              ""
            )}
            <hr />
            <CFormGroup>
              <CCol md="12">
                <CLabel>Undertakings Fulfilled?</CLabel>
              </CCol>
              <CCol md="12">
                <HnHToggleWithoutLabel
                  name="undertakings"
                  buttons={[
                    { name: "Yes", value: "yes" },
                    { name: "No", value: "no" },
                  ]}
                />
              </CCol>
            </CFormGroup>
            {undertakings === "yes" ? (
              <>
                <CFormGroup>
                  <CCol md="12">
                    <CLabel>Date done</CLabel>
                  </CCol>
                  <CCol md="12">
                    <DateInput name="undertakingsDate" />
                  </CCol>
                </CFormGroup>
              </>
            ) : (
              ""
            )}
            <hr />
            <CFormGroup>
              <CCol md="12">
                <CLabel>Is the file active?</CLabel>
              </CCol>
              <CCol md="12">
                <HnHToggleWithoutLabel
                  name="fileStillActive"
                  buttons={[
                    { name: "Yes", value: "yes" },
                    { name: "No", value: "no" },
                  ]}
                />
              </CCol>
            </CFormGroup>
            {fileStillActive === "no" ? (
              <>
                <CFormGroup>
                  <CCol md="12">
                    <CLabel>Date done</CLabel>
                  </CCol>
                  <CCol md="12">
                    <DateInput name="fileInactiveDate" />
                  </CCol>
                </CFormGroup>
              </>
            ) : (
              ""
            )}
          </CForm>
        </FormProvider>
      </div>
    </>
  );
};

export default CheckListBody;
