import currency from "currency.js";
import CalculateDaysBetweenDateStamps from "../CalculateDaysBetweenDates";
import HnHHandleDate from "../HnHHandleDate";
import Money from "../Money";

const processExistingEncumbrances = (finalResult, currentFile) => {
  const existingEncumbrancesFile = currentFile?.existingEncumbrances?.encumbrances;
  const fileOpen = currentFile?.fileOpen;
  const whichDateToChoose = fileOpen?.adjustOn;
  const adjustmentDateTimeStamp = fileOpen?.adjustOnSpecifiedDate;
  const closingDateTimeStamp = currentFile?.createFile?.closingDate;

  if (existingEncumbrancesFile) {
    // eslint-disable-next-line array-callback-return
    existingEncumbrancesFile.map((existingEncumbrance) => {
      if (
        existingEncumbrance.encumbranceIsTo === "be assumed" &&
        existingEncumbrance.category?.value === "Mortgage" &&
        existingEncumbrance?.principalPlusInterest &&
        existingEncumbrance?.startOfInterestPeriod
      ) {
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////
        const priority = existingEncumbrance?.priority;
        const principalAmount = currency(existingEncumbrance?.amountDueForPayout).value;
        // const interestRate = existingEncumbrance?.interestRate;
        const amountInInterest = currency(existingEncumbrance?.amountInInterest).value;
        const principalWithInterest = currency(existingEncumbrance?.principalPlusInterest).value;
        const startOfInterestPeriod = existingEncumbrance?.startOfInterestPeriod;
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        let closingDate;
        let daysInBetweenDates;
        if (whichDateToChoose === "closingDate") {
          closingDate = HnHHandleDate(closingDateTimeStamp, "date");
        }
        if (whichDateToChoose === "specify") {
          closingDate = HnHHandleDate(adjustmentDateTimeStamp, "date");
        }
        daysInBetweenDates = CalculateDaysBetweenDateStamps({
          startDate: closingDate,
          endDate: startOfInterestPeriod,
        });
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const existingEncumbranceRow = {
          adjustmentItem: `${priority} Priority Assumed Mortgage`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
          _classes: "font-weight-bold main-adjustment-text",
        };
        finalResult.push(existingEncumbranceRow);
        const principalAmountForZDaysRow = {
          adjustmentItem: `Principal amount for ${daysInBetweenDates} days: ${Money(
            principalAmount,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(principalAmountForZDaysRow);
        const totalInterestAccruedAtYForZDaysRow = {
          adjustmentItem: `Total interest accrued for ${daysInBetweenDates} days: ${Money(
            amountInInterest,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(totalInterestAccruedAtYForZDaysRow);
        const principalWithInterestRow = {
          adjustmentItem: "Credit Purchaser",
          purchaserCredit: Money(principalWithInterest, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(principalWithInterestRow);
      }
    });
  }
};
export default processExistingEncumbrances;
