import { useState } from "react";
import useGenerateDocxTemplate from "src/old-folder-structure/sharedComponents/generateDocument/useGenerateDocxTemplate.hook";
import useGetTemplateData from "src/old-folder-structure/sharedComponents/generateDocument/useGetTemplateData";
import useHnHPDFFormField from "src/old-folder-structure/sharedComponents/generateDocument/useHnHPDFFormField.hook";
import usePreProcessGenerateDocumentLogic from "src/old-folder-structure/sharedComponents/generateDocument/usePreProcessGenerateDocumentLogic.hook";

const useGenerateDocument = () => {
  const [generating, setGenerating] = useState(false);
  const linkToGenerateDocumentGuide =
    "https://www.help.lawyerconveyance.ca/build-your-own-docx-guide-byod";

  const [generatedDocMessage, setGeneratedDocMessage] = useState({
    type: "",
    message: "",
  });
  const [generatedFile, setGeneratedFile] = useState();

  const { preprocessFormFieldDocuments } = useHnHPDFFormField();
  const { getTemplateData } = useGetTemplateData();
  const { preprocessGenerateDocumentLogic } = usePreProcessGenerateDocumentLogic();
  const { generateSingleFile } = useGenerateDocxTemplate();

  const handleGenerateDocumentButton = async (
    selectedTemplate,
    fileData,
    selectedItem = {},
    font = null,
    fontForLetter,
    fontForNoneLetter,
  ) => {
    setGeneratedDocMessage({ type: "", message: "" });
    setGenerating(true);
    const selectedDocs = selectedTemplate;
    const { lcsTempaltes } = await preprocessFormFieldDocuments(selectedDocs);

    const docxTemplate = await preprocessGenerateDocumentLogic(lcsTempaltes);
    if (docxTemplate) {
      await Promise.allSettled(
        docxTemplate.map(async (docxTemplateMetadata) => {
          try {
            const templateData = await getTemplateData(selectedItem, fileData);
            let thePropToPass = docxTemplateMetadata?.fullPath
              ? docxTemplateMetadata
              : { fileToUpload: docxTemplateMetadata };

            let fileLink = await generateSingleFile(
              thePropToPass,
              templateData,
              false,
              font,
              fontForLetter,
              fontForNoneLetter,
            );
            const file = new File([fileLink], selectedItem?.fileName || "generatedDocument", {
              type: fileLink.type,
            });
            setGeneratedFile(file);
            setGeneratedDocMessage({
              type: "success",
              message: "Document generated successfully",
            });
          } catch (error) {
            console.error("this is the error", error);
            setGeneratedDocMessage({
              type: "danger",
              message: "Error while generating document",
            });
          }
        }),
      );
    }
    setGenerating(false);
  };
  const handleMultipleLogic = ({
    selectedFile,
    multiple,
    multipleCollection,
    billsAndHoldbacks,
  }) => {
    let multipleData = {};
    if (multiple === "yes") {
      if (multipleCollection === "newMortgages") {
        multipleData = {
          data: selectedFile?.newMortgagesFile?.newMortgages?.[0] || {},
        };
      } else if (multipleCollection === "encumbrances") {
        multipleData = {
          data: selectedFile?.existingEncumbrances?.encumbrances?.[0] || {},
        };
      } else if (multipleCollection === "vtbMortgages") {
        multipleData = {
          data: selectedFile?.vtbMortgages?.vtbMortgages?.[0] || {},
        };
      } else if (multipleCollection === "executionAffidavits") {
        multipleData = {
          data: selectedFile?.ExecutionAffidavitsHandlebar?.[0]?.data?.[0] || {},
        };
      } else if (multipleCollection === "billsHoldbacks" && billsAndHoldbacks) {
        multipleData = { data: billsAndHoldbacks?.[0] || {} };
      } else if (multipleCollection === "purchasers") {
        multipleData = {
          data: selectedFile?.purchaser?.purchaser?.[0] || {},
        };
      } else if (multipleCollection === "vendors") {
        multipleData = { data: selectedFile?.vendor?.vendors?.[0] || {} };
      } else if (multipleCollection === "mortgagors") {
        multipleData = {
          data: selectedFile?.mortgagor?.mortgagors?.[0] || {},
        };
      }
    }
    return multipleData;
  };
  return {
    handleGenerateDocumentButton,
    generating,
    generatedDocMessage,
    generatedFile,
    handleMultipleLogic,
    linkToGenerateDocumentGuide,
  };
};

export default useGenerateDocument;
