/* build environment:
 * "development":  using npm start
 * "production": if using npm run build
 * "test": if using npm test
 * Note: this is not deployment environment
 */
export const { NODE_ENV } = process.env;
export const DEPLOYMENT_TARGET = process.env.REACT_APP_TARGET_DEPLOYMENT;

/* production build using npm run build and specified prod in the environment variable */
export const isProdEnv = () => NODE_ENV === "production" && DEPLOYMENT_TARGET === "prod";
export const isEmulator = () => process.env.REACT_APP_FUNCTIONS_EMULATOR === "true";

export const firebaseEnvVars =
  DEPLOYMENT_TARGET === "prod"
    ? {
        FIREBASE_API_KEY: "AIzaSyAqMiB_40dtfg4WyjnhQtTIsPhBzUtnSMA",
        FIREBASE_PROJECT_ID: "quill-master-project",
        FIREBASE_APP_ID: "1:505544438561:web:da0d4703787f51801c846",
        FIREBASE_MESSAGING_SENDER_ID: "505544438561",
        FIREBASE_MEASUREMENT_ID: "G-QRTW3J5TQ8",
      }
    : {
        FIREBASE_API_KEY: "AIzaSyAx0pBxIu4ID3qNz0kKy08Cab1u1rKURnQ",
        FIREBASE_PROJECT_ID: "lcs-test-11489",
        FIREBASE_APP_ID: "1:213220412209:web:cf690c627e491153d33e10",
        FIREBASE_MESSAGING_SENDER_ID: "213220412209",
        FIREBASE_MEASUREMENT_ID: "G-630CFZW0VR",
      };

export const FIREBASE_FUNCTIONS_REGION = process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION;
