import currency from "currency.js";
import _ from "lodash";
import CalculateDaysBetweenDateStamps from "../CalculateDaysBetweenDates";
import HnHHandleDate from "../HnHHandleDate";
import Money from "../Money";

const processPropertyTax = (finalResult, currentFile) => {
  const propertyTaxFile = currentFile?.propertyTax;
  if (propertyTaxFile) {
    const whichDateToChoose = currentFile?.fileOpen?.adjustOn;
    const adjustmentDateTimeStamp = currentFile?.fileOpen?.adjustOnSpecifiedDate;
    let daysInBetweenDates;
    let vendorsShareForXDays;
    let vendorHasPaid;
    let perDiemDays;
    let yearToCheck;
    function leapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }
    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (whichDateToChoose === "specify") {
      if (adjustmentDateTimeStamp) {
        let adjustmentDate = adjustmentDateTimeStamp;
        if (adjustmentDate.seconds) {
          adjustmentDate = HnHHandleDate(adjustmentDate);
        }
        yearToCheck = adjustmentDate.getFullYear();
        if (leapYear(yearToCheck)) {
          perDiemDays = 366;
        } else {
          perDiemDays = 365;
        }
        adjustmentDate.setHours(0, 0, 0, 0);
        const firstDayOfYear = new Date(adjustmentDate.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);
        daysInBetweenDates = CalculateDaysBetweenDateStamps({
          startDate: firstDayOfYear,
          endDate: adjustmentDate,
        });
      }
    } else {
      const closingDateTimeStamp = currentFile?.createFile?.closingDate;
      const closingDate = HnHHandleDate(closingDateTimeStamp);
      yearToCheck = closingDate.getFullYear();
      if (leapYear(yearToCheck)) {
        perDiemDays = 366;
      } else {
        perDiemDays = 365;
      }
      closingDate.setHours(0, 0, 0, 0);
      const firstDayOfYear = new Date(closingDate.getFullYear(), 0, 1);
      firstDayOfYear.setHours(0, 0, 0, 0);
      daysInBetweenDates = CalculateDaysBetweenDateStamps({
        startDate: firstDayOfYear,
        endDate: closingDate,
      });
    }
    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (Array.isArray(propertyTaxFile?.taxes) && !_.isEmpty(propertyTaxFile?.taxes)) {
      propertyTaxFile?.taxes?.forEach((tax) => {
        const customTaxName = tax?.label;
        const propertyTaxRow = {
          adjustmentItem:
            propertyTaxFile?.taxes?.length > 1 ? `Property Tax - ${customTaxName}` : "Property Tax",
          purchaserCredit: "",
          vendorCredit: "",
          _classes: "font-weight-bold main-adjustment-text",
          automatic: "true",
        };
        finalResult.push(propertyTaxRow);

        if (tax?.basedOnWhichTaxBill === "final") {
          const totalTaxesRow = {
            adjustmentItem: `Total taxes: ${Money(tax?.totalTaxesForTheYearTaxBillFinal)}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(totalTaxesRow);

          if (tax?.vendorPaidEntireBillTaxBillFinal === "yes") {
            vendorHasPaid = tax?.totalTaxesForTheYearTaxBillFinal;
            const vendorHasPaidRow = {
              adjustmentItem: `Vendor has paid: ${Money(tax?.totalTaxesForTheYearTaxBillFinal)}`,
              purchaserCredit: "",
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(vendorHasPaidRow);
          } else {
            vendorHasPaid = tax?.vendorWillHavePaidTaxBillFinal;
            const vendorHasPaidRow = {
              adjustmentItem: `Vendor has paid: ${Money(tax?.vendorWillHavePaidTaxBillFinal)}`,
              purchaserCredit: "",
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(vendorHasPaidRow);
          }
          vendorsShareForXDays =
            (currency(tax?.totalTaxesForTheYearTaxBillFinal).value / perDiemDays) *
            daysInBetweenDates;
          const vendorsShareForXDaysRow = {
            adjustmentItem: `Vendor's share for ${daysInBetweenDates} days: ${Money(
              vendorsShareForXDays,
            )}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };

          finalResult.push(vendorsShareForXDaysRow);

          if (currency(vendorHasPaid).value > currency(vendorsShareForXDays).value) {
            const creditVendor = {
              adjustmentItem: "Credit Vendor",
              adjustmentType: "tax",
              customTaxName,
              vendorShares: vendorsShareForXDays,
              vendorHasPaid,
              creditTo: "Vendor",
              purchaserCredit: "",
              vendorCredit: Money(
                currency(vendorHasPaid).subtract(vendorsShareForXDays).value,
                "none",
              ),
              automatic: "true",
            };
            finalResult.push(creditVendor);
          } else {
            const creditPurchaser = {
              adjustmentItem: "Credit Purchaser",
              adjustmentType: "tax",
              customTaxName,
              vendorShares: vendorsShareForXDays,
              vendorHasPaid,
              creditTo: "Purchaser",
              purchaserCredit: Money(
                currency(vendorsShareForXDays).subtract(vendorHasPaid).value,
                "none",
              ),
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(creditPurchaser);
          }
        }
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        else if (tax?.basedOnWhichTaxBill === "estimatedFinal") {
          const estimatedTotalTaxesRow = {
            adjustmentItem: `Current year's estimated taxes: ${Money(
              tax?.estimatedTotalTaxesForTheYearTaxBillEstimatedFinal,
            )}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(estimatedTotalTaxesRow);

          if (tax?.vendorPaidEntireBillTaxBillEstimatedFinal === "yes") {
            vendorHasPaid = tax?.estimatedTotalTaxesForTheYearTaxBillEstimatedFinal;
            const vendorHasPaidRow = {
              adjustmentItem: `Vendor has paid: ${Money(
                tax?.estimatedTotalTaxesForTheYearTaxBillEstimatedFinal,
              )}`,
              purchaserCredit: "",
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(vendorHasPaidRow);
          } else {
            vendorHasPaid = tax?.vendorWillHavePaidTaxBillEstimatedFinal;
            const vendorHasPaidRow = {
              adjustmentItem: `Vendor has paid: ${Money(
                tax?.vendorWillHavePaidTaxBillEstimatedFinal,
              )}`,
              purchaserCredit: "",
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(vendorHasPaidRow);
          }

          vendorsShareForXDays =
            (currency(tax?.estimatedTotalTaxesForTheYearTaxBillEstimatedFinal).value /
              perDiemDays) *
            daysInBetweenDates;
          const vendorsShareForXDaysRow = {
            adjustmentItem: `Vendor's share for ${daysInBetweenDates} days: ${Money(
              vendorsShareForXDays,
            )}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };

          finalResult.push(vendorsShareForXDaysRow);

          if (currency(vendorHasPaid).value > currency(vendorsShareForXDays).value) {
            const creditVendor = {
              adjustmentItem: "Credit Vendor",
              purchaserCredit: "",
              adjustmentType: "tax",
              customTaxName,
              vendorShares: vendorsShareForXDays,
              vendorHasPaid,
              creditTo: "Vendor",
              vendorCredit: Money(
                currency(vendorHasPaid).subtract(vendorsShareForXDays).value,
                "none",
              ),
              automatic: "true",
            };
            finalResult.push(creditVendor);
          } else {
            const creditPurchaser = {
              adjustmentItem: "Credit Purchaser",
              adjustmentType: "tax",
              customTaxName,
              vendorShares: vendorsShareForXDays,
              vendorHasPaid,
              creditTo: "Purchaser",
              purchaserCredit: Money(
                currency(vendorsShareForXDays).subtract(vendorHasPaid).value,
                "none",
              ),
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(creditPurchaser);
          }
        }
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        else if (tax?.basedOnWhichTaxBill === "previous") {
          const previousYearsTotalTaxes = {
            adjustmentItem: `Previous year's total taxes: ${Money(tax?.totalTaxesTaxBillPrevious)}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(previousYearsTotalTaxes);
          const estimatedIncreaseForThisYear = {
            adjustmentItem: `Estimated increase for this year: ${tax?.estimatedIncreaseTaxBillPrevious}%`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(estimatedIncreaseForThisYear);
          const percentageIncrease =
            currency(tax?.estimatedIncreaseTaxBillPrevious).value / 100 + 1;
          const totalTaxesForThisYear = percentageIncrease * tax?.totalTaxesTaxBillPrevious;
          const estimatedTaxes = {
            adjustmentItem: `Current year's estimated taxes: ${Money(totalTaxesForThisYear)}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(estimatedTaxes);

          if (tax?.vendorPaidEntireBillTaxBillPrevious === "yes") {
            vendorHasPaid = totalTaxesForThisYear;
            const vendorHasPaidRow = {
              adjustmentItem: `Vendor has paid: ${Money(totalTaxesForThisYear)}`,
              purchaserCredit: "",
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(vendorHasPaidRow);
          } else {
            vendorHasPaid = tax?.amountVendorPaidTaxBillPrevious;
            const vendorHasPaidRow = {
              adjustmentItem: `Vendor has paid: ${Money(tax?.amountVendorPaidTaxBillPrevious)}`,
              purchaserCredit: "",
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(vendorHasPaidRow);
          }

          vendorsShareForXDays =
            (currency(totalTaxesForThisYear).value / perDiemDays) * daysInBetweenDates;
          const vendorsShareForXDaysRow = {
            adjustmentItem: `Vendor's share for ${daysInBetweenDates} days: ${Money(
              vendorsShareForXDays,
            )}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };

          finalResult.push(vendorsShareForXDaysRow);

          if (vendorHasPaid > vendorsShareForXDays) {
            const creditVendor = {
              adjustmentItem: "Credit Vendor",
              adjustmentType: "tax",
              customTaxName,
              vendorShares: vendorsShareForXDays,
              vendorHasPaid,
              creditTo: "Vendor",
              purchaserCredit: "",
              vendorCredit: Money(
                currency(vendorHasPaid).subtract(vendorsShareForXDays).value,
                "none",
              ),
              automatic: "true",
            };
            finalResult.push(creditVendor);
          } else {
            const creditPurchaser = {
              adjustmentItem: "Credit Purchaser",
              adjustmentType: "tax",
              customTaxName,
              vendorShares: vendorsShareForXDays,
              vendorHasPaid,
              creditTo: "Purchaser",
              purchaserCredit: Money(
                currency(vendorsShareForXDays).subtract(vendorHasPaid).value,
                "none",
              ),
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(creditPurchaser);
          }
        }
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        else if (tax?.basedOnWhichTaxBill === "interim") {
          const currentYearsInterimTaxes = {
            adjustmentItem: `Current year's interim taxes: ${Money(
              tax?.currentYearTaxBillInterim,
            )}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(currentYearsInterimTaxes);
          const estimatedIncreaseForThisYear = {
            adjustmentItem: `Estimated increase for this year: ${tax?.estimatedIncreaseTaxBillInterim}%`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(estimatedIncreaseForThisYear);
          const percentageIncrease = currency(tax?.estimatedIncreaseTaxBillInterim).value / 100 + 1;
          const totalTaxesForThisYear = percentageIncrease * tax?.currentYearTaxBillInterim * 2;
          const estimatedTaxes = {
            adjustmentItem: `Current year's estimated taxes: ${Money(totalTaxesForThisYear)}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(estimatedTaxes);

          if (tax?.vendorPaidEntireBillTaxBillInterim === "yes") {
            vendorHasPaid = tax?.currentYearTaxBillInterim;
            const vendorHasPaidRow = {
              adjustmentItem: `Vendor has paid: ${Money(vendorHasPaid)}`,
              purchaserCredit: "",
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(vendorHasPaidRow);
          } else {
            vendorHasPaid = tax?.amountVendorPaidTaxBillInterim;
            const vendorHasPaidRow = {
              adjustmentItem: `Vendor has paid: ${Money(tax?.amountVendorPaidTaxBillInterim)}`,
              purchaserCredit: "",
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(vendorHasPaidRow);
          }

          vendorsShareForXDays =
            (currency(totalTaxesForThisYear).value / perDiemDays) * daysInBetweenDates;
          const vendorsShareForXDaysRow = {
            adjustmentItem: `Vendor's share for ${daysInBetweenDates} days: ${Money(
              vendorsShareForXDays,
            )}`,
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };

          finalResult.push(vendorsShareForXDaysRow);

          if (vendorHasPaid > vendorsShareForXDays) {
            const creditVendor = {
              adjustmentItem: "Credit Vendor",
              adjustmentType: "tax",
              customTaxName,
              vendorShares: vendorsShareForXDays,
              vendorHasPaid,
              creditTo: "Vendor",
              purchaserCredit: "",
              vendorCredit: Money(
                currency(vendorHasPaid).subtract(vendorsShareForXDays).value,
                "none",
              ),
              automatic: "true",
            };
            finalResult.push(creditVendor);
          } else {
            const creditPurchaser = {
              adjustmentItem: "Credit Purchaser",
              adjustmentType: "tax",
              customTaxName,
              vendorShares: vendorsShareForXDays,
              vendorHasPaid,
              creditTo: "Purchaser",
              purchaserCredit: Money(
                currency(vendorsShareForXDays).subtract(vendorHasPaid).value,
                "none",
              ),
              vendorCredit: "",
              automatic: "true",
            };
            finalResult.push(creditPurchaser);
          }
        }
        /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        else if (tax?.basedOnWhichTaxBill === "notAssessed") {
          const noAdjustment = {
            adjustmentItem: "Not assessed, no adjustment",
            purchaserCredit: "",
            vendorCredit: "",
            automatic: "true",
          };
          finalResult.push(noAdjustment);
        }
      });
    }
  }
};
export default processPropertyTax;
