import { addDoc, collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import { loadFile } from "src/reducers";
import FilterBadData from "./FilterBadData";

const useSaveFileToDatabase = () => {
  const db = useFirestore();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);

  const { id: currentFileId } = useParams();
  const dispatch = useDispatch();
  const [fetchingFile, setFetchingFile] = useState(false);
  const [fetchedFileData, setFetchedFileData] = useState([]);
  const [currentFileData, setCurrentFileData] = useState([]);
  const [currentLawfirmData, setCurrentLawfirmData] = useState([]);

  const [savingFile, setSavingFile] = useState(false);
  const [newDataToFetch, setNewDataToFetch] = useState(false);
  const [editingFile, setEditingFile] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);

  const currentFileDocRef = doc(db, `lawFirm/${lawFirmID}/files/${currentFileId}`);
  const currentLawfirmReference = doc(db, `lawFirm/${lawFirmID}`);
  /// /////////////////////////////////////////////////

  const fetchAllFiles = async () => {
    const fileData = await (await getDoc(currentFileDocRef)).data();
    setCurrentFileData(fileData);
    return fileData;
  };
  const fetchFile = async (path) => {
    setFetchingFile(true);
    const fileData = await (await getDoc(currentFileDocRef)).data();
    const existingData = _.get(fileData, path);
    if (existingData) {
      setFetchingFile(false);
      setFetchedFileData(existingData);
      return existingData;
    }
    setFetchingFile(false);
    setFetchedFileData([]);
  };

  const saveFile = async (path, data) => {
    setSavingFile(true);
    const fileData = await (await getDoc(currentFileDocRef)).data();
    const existingData = _.get(fileData, path);
    if (existingData) {
      data.uniqueID = Math.random();
      const finalData = existingData.concat(data);
      await updateDoc(currentFileDocRef, path, finalData);
    } else {
      const finalData = data;
      finalData.uniqueID = Math.random();
      if (Array.isArray(finalData)) await updateDoc(currentFileDocRef, path, finalData);
      else await updateDoc(currentFileDocRef, path, [finalData]);
    }
    setSavingFile(false);
    setNewDataToFetch(true);
    dispatch(loadFile(currentFileId));
  };
  const updateFile = async (path, data) => {
    setSavingFile(true);
    await updateDoc(currentFileDocRef, path, data);
    setSavingFile(false);
    setNewDataToFetch(true);
    dispatch(loadFile(currentFileId));
  };
  const editFile = async (path, data) => {
    const fileData = await (await getDoc(currentFileDocRef)).data();
    const existingData = _.get(fileData, path);
    if (existingData && data?.uniqueID) {
      setEditingFile(true);
      const finalData = existingData.filter((e) => e.uniqueID !== data.uniqueID).concat(data);
      await updateDoc(currentFileDocRef, path, finalData);
      setEditingFile(false);
      setNewDataToFetch(true);
      dispatch(loadFile(currentFileId));
    } else if (!data.uniqueID) console.log("The uniqueID is not passed!");
  };
  const deleteFile = async (path, data) => {
    const fileData = await (await getDoc(currentFileDocRef)).data();
    const existingData = _.get(fileData, path);
    if (existingData && data?.uniqueID) {
      setDeletingFile(true);
      const finalData = existingData.filter((e) => e.uniqueID !== data.uniqueID);
      await updateDoc(currentFileDocRef, path, finalData);
      setDeletingFile(false);
      setNewDataToFetch(true);
      dispatch(loadFile(currentFileId));
    } else if (!data.uniqueID) console.log("The uniqueID is not passed!");
  };
  const removeTabbedIndex = async (path, data) => {
    // This should be renamed. Its not really overwrite. Its a delete. (Currently used to remove a specific index from existing encumbrances and new mortgages)
    const fileData = (await getDoc(currentFileDocRef)).data();
    const existingData = _.get(fileData, path);
    if (existingData && existingData.some((e) => e.uniqueID === data.uniqueID)) {
      const filteredData = existingData.filter((e) => e.uniqueID !== data.uniqueID);
      await updateDoc(currentFileDocRef, path, filteredData);
    }
  };
  const replaceFields = async ({ path, data, shouldDispatch }) => {
    if (currentFileId) {
      const filteredData = FilterBadData(data);
      setSavingFile(true);
      await updateDoc(currentFileDocRef, path, filteredData);
      setSavingFile(false);
      if (shouldDispatch === true) dispatch(loadFile(currentFileId));
    }
  };

  const clearFileSection = async ({ path, shouldDispatch }) => {
    await updateDoc(currentFileDocRef, path, "");
    if (shouldDispatch) {
      dispatch(loadFile(currentFileId));
    }
  };
  const saveToLawfirmFields = async (data) => {
    const filteredData = FilterBadData(data);
    setSavingFile(true);
    await updateDoc(currentLawfirmReference, filteredData);
    setSavingFile(false);
    setNewDataToFetch(true);
    dispatch(loadFile(currentFileId));
  };
  const fetchLawfirmFields = async () => {
    const fileData = (await getDoc(currentLawfirmReference)).data();
    setCurrentLawfirmData(fileData);
    return fileData;
  };
  const fetchFontsList = async () => {
    const querySnapshot = await getDocs(collection(db, "fontsList"));
    const fonts = [];
    const myDocumentRef = doc(collection(db, "lawFirm"), `${lawFirmID}`);
    const subCollectionRef = collection(myDocumentRef, "specificFonts");
    const querySnapshotCustomFont = await getDocs(subCollectionRef);
    querySnapshot.forEach((doc) => {
      const documentData = doc.data();
      fonts.push(documentData);
    });
    querySnapshotCustomFont.forEach((doc) => {
      const documentData = doc.data();
      fonts.push(documentData);
    });
    return fonts;
  };
  const createNewSpecificFont = async (font) => {
    const myDocumentRef = doc(collection(db, "lawFirm"), `${lawFirmID}`);
    const subCollectionRef = collection(myDocumentRef, "specificFonts");
    await addDoc(subCollectionRef, font);
  };
  return {
    saveFile,
    savingFile,
    editFile,
    editingFile,
    deleteFile,
    deletingFile,
    fetchFile,
    fetchingFile,
    fetchedFileData,
    newDataToFetch,
    setNewDataToFetch,
    fetchAllFiles,
    currentFileData,
    removeTabbedIndex,
    clearFileSection,
    replaceFields,
    saveToLawfirmFields,
    fetchLawfirmFields,
    currentLawfirmData,
    updateFile,
    fetchFontsList,
    createNewSpecificFont,
  };
};
export default useSaveFileToDatabase;
