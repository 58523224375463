/* eslint-disable array-callback-return */
import currency from "currency.js";
import Money from "../Money";

const processSalePrice = (finalResult, currentFile) => {
  const newBuild = currentFile?.property?.newBuild;
  const propertyHasBeenRenovated = currentFile?.property?.propertyHasBeenRenovated;

  const provincialRebatePortion = currentFile?.propertyPrice?.provincialRebatePortion || 0;
  const federalRebatePortion = currentFile?.propertyPrice?.federalRebatePortion || 0;

  const Hst_provincialPortion = currentFile?.propertyPrice?.Hst_provincialPortion || 0;
  const Hst_federalPortion = currentFile?.propertyPrice?.Hst_federalPortion || 0;
  const hstRebate = currentFile?.propertyPrice?.hstRebate || 0;

  const additionalConsiderationAmountIncludingHST =
    currentFile?.propertyPrice?.additionalConsiderationAmountIncludingHST;
  const creditToPurchaserAmountIncludingHST =
    currentFile?.propertyPrice?.creditToPurchaserAmountIncludingHST;
  const propertyPriceFile = currentFile?.propertyPrice;
  const propertyPrice = propertyPriceFile?.propertyPrice;
  const agreedSalePrice = propertyPriceFile?.finalPriceIncludingTax;
  const hstSelection = propertyPriceFile?.taxesIncluded;
  const showHSTParticularsInStatementOfAdjustments =
    propertyPriceFile?.showHSTParticularsInStatementOfAdjustments;

  const hstRebateSection = currentFile?.propertyPrice?.addHstRebateToSOA;

  if (propertyPriceFile && currency(propertyPrice).value) {
    //***************************** Not a new home ******************************* *//
    if (
      newBuild !== "yes" &&
      propertyHasBeenRenovated !== "yes" &&
      showHSTParticularsInStatementOfAdjustments === "yes" &&
      hstSelection === "addHst"
    ) {
      const saleRow = {
        adjustmentItem: "Sale Price",
        purchaserCredit: "",
        vendorCredit: "",
        credit: Money(agreedSalePrice), // TODO
        _classes: "font-weight-bold main-adjustment-text",
        automatic: "true",
      };
      finalResult.push(saleRow);
      const agreedSalePriceRow = {
        adjustmentItem: "Agreed sale price: " + Money(agreedSalePrice),
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(agreedSalePriceRow);

      const subtractHSTFederalPortionRow = {
        adjustmentItem: "- HST Federal Portion (5%): " + Money(Hst_federalPortion),
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(subtractHSTFederalPortionRow);

      const subtractHSTProvincialPortionRow = {
        adjustmentItem: "- HST Provincial Portion (8%): " + Money(Hst_provincialPortion),
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(subtractHSTProvincialPortionRow);
      const netSalePriceRow = {
        adjustmentItem: "= Net sale price: " + Money(propertyPrice),
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(netSalePriceRow);
      const creditVendor = {
        adjustmentItem: "Credit Vendor",
        purchaserCredit: "",
        vendorCredit: Money(propertyPrice, "none"),
        automatic: "true",
      };
      finalResult.push(creditVendor);
    } else if (newBuild !== "yes" && propertyHasBeenRenovated !== "yes") {
      const saleRow = {
        adjustmentItem: "Sale Price",
        purchaserCredit: "",
        vendorCredit: "",
        credit: Money(agreedSalePrice),
        _classes: "font-weight-bold main-adjustment-text",
        automatic: "true",
      };
      finalResult.push(saleRow);
      const creditVendor = {
        adjustmentItem: "Credit Vendor",
        purchaserCredit: "",
        vendorCredit: Money(agreedSalePrice, "none"),
        automatic: "true",
      };
      finalResult.push(creditVendor);
    }
    let totalExtrasAndUpgradesValue = Number(0);
    let totalCreditToPurchaserAmountIncludingHST = Number(0);

    //* **************************** Property is a new home ******************************* *//
    if ((newBuild === "yes" && hstSelection !== "none") || propertyHasBeenRenovated === "yes") {
      if (currency(additionalConsiderationAmountIncludingHST).value > 0) {
        totalExtrasAndUpgradesValue = currency(additionalConsiderationAmountIncludingHST).value;
      }
      if (currency(creditToPurchaserAmountIncludingHST).value > 0) {
        totalCreditToPurchaserAmountIncludingHST = currency(
          creditToPurchaserAmountIncludingHST,
        ).value;
      }
      let totalRebateValue = Number(0);
      if (hstRebateSection === "yes") {
        totalRebateValue = currency(hstRebate).value;
      }
      const totalIncludingHSTValueToShow = currency(agreedSalePrice)
        .add(totalExtrasAndUpgradesValue)
        .subtract(totalCreditToPurchaserAmountIncludingHST).value;

      const totalIncludingHSTValue = currency(agreedSalePrice)
        .add(totalExtrasAndUpgradesValue)
        .subtract(totalCreditToPurchaserAmountIncludingHST)
        .add(totalRebateValue).value;

      const netHSTPortion = currency(totalIncludingHSTValue).multiply(0.13).divide(1.13).value;
      const hstProvincialPortion = currency(totalIncludingHSTValue)
        .subtract(netHSTPortion)
        .multiply(0.08).value;

      const hstFederalPortion = currency(totalIncludingHSTValue)
        .subtract(netHSTPortion)
        .multiply(0.05).value;

      const totalHSTPortions = currency(hstProvincialPortion).add(hstFederalPortion).value;

      const netSalePriceValue = currency(totalIncludingHSTValue).subtract(totalHSTPortions).value;

      const saleRow = {
        adjustmentItem: "Sale Price",
        purchaserCredit: "",
        vendorCredit: "",
        credit: Money(netSalePriceValue),
        _classes: "font-weight-bold main-adjustment-text",
        automatic: "true",
      };
      finalResult.push(saleRow);
      const agreedSalePriceRow = {
        adjustmentItem: `Agreed sale price: ${Money(agreedSalePrice)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(agreedSalePriceRow);

      if (currency(additionalConsiderationAmountIncludingHST).value > 0) {
        const extrasAndUpgradesRow = {
          adjustmentItem: `+ Additional consideration: ${Money(totalExtrasAndUpgradesValue)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(extrasAndUpgradesRow);
      }
      if (currency(creditToPurchaserAmountIncludingHST).value > 0) {
        const extrasAndUpgradesRow = {
          adjustmentItem: `- Credit to Purchaser: ${Money(
            totalCreditToPurchaserAmountIncludingHST,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(extrasAndUpgradesRow);
      }
      if (hstRebateSection === "yes") {
        const totalIncludingHSTRow = {
          adjustmentItem: `= Total including HST: ${Money(totalIncludingHSTValueToShow)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(totalIncludingHSTRow);
      }

      const subtractHSTFederalPortionRow = {
        adjustmentItem: `- HST Federal Portion (5%): ${Money(Hst_federalPortion)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(subtractHSTFederalPortionRow);

      const subtractHSTProvincialPortionRow = {
        adjustmentItem: `- HST Provincial Portion (8%): ${Money(Hst_provincialPortion)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(subtractHSTProvincialPortionRow);
      if (hstRebateSection === "yes") {
        const hstRebateFederalPortionRow = {
          adjustmentItem: `+ HST Rebate Federal Portion: ${Money(federalRebatePortion)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(hstRebateFederalPortionRow);
        const hstRebateProvincialPortionRow = {
          adjustmentItem: `+ HST Rebate Provincial Portion: ${Money(provincialRebatePortion)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(hstRebateProvincialPortionRow);
      }

      const netSalePriceRow = {
        adjustmentItem: `= Net sale price: ${Money(netSalePriceValue)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(netSalePriceRow);
      const creditVendorRow = {
        adjustmentItem: "Credit Vendor",
        purchaserCredit: "",
        vendorCredit: Money(netSalePriceValue, "none"),
        automatic: "true",
      };
      finalResult.push(creditVendorRow);
    }
  }
};
export default processSalePrice;
