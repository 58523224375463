import currency from "currency.js";
import _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Money from "src/reusable/Money";
import useGetCollectionManual from "src/reusable/firestoreHooks/useGetCollectionManual";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";
import useStatementOfAccount from "src/views/pages/Shared/statementOfAccount/processor/useStatementOfAccount";
import useGenerateTrustChequeMadePayableTo from "src/views/pages/Shared/trustLedger/functions/useGenerateTrustChequeMadePayableTo";
import useUpdateStatementOfAdjustments from "src/views/pages/Shared/trustLedger/processors/useUpdateStatementOfAdjustments";

const useDirectionReFundsProcessor = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { replaceFields, fetchAllFiles, fetchFile } = useSaveFileToDatabase();
  const { UpdateStatementOfAccount } = useStatementOfAccount();
  const { UpdateStatementOfAdjustments } = useUpdateStatementOfAdjustments();
  const { GenerateTrustChequeMadePayableTo } = useGenerateTrustChequeMadePayableTo();
  const { getCollectionFromPath } = useGetCollectionManual();
  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);

  const [currentBalanceDueOnClosing, setCurrentBalanceDueOnClosing] = useState(0);
  const [originalBalanceDueOnClosing, setOriginalBalanceDueOnClosing] = useState(0);

  const [balanceOfFundsDropdown, setBalanceOfFundsDropdown] = useState([]);
  const [directedToDropdown, setDirectedToDrowndown] = useState([]);
  const { type: fileType } = useParams();

  const populateBalanceOfFundsDropdown = async () => {
    const trustChequeMadePayableTo = await GenerateTrustChequeMadePayableTo({
      currentFile: await fetchAllFiles(),
      users: await getCollectionFromPath(`lawFirm/${lawFirmID}/users`),
    });
    let balanceOfFundsDropdown = trustChequeMadePayableTo
      ? [
          {
            value: "lawFirmInTrust",
            label: `${trustChequeMadePayableTo}, in trust`,
          },
          {
            value: "custom",
            label: "Custom",
          },
        ]
      : [
          {
            value: "custom",
            label: "Custom",
          },
        ];
    setBalanceOfFundsDropdown(balanceOfFundsDropdown);
    return balanceOfFundsDropdown;
  };
  const populateDirectedToDropdown = async () => {
    const currentFile = await fetchAllFiles();

    let directedToDropdownOptions = [
      {
        value: "none",
        label: "Please select",
        amount: Number(0),
      },
      {
        value: "custom",
        label: "Custom",
        amount: Number(0),
      },
    ];
    const encumbranceOptions = await fetchEncumbranceOptions(currentFile);
    const brokerOptions = await fetchBrokerOptions(currentFile);
    const statementOfAccountOptions = await fetchStatementOfAccountOptions();
    if (encumbranceOptions)
      directedToDropdownOptions = directedToDropdownOptions.concat(encumbranceOptions);
    if (brokerOptions) directedToDropdownOptions = directedToDropdownOptions.concat(brokerOptions);
    if (statementOfAccountOptions)
      directedToDropdownOptions = directedToDropdownOptions.concat(statementOfAccountOptions);

    setDirectedToDrowndown(directedToDropdownOptions);
    return directedToDropdownOptions;
  };
  const fetchEncumbranceOptions = async () => {
    const currentFile = await fetchAllFiles();
    if (!currentFile?.existingEncumbrances?.encumbrances) return [];
    const encumbrances = currentFile?.existingEncumbrances?.encumbrances;
    let encumbranceOptions = encumbrances.flatMap((encumbrance) => {
      const category = encumbrance?.category?.value || "";

      if (
        (category === "Vendor take-back mortgage" &&
          encumbrance?.encumbranceIsTo === "be discharged") ||
        (category === "Mortgage" && encumbrance?.encumbranceIsTo === "be discharged")
      ) {
        const description = `Discharge ${encumbrance?.documentTitle} (${Money(
          encumbrance?.finalAmountToDischargeTheEncumbrance,
        )})${encumbrance?.listOfFullNames !== "" ? ` to ${encumbrance?.listOfFullNames}` : ""}`;
        return {
          value: description,
          label: description,
          amount: Money(encumbrance?.finalAmountToDischargeTheEncumbrance, "none"),
        };
      }
      if (category === "Bridge loan" && encumbrance?.encumbranceIsTo === "be discharged") {
        const description = `Payout Bridge Loan (${Money(
          encumbrance?.finalAmountToPayoutBridgeLoan,
        )})${encumbrance?.listOfFullNames !== "" ? ` to ${encumbrance?.listOfFullNames}` : ""}`;
        return {
          value: description,
          label: description,
          amount: Money(encumbrance?.finalAmountToPayoutBridgeLoan, "none"),
        };
      }
      if (
        (category === "Certificate of Lien (Housing Development Act)" ||
          category.substr(0, category.indexOf(" ")) === "Notice" ||
          category === "Other" ||
          category === "Construction Lien" ||
          category === "Lien") &&
        encumbrance?.encumbranceIsTo === "be discharged"
      ) {
        const description = `Discharge ${encumbrance?.documentTitle} (${Money(
          encumbrance?.finalAmountToDischargeTheEncumbrance,
        )})${encumbrance?.listOfFullNames !== "" ? ` to ${encumbrance?.listOfFullNames}` : ""}`;
        return {
          value: description,
          label: description,
          amount: Money(encumbrance?.finalAmountToDischargeTheEncumbrance, "none"),
        };
      }
      return [];
    });
    return encumbranceOptions || [];
  };
  const fetchBrokerOptions = async () => {
    const currentFile = await fetchAllFiles();
    if (fileType !== "Sale") return [];
    const brokerOptions = [];
    const brokerSelected = currentFile?.property?.realEstateBrokersBeingUsed;
    const vendorBrokerCommissionWithHst = currentFile?.brokers?.totalCommissionAmount;
    const purchasersCommissionWithHST = currentFile?.brokers?.purchasersTotalCommissionAmount;
    const brokersPaidTo = currentFile?.brokers?.vendorsPaidTo;
    if (brokerSelected === "yes") {
      if (brokersPaidTo === "vendorsBrokerOnly") {
        if (currency(vendorBrokerCommissionWithHst).value >= 0) {
          const description = "Pay vendor's agent commission";
          brokerOptions.push({
            value: description,
            label: description,
            amount: Money(vendorBrokerCommissionWithHst, "none"),
          });
        }
      } else if (brokersPaidTo === "purchasersBrokerOnly") {
        if (currency(purchasersCommissionWithHST).value >= 0) {
          const descriptionForPurchaser = "Pay purchaser's agent commission";
          brokerOptions.push({
            value: descriptionForPurchaser,
            label: descriptionForPurchaser,
            amount: Money(purchasersCommissionWithHST, "none"),
          });
        }
      } else if (brokersPaidTo === "bothBrokers") {
        if (currency(purchasersCommissionWithHST).value >= 0) {
          const descriptionForPurchaser = "Pay purchaser's agent commission";
          brokerOptions.push({
            value: descriptionForPurchaser,
            label: descriptionForPurchaser,
            amount: Money(purchasersCommissionWithHST, "none"),
          });
        }
        if (currency(vendorBrokerCommissionWithHst).value >= 0) {
          const descriptionForVendor = "Pay vendor's agent commission";
          brokerOptions.push({
            value: descriptionForVendor,
            label: descriptionForVendor,
            amount: Money(vendorBrokerCommissionWithHst, "none"),
          });
        }
      }
    }
    return brokerOptions;
  };
  const fetchStatementOfAccountOptions = async () => {
    await UpdateStatementOfAccount();
    const currentFile = await fetchAllFiles();
    if (!currentFile?.StatementOfAccountFile?.Summary) return [];
    const statementOfAccountFile = currentFile?.StatementOfAccountFile?.Summary;
    const statementOfAccountOptions = [];
    const balanceOwing = currency(statementOfAccountFile?.totalAmountWithHst).value;
    if (currency(balanceOwing).value > 0) {
      const description = "Pay off balance owed by client in the statement of account";
      statementOfAccountOptions.push({
        value: description,
        label: description,
        amount: Money(balanceOwing, "none"),
      });
    }
    return statementOfAccountOptions;
  };
  const calculateOriginalBalanceDueOnClosing = async () => {
    const currentFile = await fetchAllFiles();
    const originalBalanceDueOnClosing = Math.abs(
      currency(currentFile?.statementOfAdjustments?.balanceDueOnClosing).value || 0,
    );
    setOriginalBalanceDueOnClosing(originalBalanceDueOnClosing);
    return originalBalanceDueOnClosing;
  };
  const calculateBalanceOfFundsPayableTo = async ({
    directionReFundsData,
    originalBalanceDueOnClosing,
  }) => {
    const listOfSelectedDirectedTo = directionReFundsData?.directedTos;
    const balanceOfFundsPayableTo = directionReFundsData?.balanceOfFundsPayableTo;
    const customBalanceOfFundsPayableToMoneyField =
      directionReFundsData?.customBalanceOfFundsPayableToMoneyField;
    let sumOfAllDirectedToFields = Number(0);
    let currentBalanceDueOnClosing = originalBalanceDueOnClosing || 0;
    listOfSelectedDirectedTo?.forEach((dropDownOption) => {
      if (dropDownOption?.directedToMoneyField) {
        sumOfAllDirectedToFields = currency(sumOfAllDirectedToFields).add(
          dropDownOption?.directedToMoneyField,
        ).value;
      }
      if (dropDownOption.customDirectedToMoneyField && dropDownOption.directedToMoneyField === 0) {
        sumOfAllDirectedToFields = currency(sumOfAllDirectedToFields).add(
          dropDownOption?.customDirectedToMoneyField,
        ).value;
      }
    });
    if (balanceOfFundsPayableTo !== "custom" && balanceOfFundsPayableTo !== "none") {
      currentBalanceDueOnClosing = currency(originalBalanceDueOnClosing).subtract(
        sumOfAllDirectedToFields,
      ).value;
    } else {
      currentBalanceDueOnClosing = currency(customBalanceOfFundsPayableToMoneyField).subtract(
        sumOfAllDirectedToFields,
      ).value;
    }
    setCurrentBalanceDueOnClosing(Money(currentBalanceDueOnClosing, "none"));

    return Money(currentBalanceDueOnClosing, "none");
  };
  const updateDirectedTos = async ({ currentPageData, newDropDown }) => {
    const currentListOfDirectedTos = currentPageData?.directedTos || [];
    const filteredListOfDirectedTos =
      currentListOfDirectedTos?.filter(
        (e) =>
          newDropDown.some((item) => e?.directedTo === item?.value) || e?.directedTo === "custom",
      ) || [];
    const filteredListOfDirectedTosWithUpdatedAmounts =
      filteredListOfDirectedTos?.map((oldElement) => {
        if (oldElement?.directedTo === "custom") {
          return oldElement;
        }
        const theSameDirectedToEntryInTheUpdatedList = newDropDown?.find(
          (newElement) => newElement?.label === oldElement?.directedTo,
        );
        return {
          directedTo: theSameDirectedToEntryInTheUpdatedList?.label,
          directedToMoneyField: theSameDirectedToEntryInTheUpdatedList?.amount,
        };
      }) || [];
    const updatedPageData = {
      ...currentPageData,
      directedTos: filteredListOfDirectedTosWithUpdatedAmounts,
    };
    if (Array.isArray(currentListOfDirectedTos) && currentListOfDirectedTos.length > 0) {
      await updateDirectedToPage(updatedPageData);
    } else {
      setIsLoading(false);
    }

    return updatedPageData;
  };

  const updateDirectedToPage = async (directionFundsFields) => {
    const arrayOfPageEntries = Object.entries(directionFundsFields);
    arrayOfPageEntries?.forEach((entry) => callReplaceFields({ key: entry[0], value: entry[1] }));
    setIsLoading(false);
  };
  const callReplaceFields = async ({ key, value }) => {
    await replaceFields({
      path: `directionReFunds.${key}`,
      data: value,
      shouldDispatch: true,
    });
  };

  const processDirectionREFundPage = async () => {
    const directionFundsData = await fetchFile("directionReFunds");
    if (!_.isEmpty(directionFundsData)) {
      await UpdateStatementOfAdjustments({
        currentFile: await fetchAllFiles(),
      });
      const originalBalanceDueOnClosing = await calculateOriginalBalanceDueOnClosing();

      const directedToDropdown = await populateDirectedToDropdown();
      const updatedPageData = await updateDirectedTos({
        currentPageData: directionFundsData,
        newDropDown: directedToDropdown,
      });
      const currentBalanceDueOnClosing = await calculateBalanceOfFundsPayableTo({
        directionReFundsData: updatedPageData,
        originalBalanceDueOnClosing,
      });
      await callReplaceFields({
        key: "balanceOfFundsPayableToMoneyField",
        value: currentBalanceDueOnClosing,
      });
    } else {
      setIsLoading(false);
    }
  };
  return {
    populateBalanceOfFundsDropdown,
    balanceOfFundsDropdown,
    populateDirectedToDropdown,
    directedToDropdown,
    calculateBalanceOfFundsPayableTo,
    currentBalanceDueOnClosing,
    originalBalanceDueOnClosing,
    calculateOriginalBalanceDueOnClosing,
    updateDirectedTos,
    processDirectionREFundPage,
    isLoading,
  };
};
export default useDirectionReFundsProcessor;
