/* eslint-disable array-callback-return */
import currency from "currency.js";
import Money from "../Money";

const processHSTOnTotalConsideration = (finalResult, currentFile) => {
  const newBuild = currentFile?.property?.newBuild;
  const propertyHasBeenRenovated = currentFile?.property?.propertyHasBeenRenovated;

  const Hst_provincialPortion = currentFile?.propertyPrice?.Hst_provincialPortion || 0;
  const Hst_federalPortion = currentFile?.propertyPrice?.Hst_federalPortion || 0;
  const hstRebate = currentFile?.propertyPrice?.hstRebate || 0;

  const additionalConsiderationAmountIncludingHST =
    currentFile?.propertyPrice?.additionalConsiderationAmountIncludingHST;
  const creditToPurchaserAmountIncludingHST =
    currentFile?.propertyPrice?.creditToPurchaserAmountIncludingHST;
  const propertyPriceFile = currentFile?.propertyPrice;
  const propertyPrice = propertyPriceFile?.propertyPrice;
  const agreedSalePrice = propertyPriceFile?.finalPriceIncludingTax;
  const hstSelection = propertyPriceFile?.taxesIncluded;
  const showHSTParticularsInStatementOfAdjustments =
    propertyPriceFile?.showHSTParticularsInStatementOfAdjustments;

  const hstRebateSection = currentFile?.propertyPrice?.addHstRebateToSOA;

  if (propertyPriceFile && currency(propertyPrice).value) {
    //***************************** Not a new home ******************************* *//
    if (
      newBuild !== "yes" &&
      propertyHasBeenRenovated !== "yes" &&
      showHSTParticularsInStatementOfAdjustments === "yes" &&
      hstSelection === "addHst"
    ) {
      let totalIncludingHSTValue = currency(agreedSalePrice);

      let netHSTPortion = currency(totalIncludingHSTValue).multiply(0.13).divide(1.13).value;
      let hstProvincialPortion = currency(totalIncludingHSTValue)
        .subtract(netHSTPortion)
        .multiply(0.08).value;

      let hstFederalPortion = currency(totalIncludingHSTValue)
        .subtract(netHSTPortion)
        .multiply(0.05).value;

      let totalHSTPortions = currency(hstProvincialPortion).add(hstFederalPortion).value;
      const hstOnTotalConsiderationRow = {
        adjustmentItem: "HST on Total Consideration",
        purchaserCredit: "",
        vendorCredit: "",
        credit: Money(totalHSTPortions),
        _classes: "font-weight-bold main-adjustment-text",
        automatic: "true",
      };
      finalResult.push(hstOnTotalConsiderationRow);
      const federalPortionRow = {
        adjustmentItem: "Federal Portion (5%): " + Money(Hst_federalPortion),
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
        handlebarLookup: "hstFederalPortion",
        handlebarValue: Money(hstFederalPortion),
      };
      finalResult.push(federalPortionRow);
      const provincialPortionRow = {
        adjustmentItem: "Provincial Portion (8%): " + Money(Hst_provincialPortion),
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(provincialPortionRow);
      const hstOnTotalConsiderationCreditVendor = {
        adjustmentItem: "Credit Vendor",
        purchaserCredit: "",
        vendorCredit: Money(totalHSTPortions, "none"),
        automatic: "true",
      };
      finalResult.push(hstOnTotalConsiderationCreditVendor);
    }
    let totalExtrasAndUpgradesValue = Number(0);
    let totalCreditToPurchaserAmountIncludingHST = Number(0);

    //* **************************** Property is a new home ******************************* *//
    if ((newBuild === "yes" && hstSelection !== "none") || propertyHasBeenRenovated === "yes") {
      if (currency(additionalConsiderationAmountIncludingHST).value > 0) {
        totalExtrasAndUpgradesValue = currency(additionalConsiderationAmountIncludingHST).value;
      }
      if (currency(creditToPurchaserAmountIncludingHST).value > 0) {
        totalCreditToPurchaserAmountIncludingHST = currency(
          creditToPurchaserAmountIncludingHST,
        ).value;
      }
      let totalRebateValue = Number(0);
      if (hstRebateSection === "yes") {
        totalRebateValue = currency(hstRebate).value;
      }
      const totalIncludingHSTValue = currency(agreedSalePrice)
        .add(totalExtrasAndUpgradesValue)
        .subtract(totalCreditToPurchaserAmountIncludingHST)
        .add(totalRebateValue).value;

      const netHSTPortion = currency(totalIncludingHSTValue).multiply(0.13).divide(1.13).value;
      const hstProvincialPortion = currency(totalIncludingHSTValue)
        .subtract(netHSTPortion)
        .multiply(0.08).value;

      const hstFederalPortion = currency(totalIncludingHSTValue)
        .subtract(netHSTPortion)
        .multiply(0.05).value;

      const totalHSTPortions = currency(hstProvincialPortion).add(hstFederalPortion).value;

      const hstOnTotalConsiderationRow = {
        adjustmentItem: "HST on Total Consideration",
        purchaserCredit: "",
        vendorCredit: "",
        credit: Money(totalHSTPortions),
        _classes: "font-weight-bold main-adjustment-text",
        automatic: "true",
      };
      finalResult.push(hstOnTotalConsiderationRow);
      const federalPortionRow = {
        adjustmentItem: `Federal Portion (5%): ${Money(Hst_federalPortion)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
        handlebarLookup: "hstFederalPortion",
        handlebarValue: Money(hstFederalPortion),
      };
      finalResult.push(federalPortionRow);
      const provincialPortionRow = {
        adjustmentItem: `Provincial Portion (8%): ${Money(Hst_provincialPortion)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(provincialPortionRow);
      const hstOnTotalConsiderationCreditVendor = {
        adjustmentItem: "Credit Vendor",
        purchaserCredit: "",
        vendorCredit: Money(totalHSTPortions, "none"),
        automatic: "true",
      };
      finalResult.push(hstOnTotalConsiderationCreditVendor);
    }
  }
};
export default processHSTOnTotalConsideration;
