const useErrorHandling = () => {
  const processError = (err, document) => {
    let message = "";
    if (err.properties && err.properties.errors instanceof Array) {
      const errorMessages = err.properties.errors
        .map(function (e) {
          return e.properties.explanation;
        })
        .join("\n");
      message = errorMessages;
    } else {
      message = err;
    }
    message = `Error generating document: ${document.documentName}`;
    return message;
  };
  return { processError };
};
export default useErrorHandling;
