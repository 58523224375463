import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { useFirestore } from "reactfire";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";
import currency from "currency.js";
import Money from "src/reusable/Money";
import useSaveStatementOfAccountToDataBase from "../SavingToDataBase/useSaveStatementOfAccount";

const useStatementOfAccount = () => {
  const { type } = useParams();
  const { savingRoutineCostsToDatabase } = useSaveStatementOfAccountToDataBase();
  const { savingStatementOfAccountSummary } = useSaveStatementOfAccountToDataBase();
  const { fetchAllFiles } = useSaveFileToDatabase();

  const db = useFirestore();

  const lawFirmID = useSelector((state) => state?.old?.lcsUser?.lawFirmID);
  const UpdateStatementOfAccount = async () => {
    await SaveStatementOfAccountRoutineEntriesToDatabase();
    const currentFile = await fetchAllFiles();
    await CalculateStatementOfAccountSummaryAndSaveToDatabase(currentFile);
  };
  const SaveStatementOfAccountRoutineEntriesToDatabase = async () => {
    const lawFirmDocRef = doc(db, `lawFirm/${lawFirmID}`);
    const data = (await getDoc(lawFirmDocRef)).data();
    const dataToSave = data?.StatementOfAccountRoutineEntries;
    const finalDataToSave = dataToSave?.filter((e) =>
      e.relatedToFileTypesArrayOfValues.includes(type),
    );
    await savingRoutineCostsToDatabase(finalDataToSave);
  };
  const CalculateStatementOfAccountSummaryAndSaveToDatabase = async (currentFile) => {
    let theHstAmount = 0;
    let totalAmountWithHst = 0;
    let feesHstSubtotal = 0;
    const fees = [];
    const disbursements = [];
    const disbursementsHST = [];
    let disbursementsHSTSubTotal = 0;
    let feesSubtotal = 0;
    let disbursementsWithHSTSubtotal = 0;
    let disbursementsWithoutHSTSubtotal = 0;
    if (currentFile?.StatementOfAccountFile?.CostsOnAccount?.TotalCostsOnAccount) {
      const statementOfAccountCost =
        currentFile?.StatementOfAccountFile?.CostsOnAccount?.TotalCostsOnAccount;
      statementOfAccountCost?.forEach((amountSum) => {
        if (amountSum?.hasBeenDeleted === true) return;
        const amountSumToUse =
          amountSum.hasBeenEdited === true ? amountSum.lawClerkEdit : amountSum;
        totalAmountWithHst = currency(totalAmountWithHst).add(amountSumToUse?.accountTotal).value;
        theHstAmount = currency(theHstAmount).add(amountSumToUse?.accountHSTAmount).value;
        if (amountSumToUse.type === "fee") {
          feesSubtotal = currency(feesSubtotal).add(amountSumToUse.accountCost).value;
          if (amountSumToUse.accountHST === "Yes") {
            feesHstSubtotal = currency(feesHstSubtotal).add(amountSumToUse.accountHSTAmount).value;
          }
          fees.push({
            name: amountSumToUse.accountDescription,
            fee: Money(amountSumToUse.accountCost),
          });
        } else if (amountSumToUse.type === "disbursement") {
          if (amountSumToUse.accountHST === "Yes") {
            disbursementsWithHSTSubtotal = currency(disbursementsWithHSTSubtotal).add(
              amountSumToUse.accountCost,
            ).value;
            disbursementsHSTSubTotal = currency(disbursementsHSTSubTotal).add(
              amountSumToUse.accountHSTAmount,
            ).value;
            disbursementsHST.push({
              name: amountSumToUse.accountDescription,
              fee: Money(amountSumToUse.accountCost),
            });
          } else if (amountSumToUse.accountHST === "No") {
            disbursementsWithoutHSTSubtotal = currency(disbursementsWithoutHSTSubtotal).add(
              amountSumToUse.accountCost,
            ).value;
            disbursements.push({
              name: amountSumToUse.accountDescription,
              fee: Money(amountSumToUse.accountCost),
            });
          }
        }
      });
      await savingStatementOfAccountSummary({
        feesHstSubtotal,
        totalAmountWithHst,
        precedenceData: {
          feesSubtotalHstMoney: feesHstSubtotal,
          fees,
          disbursementsHST,
          disbursementSubtotalHstMoney: disbursementsHSTSubTotal,
          disbursements,
          feesSubtotal,
          disbursementsWithHSTSubtotal,
          disbursementsWithoutHSTSubtotal,
        },
      });
    }
  };
  return { UpdateStatementOfAccount };
};

export default useStatementOfAccount;
