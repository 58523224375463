import { collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import useGetCollection from "src/reusable/firestoreHooks/useGetCollection";
import useGetCollectionManual from "src/reusable/firestoreHooks/useGetCollectionManual";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";
import { FormatDateUtil_date } from "src/util/FormatDateUtil";
import Handlebars from "src/util/HnhHandlebar";
import useDirectionReFundsProcessor from "src/views/pages/Sale/closing/DirectionREfunds/processor/useProcessDirectionREFunds";
import OrganizeTrustLedgerData from "src/views/pages/Shared/trustLedger/functions/OrganizeTrustLedgerData";
import ProcessTrustLedgerTableEntries from "src/views/pages/Shared/trustLedger/functions/ProcessTrustLedgerTableEntries";
import useTrustLedgerProcessor from "src/views/pages/Shared/trustLedger/processors/useTrustLedgerProcessor";
// import _ from "lodash";

/**
 * This function will contain the logic of how and where to get the template data from and serve it back as function that can be called
 */
const useGetTemplateData = () => {
  const dispatch = useDispatch();
  const db = useFirestore();
  const { type } = useParams();
  const currentFile = useSelector((state) => state?.old?.currentFile);
  const { processTrustLedger } = useTrustLedgerProcessor();
  const { processDirectionREFundPage } = useDirectionReFundsProcessor();
  const { fetchLawfirmFields, currentLawfirmData } = useSaveFileToDatabase();
  const [trustLedger, setTrustLedger] = useState([]);
  const [loadingTrustLedger, setLoadingTrustLedger] = useState(false);
  const { getCollection } = useGetCollectionManual();

  // FIXME: this doesn't work, investigate if it broke this component
  const users = useSelector((state) => state?.old?.lawFirm?.users);
  // Current time to timestamp for comparision
  const currentDateNotFormated = FormatDateUtil_date(new Date());
  const currentDateTimeStamp = Date.parse(currentDateNotFormated);
  const closingDate = useSelector((state) => state.old?.currentFile?.createFile?.closingDate);
  const closingDateCompare = closingDate?.seconds * 1000;
  let postClosingAddress;
  if (currentDateTimeStamp && closingDateCompare) {
    if (currentDateTimeStamp >= closingDateCompare) {
      postClosingAddress = true;
    } else {
      postClosingAddress = false;
    }
  }
  // this isnt going to work because useGetCollection relies on the file ID parameter from the URL
  const { collectionData: undertakings } = useGetCollection({
    nameOfCollection: "undertakings",
  });
  const { collectionData: billsHoldbacks } = useGetCollection({
    nameOfCollection: "billsHoldbacks",
  });
  const TeranetPins = currentFile?.TeranetAPIData;
  const commonJsonRef = collection(db, "config");
  const { data: commonJsonvalue } = useFirestoreCollectionData(commonJsonRef);

  useEffect(() => {
    callProcessors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callProcessors = async () => {
    setLoadingTrustLedger(true);
    await fetchLawfirmFields();
    await processTrustLedger();
    await processDirectionREFundPage();
    const trustLedgerData = await getCollection("trustLedger");
    const temp = ProcessTrustLedgerTableEntries(trustLedgerData);
    const orderedTrustLedgerData = OrganizeTrustLedgerData(temp, type);
    setLoadingTrustLedger(false);
    setTrustLedger(orderedTrustLedgerData);
  };

  const getTemplateData = async (docxTemplateFromFirestore, loadedFile) => {
    const primaryTeranetPins = [];
    if (TeranetPins) {
      const keys = Object.keys(TeranetPins);
      keys.forEach((pin) => {
        primaryTeranetPins.push(TeranetPins[pin][0]);
      });
    }
    const dataForHandlebar = {
      containsS200: docxTemplateFromFirestore.documentCode === "S200",
      postClosingAddress,
      users,
      trustLedger,
      undertakings,
      billsHoldbacks,
      // documentsBeingExecutedOnDate: documentsBeingExecutedOnDate,
      // cityWhereDocumentsExecuted: cityWhereDocumentsExecuted,
      primaryTeranetPins,
      // referenceToLCSLogo: referenceToLCSLogo,
      lawFirm: currentLawfirmData,
    };
    const commonJson = docxTemplateFromFirestore?.commonJSON
      ? docxTemplateFromFirestore?.commonJSON
      : commonJsonvalue[0]?.commonJSON
      ? commonJsonvalue[0]?.commonJSON
      : "{}";
    return await processJSONWithHandlebar({
      CommonJSONTemplateWithHandlebar: commonJson,
      SpecificJSONTemplateWithHandlebar: docxTemplateFromFirestore.specificJSON || "{}",
      templateMetaData: dataForHandlebar,
      loadedFile,
      multipleData: docxTemplateFromFirestore?.data,
    });
  };

  const processJSONWithHandlebar = async ({
    CommonJSONTemplateWithHandlebar,
    SpecificJSONTemplateWithHandlebar,
    templateMetaData,
    loadedFile,
    multipleData,
  }) => {
    try {
      const generateFnForCommonJson = Handlebars.compile(CommonJSONTemplateWithHandlebar, {
        noEscape: true,
      });
      const generateFnForSpecific = Handlebars.compile(SpecificJSONTemplateWithHandlebar, {
        noEscape: true,
      });
      // TODO: add better logic to create the template data for handlebar
      const mappingsCommonJSONAsString = generateFnForCommonJson({
        currentFile: loadedFile,
        data: multipleData,
        ...templateMetaData,
      });
      const mappingsSpecificJSONAsString = generateFnForSpecific({
        currentFile: loadedFile,
        data: multipleData,
        ...templateMetaData,
      });
      // eslint-disable-next-line
      let regex = /\,(?!\s*?[\{\[\"\'\w])/g;
      let mappingCommonJsonOnRegex = mappingsCommonJSONAsString.replace(regex, "");
      let mappingSpecificJsonOnRegex = mappingsSpecificJSONAsString.replace(regex, "");
      mappingCommonJsonOnRegex = mappingCommonJsonOnRegex.replace(/\t+/g, "");
      mappingSpecificJsonOnRegex = mappingSpecificJsonOnRegex.replace(/\t+/g, "");

      // var decodedCommonJson = mappingCommonJsonOnRegex
      //   .replace(/&quot;/g, '\\"')
      //   .replace(/&#x27;/g, "'");
      // var decodedCommonJsonInput = _.unescape(decodedCommonJson);

      // var decodedSpecificJson = mappingSpecificJsonOnRegex
      //   .replace(/&quot;/g, '\\"')
      //   .replace(/&#x27;/g, "'");
      // var decodedSpecificJsonInput = _.unescape(decodedSpecificJson);

      dispatch({
        type: "set",
        payload: {
          handlebaredCommonJSONValue: mappingCommonJsonOnRegex,
          handlebaredSpecificJSONValue: mappingSpecificJsonOnRegex,
        },
      });

      const commonJsonMappings = JSON.parse(mappingCommonJsonOnRegex);
      const specificJsonMappings = JSON.parse(mappingSpecificJsonOnRegex);
      return { ...commonJsonMappings, ...specificJsonMappings };
    } catch (err) {
      console.error(
        "Unexpected error occurred while executing function processJSONWithHandlebar\n",
        err,
      );
      throw err;
    }
  };
  return { getTemplateData, loadingTrustLedger };
};
export default useGetTemplateData;
