import { _ } from "core-js";

const PopulateListOfLastNamesAndFullNames = ({ arrayOfNames }) => {
  if (_.isEmpty(arrayOfNames))
    return {
      listOfFullNamesAsString: "",
      listOfLastNamesAsString: "",
      setOfUniqueAddresses: [],
      setOfUniquePostClosingAddresses: [],
    };
  const { listOfFullNames, listOfLastNames } =
    generateArrayOfNamesFromObject(arrayOfNames);
  const listOfFullNamesAsString = handleFullNames(listOfFullNames);
  const listOfLastNamesAsString = handleLastNames(listOfLastNames);
  const setOfUniqueAddresses = generateSetOfUniqueAddresses(arrayOfNames);
  let setOfUniquePostClosingAddresses = [];

  setOfUniquePostClosingAddresses =
    generateSetOfUniquePostClosingAddresses(arrayOfNames);

  return {
    listOfFullNamesAsString,
    listOfLastNamesAsString,
    setOfUniqueAddresses: setOfUniqueAddresses,
    setOfUniquePostClosingAddresses: setOfUniquePostClosingAddresses,
  };
};

const generateArrayOfNamesFromObject = (arrayOfNames) => {
  let listOfFullNames = [];
  let listOfLastNames = [];
  arrayOfNames?.forEach((entry) => {
    const processedFullNameEntry = handleCurrentFullName(entry);
    listOfFullNames =
      processedFullNameEntry?.length > 0
        ? listOfFullNames.concat(processedFullNameEntry)
        : listOfFullNames;
    const processedLastNameEntry = handleCurrentLastName(entry);
    listOfLastNames = listOfLastNames.concat(processedLastNameEntry);
  });
  return { listOfFullNames, listOfLastNames };
};
const handleCurrentFullName = (entry) => {
  let fullName = "";
  if (
    entry?.clientType === "corporation" ||
    entry?.clientType === "" ||
    !entry?.clientType
  ) {
    fullName = entry?.name;
  } else if (entry?.clientType === "estate") {
    fullName = `Estate of ${entry?.firstName} ${entry?.lastName}`;
  } else if (
    entry?.clientType === "person" ||
    entry?.clientType === "personPowerOfAttorney"
  ) {
    if (entry?.firstName) fullName += entry?.firstName;
    if (entry?.middleName) fullName += ` ${entry?.middleName}`;
    if (entry?.lastName) fullName += ` ${entry?.lastName}`;
  } else {
    if (entry?.firstName) fullName += entry?.firstName;
    if (entry?.middleName) fullName += ` ${entry?.middleName}`;
    if (entry?.lastName) fullName += ` ${entry?.lastName}`;
  }
  return fullName;
};
const handleCurrentLastName = (entry) => {
  let lastName = "";
  if (
    entry?.clientType === "corporation" ||
    entry?.clientType === "" ||
    !entry?.clientType
  ) {
    lastName = entry?.name || "";
  } else if (entry?.clientType === "estate") {
    lastName = `Estate of ${entry?.firstName} ${entry?.lastName}`;
  } else {
    lastName = entry?.lastName || "";
  }

  return lastName;
};

const handleFullNames = (listOfFullNames) => {
  let listOfFullNamesAsString = "";
  Array.isArray(listOfFullNames) &&
    listOfFullNames?.forEach((name) => {
      listOfFullNamesAsString += `${name}, `;
    });
  listOfFullNamesAsString = listOfFullNamesAsString.slice(
    0,
    listOfFullNamesAsString.lastIndexOf(",")
  );
  listOfFullNamesAsString = listOfFullNamesAsString.trim();
  listOfFullNamesAsString = replaceLastCommaWithAnd(listOfFullNamesAsString);
  return listOfFullNamesAsString;
};

const handleLastNames = (listOfLastNames) => {
  //Making it into a set and back to an array removes all duplicates
  listOfLastNames = [...new Set(listOfLastNames)];
  let listOfLastNamesAsString = "";
  if (listOfLastNames?.length === 1) {
    listOfLastNamesAsString = `${listOfLastNames[0]}`;
  } else if (listOfLastNames?.length === 2) {
    listOfLastNamesAsString = `${listOfLastNames[0]} and ${listOfLastNames[1]}`;
  } else if (listOfLastNames?.length > 2) {
    Array.isArray(listOfLastNames) &&
      listOfLastNames?.forEach((name) => {
        listOfLastNamesAsString += `${name}, `;
      });
    listOfLastNamesAsString = listOfLastNamesAsString.slice(
      0,
      listOfLastNamesAsString.lastIndexOf(",")
    );
    listOfLastNamesAsString = replaceLastCommaWithAnd(listOfLastNamesAsString);
  }
  return listOfLastNamesAsString;
};

const replaceLastCommaWithAnd = (string) => {
  const lastIndex = string.lastIndexOf(",");
  const replacement = " and";
  let replaced;
  if (lastIndex !== -1) {
    replaced =
      string.substring(0, lastIndex) +
      replacement +
      string.substring(lastIndex + 1);
    return replaced;
  } else return string;
};

const generateSetOfUniqueAddresses = (array) => {
  if (!Array.isArray(array)) return [];
  let personEntities = array?.filter((e) => {
    return e.clientType === "person";
  });
  let arrayOfAllAddressesForPersons = personEntities?.map((e) => {
    return e?.addressInfo?.FullAddress;
  });
  let setOfUniqueAddresses = [...new Set(arrayOfAllAddressesForPersons)];
  let setOfUniqueAddressesWithNames = [];
  setOfUniqueAddresses?.forEach((uniqueAddress) => {
    let listOfPeopleWithTheCurrentAddress = personEntities?.filter(
      (person) =>
        person?.addressInfo?.FullAddress?.toUpperCase() ===
        uniqueAddress?.toUpperCase()
    );
    let listOfNamesOnCurrentAddress = listOfPeopleWithTheCurrentAddress?.map(
      (e) => {
        return e?.nameOnFile;
      }
    );
    let uniqueAddressWithNamesObject = {
      stringOfFullNamesWithAddress:
        handleFullNames(listOfNamesOnCurrentAddress) || "",
      addressInfo: listOfPeopleWithTheCurrentAddress?.[0]?.addressInfo || {},
    };
    setOfUniqueAddressesWithNames = setOfUniqueAddressesWithNames.concat(
      uniqueAddressWithNamesObject
    );
  });
  return setOfUniqueAddressesWithNames;
};
const generateSetOfUniquePostClosingAddresses = (array) => {
  if (!Array.isArray(array)) return [];
  let personEntities = array?.filter((e) => {
    return e.clientType === "person";
  });
  let arrayOfAllAddressesForPersons = personEntities?.map((e) => {
    return e?.postClosingAddressInfo?.FullAddress;
  });
  let setOfUniqueAddresses = [...new Set(arrayOfAllAddressesForPersons)];
  let setOfUniqueAddressesWithNames = [];
  setOfUniqueAddresses?.forEach((uniqueAddress) => {
    let listOfPeopleWithTheCurrentAddress = personEntities?.filter(
      (person) =>
        person?.postClosingAddressInfo?.FullAddress?.toUpperCase() ===
        uniqueAddress?.toUpperCase()
    );
    let listOfNamesOnCurrentAddress = listOfPeopleWithTheCurrentAddress?.map(
      (e) => {
        return e?.nameOnFile;
      }
    );
    let uniqueAddressWithNamesObject = {
      stringOfFullNamesWithAddress:
        handleFullNames(listOfNamesOnCurrentAddress) || "",
      postClosingAddressInfo:
        listOfPeopleWithTheCurrentAddress?.[0]?.postClosingAddressInfo || {},
    };
    setOfUniqueAddressesWithNames = setOfUniqueAddressesWithNames.concat(
      uniqueAddressWithNamesObject
    );
  });
  return setOfUniqueAddressesWithNames;
};

export default PopulateListOfLastNamesAndFullNames;
