import currency from "currency.js";

const ProcessFireInsuranceBrokerFee = async ({ dataToProcess, processedData }) => {
  dataToProcess = dataToProcess?.fireInsurance;
  const fireInsuranceBrokerage = dataToProcess?.FireInsuranceBrokerageComponent?.[0];
  if (
    dataToProcess?.brokerageUsed === "yes" &&
    fireInsuranceBrokerage &&
    currency(fireInsuranceBrokerage?.brokerFee).value > 0
  ) {
    return processedData.concat({
      trustLedgerItem: `Paid to fire insurance broker (${
        fireInsuranceBrokerage?.brokerage?.name ? fireInsuranceBrokerage?.brokerage?.name : ""
      })`,
      trustLedgerExpenditureAmount: currency(fireInsuranceBrokerage?.brokerFee).value,
      trustLedgerReceiptAmount: "",
      type: "expenditure",
      processorReference: "fireInsuranceBrokerage",
      typeOfGeneratedEntry: "automaticEntry",
      uniqueID: fireInsuranceBrokerage.uniqueID,
    });
  }
  return processedData;
};

export default ProcessFireInsuranceBrokerFee;
