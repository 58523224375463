import currency from "currency.js";
import _ from "lodash";
import HnHHandleDate from "../HnHHandleDate";
import Money from "../Money";

const processCommonExpenses = (finalResult, currentFile) => {
  // Data from backend table
  const propertyCommonExpensesFile = currentFile?.condoSaleCommonExpenses;
  if (propertyCommonExpensesFile) {
    if (
      currency(propertyCommonExpensesFile?.monthlyCommonExpenses).value > 0 &&
      !_.isEmpty(propertyCommonExpensesFile?.amountPaidForCurrentMonth)
    ) {
      const monthlyCommonExpenses = currency(
        propertyCommonExpensesFile?.monthlyCommonExpenses,
      ).value;
      const currentMonthPaidFor = propertyCommonExpensesFile?.amountPaidForCurrentMonth;
      const adjustFor = propertyCommonExpensesFile?.adjustFor;

      /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Data for the date work
      let daysBetweenFirstOfMonthToClosingDay;
      let daysInClosingDateMonth;
      const adjustmentDateTimeStamp = currentFile?.fileOpen?.adjustOnSpecifiedDate;
      const whichDateToChoose = currentFile?.fileOpen?.adjustOn;
      let year;
      let month;
      let monthName;

      if (whichDateToChoose === "specify") {
        if (adjustmentDateTimeStamp) {
          let adjustmentDate = adjustmentDateTimeStamp;
          if (adjustmentDate.seconds) {
            adjustmentDate = HnHHandleDate(adjustmentDate);
          }
          const adjustmentDateDay = adjustmentDate.getDate();
          daysBetweenFirstOfMonthToClosingDay = adjustmentDateDay - 1;

          month = adjustmentDate.getMonth() + 1;
          year = adjustmentDate.getFullYear();
          monthName = adjustmentDate.toLocaleString("default", {
            month: "long",
          });

          daysInClosingDateMonth = new Date(year, month, 0).getDate();
        }
      } else {
        const closingDateTimeStamp = currentFile?.createFile?.closingDate;
        const closingDate = HnHHandleDate(closingDateTimeStamp);
        const closingDateDay = closingDate.getDate();
        daysBetweenFirstOfMonthToClosingDay = closingDateDay - 1;
        month = closingDate.getMonth() + 1;
        monthName = closingDate.toLocaleString("default", { month: "long" });
        year = closingDate.getFullYear();
        daysInClosingDateMonth = new Date(year, month, 0).getDate();
      }
      /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Data for form work
      let monthlyWithTax = currency(monthlyCommonExpenses).value;
      let shareForXDays = 0;
      /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const commonExpensesRow = {
        adjustmentItem: "Common Expenses",
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
        _classes: "font-weight-bold main-adjustment-text",
      };
      finalResult.push(commonExpensesRow);

      const monthlyCommonExpensesRow = {
        adjustmentItem: `Monthly common expenses: ${Money(monthlyCommonExpenses)}`,
        purchaserCredit: "",
        vendorCredit: "",
        automatic: "true",
      };
      finalResult.push(monthlyCommonExpensesRow);
      if (adjustFor === "none") monthlyWithTax = monthlyCommonExpenses;
      if (adjustFor === "hst") {
        monthlyWithTax *= 1.13;
        const hstRow = {
          adjustmentItem: `HST: ${Money(currency(monthlyCommonExpenses).multiply(0.13))}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(hstRow);
      }
      if (adjustFor === "gst") {
        monthlyWithTax *= 1.05;
        const gstRow = {
          adjustmentItem: `GST: ${Money(currency(monthlyCommonExpenses).multiply(0.05))}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(gstRow);
      }

      if (currentMonthPaidFor === "yes") {
        const paidByVendorRow = {
          adjustmentItem: `Paid by vendor in the month of closing: ${Money(monthlyWithTax)}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(paidByVendorRow);
        shareForXDays =
          (currency(monthlyWithTax).value / daysInClosingDateMonth) *
          daysBetweenFirstOfMonthToClosingDay;
        const vendorsShareForXDaysRow = {
          adjustmentItem: `Vendor's share for ${daysBetweenFirstOfMonthToClosingDay} days: ${Money(
            shareForXDays,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(vendorsShareForXDaysRow);
        const creditVendor = monthlyWithTax - shareForXDays;
        const creditVendorRow = {
          adjustmentItem: "Credit Vendor",
          credit: Money(creditVendor),
          monthlyCommonExpenses: Money(monthlyCommonExpenses),
          hst: Money(currency(monthlyCommonExpenses).multiply(0.13)),
          monthOfDate: monthName,
          yearOfDate: year,
          daysToClosing: daysBetweenFirstOfMonthToClosingDay,
          shareForXDays,

          handlebarLookup: "monthlyCommonExpenses",
          monthlyCommonExpensesData: {
            adjustFor:
              adjustFor === "hst"
                ? Money(currency(monthlyCommonExpenses).multiply(0.13))
                : adjustFor === "gst"
                ? Money(currency(monthlyCommonExpenses).multiply(0.05))
                : "",
            shareForXDays: Money(shareForXDays),
            daysBetweenFirstOfMonthToClosingDay,
            currentMonthPaidFor,
          },
          purchaserCredit: "",
          vendorCredit: Money(creditVendor, "none"),
          automatic: "true",
        };
        finalResult.push(creditVendorRow);
      } else {
        const paidByPurchaserRow = {
          adjustmentItem: `To be paid by the purchaser in the month of closing: ${Money(
            monthlyWithTax,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(paidByPurchaserRow);
        shareForXDays =
          (currency(monthlyWithTax).value / daysInClosingDateMonth) *
          daysBetweenFirstOfMonthToClosingDay;
        const purchasersShareForXDaysRow = {
          adjustmentItem: `Purchaser's share for ${daysBetweenFirstOfMonthToClosingDay} days: ${Money(
            shareForXDays,
          )}`,
          purchaserCredit: "",
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(purchasersShareForXDaysRow);
        const creditPurchaser = shareForXDays;
        const creditPurchaserRow = {
          adjustmentItem: "Credit Purchaser",
          adjustmentType: "common expenses",
          credit: Money(creditPurchaser),
          monthlyCommonExpenses: Money(monthlyCommonExpenses),
          hst: Money(currency(monthlyCommonExpenses).multiply(0.13)),
          monthOfDate: monthName,
          yearOfDate: year,
          daysToClosing: daysBetweenFirstOfMonthToClosingDay,
          shareForXDays,
          handlebarLookup: "monthlyCommonExpenses",
          monthlyCommonExpensesData: {
            adjustFor:
              adjustFor === "hst"
                ? Money(currency(monthlyCommonExpenses).multiply(0.13))
                : adjustFor === "gst"
                ? Money(currency(monthlyCommonExpenses).multiply(0.05))
                : "",
            shareForXDays: Money(shareForXDays),
            daysBetweenFirstOfMonthToClosingDay,
            currentMonthPaidFor,
          },
          purchaserCredit: Money(creditPurchaser, "none"),
          vendorCredit: "",
          automatic: "true",
        };
        finalResult.push(creditPurchaserRow);
      }
    }
  }
};
export default processCommonExpenses;
