import currency from "currency.js";
import _ from "lodash";

const ProcessPropertyTax = async ({ dataToProcess, processedData }) => {
  dataToProcess = dataToProcess?.propertyTax;
  if (Array.isArray(dataToProcess?.taxes) && !_.isEmpty(dataToProcess?.taxes)) {
    let totalAmountToPayInTrustPropertyForm = 0;
    let renderTaxEntry = false;
    dataToProcess?.taxes?.forEach((tax) => {
      const basedOnWhichTaxBillPropertyForm = tax?.basedOnWhichTaxBill;
      const amountToPayInTrustPropertyForm = currency(tax?.amountToPayInTrust).value;
      const payTaxesOutOfOurTrustAccountPropertyForm =
        tax?.payTaxesOutOfOurTrustAccountTaxBillFinal;
      if (
        basedOnWhichTaxBillPropertyForm === "final" &&
        payTaxesOutOfOurTrustAccountPropertyForm === "yes" &&
        currency(amountToPayInTrustPropertyForm).value > 0
      ) {
        totalAmountToPayInTrustPropertyForm = currency(totalAmountToPayInTrustPropertyForm).add(
          amountToPayInTrustPropertyForm || 0,
        ).value;
        renderTaxEntry = true;
      }
    });
    if (renderTaxEntry) {
      return processedData.concat({
        trustLedgerItem: "Paid to local tax department",
        trustLedgerExpenditureAmount: totalAmountToPayInTrustPropertyForm,
        trustLedgerReceiptAmount: "",
        type: "expenditure",
        processorReference: "propertyTax",
        typeOfGeneratedEntry: "automaticEntry",
      });
    }
    return processedData;
  }
  return processedData;
};

export default ProcessPropertyTax;
