import { collection } from "firebase/firestore";
import expressions from "angular-expressions";
import currency from "currency.js";
import Docxtemplater from "docxtemplater";
import SubSectionModule from "docxtemplater-subsection-module";
import SubTemplateModule from "docxtemplater-subtemplate-module";
import { getDownloadURL, ref } from "firebase/storage";
import { assign } from "lodash";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreCollectionData, useStorage } from "reactfire";
import HnHHandleDate from "src/reusable/HnHHandleDate";
import { useParams } from "react-router-dom";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";
import { changeFontInDocx } from "src/reusable/changeFontInDocx";

const useGenerateDocxTemplate = () => {
  const db = useFirestore();
  const storage = useStorage();
  const { type } = useParams();

  const lawFirmID = useSelector((state) => state?.old?.lcsUser.lawFirmID);
  const currentFile = useSelector((state) => state?.old?.currentFile);

  const subTemplatesCollectionRef = collection(db, "subTemplates");
  const { data: subTemplatesCollectionData } =
    useFirestoreCollectionData(subTemplatesCollectionRef);
  const { fetchLawfirmFields } = useSaveFileToDatabase();

  const loadSubTemplates = async () => {
    const tagFiles = {};
    if (!subTemplatesCollectionData || subTemplatesCollectionData.length === 0) {
      return tagFiles;
    }
    await Promise.allSettled(
      subTemplatesCollectionData.map(async (item) => {
        try {
          const url = await getDownloadURL(ref(storage, item.fullPath));
          if (url) {
            let loadedZipFile = null;
            let loadedFile = null;

            loadedZipFile = await getDocxZip(url).catch((e) => {
              console.error("Error while fetching docx file from firebase storage", e);
              return null;
            });

            if (loadedZipFile) {
              // loadedFile = new Docxtemplater().loadZip(loadedZipFile);
              loadedFile = new Docxtemplater(loadedZipFile, {
                paragraphLoop: true,
                linebreaks: true,
                parser: angularParser,
              });
            }
            if (loadedFile) {
              tagFiles[item.documentTag] = loadedFile;
            }
          }
        } catch (error) {
          console.error("required sub template is not present on firebase");
        }
      }),
    );
    return tagFiles;
  };
  const getTaggedFiles = async (templateData) => {
    const taggedFiles = await loadSubTemplates();
    if (Object.keys(taggedFiles).length > 0) {
      Object.keys(taggedFiles).map((item, key) => {
        taggedFiles[item] = taggedFiles[item].render(templateData);
        return item;
      });
    }
    return taggedFiles;
  };
  const getDocxZip = (url) => {
    return new Promise((resolve) => {
      PizZipUtils.getBinaryContent(url, (err, content) => {
        if (err) throw err;
        resolve(new PizZip(content));
      });
    });
  };

  expressions.filters.lower = function (input) {
    // This condition should be used to make sure that if your input is
    // undefined, your output will be undefined as well and will not
    // throw an error
    if (!input) return input;
    return input.toLowerCase();
  };
  expressions.filters.format = function (input, type) {
    // This is the custom filter tag for docxtemplater
    if (!input) return input;
    if (type === "currency") {
      return currency(input, {
        separator: ",",
        symbol: "",
      }).format();
    }
    if (type === "date") {
      // return input;
      return HnHHandleDate(input, "string");
    }
    return input;
  };
  const angularParser = (tag) => {
    tag = tag.replace(/^\.$/, "this").replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"');
    const expr = expressions.compile(tag);
    return {
      get(scope, context) {
        let obj = {};
        const { scopeList } = context;
        const { num } = context;
        for (let i = 0, len = num + 1; i < len; i++) {
          obj = assign(obj, scopeList[i]);
        }
        return expr(scope, obj);
      },
    };
  };

  const generateSingleFile = async (
    docxTemplateFirestore,
    templateData,
    isCombined,
    font = "Times New Roman",
  ) => {
    // Add your logic of adding letterhead here.
    let currentSolicitor = {};
    if (
      type === "Purchase" &&
      currentFile?.purchaser?.solicitor?.firstName &&
      currentFile?.purchaser?.solicitor?.lastName
    ) {
      currentSolicitor = currentFile?.purchaser?.solicitor;
    } else if (
      type === "Sale" &&
      currentFile?.vendor?.solicitor?.firstName &&
      currentFile?.vendor?.solicitor?.lastName
    ) {
      currentSolicitor = currentFile?.vendor?.solicitor;
    } else if (
      type === "Mortgage" &&
      currentFile?.mortgagor?.solicitor?.firstName &&
      currentFile?.mortgagor?.solicitor?.lastName &&
      (currentFile?.fileOpen?.mortgageActingFor === "mortgagor" ||
        (currentFile?.fileOpen?.mortgageActingFor === "both" &&
          (currentFile?.fileOpen?.filesPrimaryClient === "mortgagor" ||
            currentFile?.fileOpen?.filesPrimaryClient === "")))
    ) {
      currentSolicitor = currentFile?.mortgagor?.solicitor;
    } else if (
      type === "Mortgage" &&
      currentFile?.mortgagor?.solicitor?.firstName &&
      currentFile?.mortgagor?.solicitor?.lastName &&
      (currentFile?.fileOpen?.mortgageActingFor === "mortgagee" ||
        (currentFile?.fileOpen?.mortgageActingFor === "both" &&
          currentFile?.fileOpen?.filesPrimaryClient === "mortgagee"))
    ) {
      currentSolicitor.id = currentFile?.mortgageesSolicitor?.otherSolicitorUserId;
    }

    let lawFirmFields = await fetchLawfirmFields();
    const letterHeadDocxFileRef =
      lawFirmFields?.freelanceClerk === "yes"
        ? ref(storage, `lawFirms/${lawFirmID}/users/${currentSolicitor?.id}/letterHead.docx`)
        : ref(storage, `lawFirms/${lawFirmID}/letterHead.docx`);
    const fileType = type?.toLowerCase();
    const reLineDocxFileRef = ref(
      storage,
      `lawFirms/${lawFirmID}/templateLines/reLine/${fileType}-reLine.docx`,
    );
    const dearLineDocxFileRef = ref(
      storage,
      `lawFirms/${lawFirmID}/templateLines/dearLine/${fileType}-dearLine.docx`,
    );
    const trulyLineDocxFileRef = ref(
      storage,
      `lawFirms/${lawFirmID}/templateLines/trulyLine/${fileType}-trulyLine.docx`,
    );

    const letterHeadFileURL = await getDownloadURL(letterHeadDocxFileRef).catch((err) => {
      console.log("error while getting URL for letter head", err);
      return null;
    });
    const reLineFileURL = await getDownloadURL(reLineDocxFileRef).catch((err) => {
      console.log("error while getting URL for letter head", err);
      return null;
    });
    const dearLineFileURL = await getDownloadURL(dearLineDocxFileRef).catch((err) => {
      console.log("error while getting URL for letter head", err);
      return null;
    });
    const trulyLineFileURL = await getDownloadURL(trulyLineDocxFileRef).catch((err) => {
      console.log("error while getting URL for letter head", err);
      return null;
    });
    const docRef = ref(storage, docxTemplateFirestore.fullPath);
    const docxTemplateFileURL = docxTemplateFirestore.fileToUpload
      ? URL.createObjectURL(docxTemplateFirestore.fileToUpload)
      : await getDownloadURL(docRef).catch((err) => {
          console.log("error while getting template URL", err);
          return null;
        });
    if (!docxTemplateFileURL) return null;
    try {
      const docxTemplateFile = await getDocxZip(docxTemplateFileURL);
      let letterHeadZip = null;
      let letterHeadDocxFile = null;

      let reLineZip = null;
      let reLineDocxFile = null;

      let dearLineZip = null;
      let dearLineDocxFile = null;

      let trulyLineZip = null;
      let trulyLineDocxFile = null;
      if (letterHeadFileURL) {
        letterHeadZip = await getDocxZip(letterHeadFileURL).catch((e) => {
          console.error("Error while fetching docx file from firebase storage", e);
          return null;
        });
      }
      if (reLineFileURL) {
        reLineZip = await getDocxZip(reLineFileURL).catch((e) => {
          console.error("Error while fetching docx file from firebase storage", e);
          return null;
        });
      }
      if (dearLineFileURL) {
        dearLineZip = await getDocxZip(dearLineFileURL).catch((e) => {
          console.error("Error while fetching docx file from firebase storage", e);
          return null;
        });
      }
      if (trulyLineFileURL) {
        trulyLineZip = await getDocxZip(trulyLineFileURL).catch((e) => {
          console.error("Error while fetching docx file from firebase storage", e);
          return null;
        });
      }
      // define your filter functions here, for example
      // to be able to write {clientname | lower}
      // TODO: add subtemplates for letterhead
      if (letterHeadZip) {
        // letterHeadDocxFile = new Docxtemplater().loadZip(letterHeadZip);
        letterHeadDocxFile = new Docxtemplater(letterHeadZip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: angularParser,
        });
        letterHeadDocxFile?.render(templateData);
      }
      if (reLineZip) {
        reLineDocxFile = new Docxtemplater(reLineZip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: angularParser,
        });
        reLineDocxFile?.render(templateData);
      }
      if (dearLineZip) {
        dearLineDocxFile = new Docxtemplater(dearLineZip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: angularParser,
        });
        dearLineDocxFile?.render(templateData);
      }
      if (trulyLineZip) {
        trulyLineDocxFile = new Docxtemplater(trulyLineZip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: angularParser,
        });
        trulyLineDocxFile?.render(templateData);
      }
      const doc = new Docxtemplater(docxTemplateFile, {
        paragraphLoop: true,
        linebreaks: true,
        parser: angularParser,
        modules: [new SubTemplateModule({}), new SubSectionModule()],
        fontFamily: font,
      });
      const taggedFiles = await getTaggedFiles(templateData);

      // keep this console for testing
      // doc.setData(templateData);
      // doc.render();
      const docxTemplaterData = {
        ...templateData,
        ...(letterHeadDocxFile && { letterHead: letterHeadDocxFile }),
        ...(Object.keys(taggedFiles).length > 0 && { ...taggedFiles }),
        ...(reLineDocxFile && { reLine: reLineDocxFile }),
        ...(dearLineDocxFile && { dearLine: dearLineDocxFile }),
        ...(trulyLineDocxFile && { trulyLine: trulyLineDocxFile }),
        pageBreak: '<w:p><w:br w:type="page" /></w:p>',
      };

      doc.render(docxTemplaterData);

      const zip = doc.getZip();

      const { documentStyle, documentDoc } = changeFontInDocx(zip, font);

      zip.file("word/styles.xml", documentStyle);
      zip.file("word/document.xml", documentDoc);
      const generateDocxFile = doc.getZip().generate({
        type: "blob",
        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); // Output the document using Data-URI

      return isCombined ? doc : generateDocxFile;
    } catch (err) {
      throw err;
    }
  };

  return {
    generateSingleFile,
    loadSubTemplates,
    getTaggedFiles,
    angularParser,
  };
};

export default useGenerateDocxTemplate;
