import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import CIcon from "@coreui/icons-react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CTooltip,
} from "@coreui/react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useSelector } from "react-redux";
import EditTemplateSettingsModal from "src/new-folder-structure/components/organisms/template-settings/ts-Edit-Modal.component.jsx/ts-Edit-Modal.component";
import AddEditSpecificTemplateModal from "src/old-folder-structure/organisms/template-settings/ts-specific-add-edit-modal/ts-specific-add-edit-modal.component";
import ModalPortal from "src/util/ModalPortal";
import { trpc } from "../../../../shared/utils/trpc";

function TemplateSettings() {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setEditShowModal] = useState(false);
  const [ID, setID] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [templateData, setTemplateData] = useState();

  const lawFirmID = useSelector((state) => state?.old?.lcsUser.lawFirmID);

  const {
    data: lawfirmTemplates,
    isLoading: isLoadingDocuments,
    refetch,
    isRefetching,
  } = trpc.lawFirmTemplate.query.useQuery({
    templateType: "DOCX",
    lawFirmId: lawFirmID,
  });
  const deleteTemplate = trpc.lawFirmTemplate.delete.useMutation();
  const deleteRow = async (fileData) => {
    await deleteTemplate.mutateAsync({
      id: fileData.id,
      fullPath: fileData.fullPath,
      templateOrigin: fileData.templateOrigin || fileData.fileType,
      lawFirmId: lawFirmID,
    });
    await refetch();
  };

  return (
    <>
      <CCard>
        <CCardHeader>
          <h3>Template Settings</h3>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol>
              <CButton
                onClick={() => {
                  setTemplateData();
                  setShowModal(!showModal);
                }}
                className="btn btn-primary btn-lg btn-block"
              >
                Add
              </CButton>
            </CCol>
          </CRow>
          <CDataTable
            border
            size="sm"
            // items={loadedDocuments}
            items={lawfirmTemplates}
            // loading={loading}
            loading={isLoadingDocuments || isRefetching}
            sorter
            tableFilter={{ label: "Search", placeholder: "Search criteria" }}
            columnFilter
            fields={[
              {
                key: "documentName",
                label: "Document Name",
              },
              {
                key: "relatedToFileTypesForTable",
                label: "File type",
              },
              {
                key: "letterOrNonLetterForTable",
                label: "Document Type",
              },
              {
                key: "category",
                label: "Category",
              },
              {
                key: "templateOrigin",
                label: "Template Origin",
              },
              {
                key: "show_details",
                label: "Action",
                _style: { width: "1%" },
                sorter: false,
                filter: false,
              },
            ]}
            hover
            striped
            itemsPerPage={15}
            clickableRows
            onRowClick={(item, rowNumber, columnPressed) => {
              if (columnPressed === "show_details" || columnPressed === "details") {
              }
            }}
            pagination={{
              align: "center",
            }}
            scopedSlots={{
              show_details: (item) => {
                return (
                  <td>
                    <CButtonGroup>
                      <CTooltip content={`Edit`} placement="top">
                        <CButton
                          size="sm"
                          color="primary"
                          onClick={() => {
                            if (item?.fileType === "My Law Firm") {
                              setTemplateData({
                                ...item,
                                category:
                                  typeof item.category !== "string"
                                    ? item.category.map((c) => ({
                                        label: c,
                                        value: c,
                                      }))
                                    : [
                                        {
                                          label: item.category,
                                          value: item.category,
                                        },
                                      ],
                                relatedToFileTypes: item?.relatedToFileTypes,
                              });
                              setShowModal(!showModal);
                            } else {
                              setID(item.id);
                              setSelectedItem({
                                ...item,
                              });
                              setEditShowModal(true);
                            }
                          }}
                        >
                          <CIcon name="cil-pencil" />
                        </CButton>
                      </CTooltip>
                      {(item?.fileType === "My Law Firm" || item?.isTemplateEdited) && (
                        <CTooltip
                          content={
                            item?.fileType === "My Law Firm"
                              ? "Delete Document"
                              : `Restore to the original`
                          }
                          placement="top"
                        >
                          <CButton
                            onClick={() =>
                              confirmAlert({
                                title:
                                  item?.fileType === "My Law Firm"
                                    ? "Confirm delete"
                                    : "Confirm restore",
                                message:
                                  item?.fileType === "My Law Firm"
                                    ? "Are you sure you would like to delete this document?"
                                    : "You are about to restore your modified template back to the LCS original. Your modified version will be lost forever. Do you wish to proceed?",
                                buttons: [
                                  {
                                    label:
                                      item?.fileType === "My Law Firm"
                                        ? "Delete document"
                                        : "Restore document",
                                    onClick: async () => {
                                      await deleteRow(item);
                                    },
                                  },
                                  {
                                    label: "Cancel",
                                  },
                                ],
                              })
                            }
                            size="sm"
                            color="danger"
                          >
                            <CIcon
                              name={item?.fileType === "My Law Firm" ? "cil-trash" : "cil-reload"}
                              className="text-white"
                            />
                          </CButton>
                        </CTooltip>
                      )}
                    </CButtonGroup>
                  </td>
                );
              },
              relatedToFileTypesForTable: (item) => {
                return (
                  <td>
                    {Array.isArray(item.relatedToFileTypes)
                      ? item.relatedToFileTypes.map((el) => el.value).join(", ")
                      : item.relatedToFileTypes.value}
                  </td>
                );
              },
              category: (item) => {
                return <td>{item?.category?.join(", ")}</td>;
              },
              letterOrNonLetterForTable: (item) => {
                return <td>{item.filters?.letterOrNonLetter ?? ""}</td>;
              },
            }}
          />
        </CCardBody>
      </CCard>
      {showModal ? (
        <ModalPortal>
          <AddEditSpecificTemplateModal
            showModal={showModal}
            setShowModal={setShowModal}
            templateData={templateData}
            refetch={refetch}
          />
        </ModalPortal>
      ) : (
        ""
      )}
      {showEditModal ? (
        <ModalPortal>
          <EditTemplateSettingsModal
            id={ID}
            selectedItem={selectedItem}
            showModal={showEditModal}
            setShowModal={setEditShowModal}
            refetch={refetch}
          />
        </ModalPortal>
      ) : (
        ""
      )}
    </>
  );
}

export default TemplateSettings;
