import React from "react";
import TemplateSettings from "./old-folder-structure/pages/settings/template-settings/template-settings.page";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const StewartAuth = React.lazy(() =>
  import("./old-folder-structure/pages/ti-stewart-auth-postback-url/ti-stewart-auth-postback-url"),
);
const Contacts = React.lazy(() => import("./views/pages/contacts/Contacts-Crud"));

const ManageUsers = React.lazy(() =>
  import("src/old-folder-structure/pages/settings/manage-users/manage-users"),
);
const Resources = React.lazy(() =>
  import("src/old-folder-structure/pages/title-insurance-stewart/title-insurance-resources.page"),
);
const CustomResponsesRequisition = React.lazy(() =>
  import(
    "src/old-folder-structure/pages/settings/custom-responses to-requisition/custom-requisitions"
  ),
);
const Invoices = React.lazy(() => import("./views/dashboard/settings/invoices/invoices-page"));
const LawFirmSettings = React.lazy(() =>
  import("./views/dashboard/settings/LawFirmSettings/LawFirmSettings-page"),
);
const statementSettingsPage = React.lazy(() =>
  import("./views/dashboard/settings/StatementSettings/components/statementSettingsPage"),
);
const IntegrationSettings = React.lazy(() =>
  import(
    "./old-folder-structure/organisms/title-insurance/ti-stewart-auth-modal/ti-stewart-auth-modal"
  ),
);

const CreateAFile = React.lazy(() =>
  import("./views/pages/filePage/createNewFile/createNewFile-page"),
);
const FilePage = React.lazy(() => import("./views/pages/filePage/fileOpen/fileOpen"));
const Memos = React.lazy(() => import("./views/pages/filePage/memos/memos-page"));

const Vendors = React.lazy(() => import("./views/pages/Shared/vendor/vendor-page"));
const Purchasers = React.lazy(() => import("./views/pages/Shared/purchaser/purchaser-page"));
const Property = React.lazy(() => import("./views/pages/Shared/property/property-page"));
const Brokers = React.lazy(() => import("./views/pages/Shared/brokers/broker-page"));
const Condominium = React.lazy(() => import("./views/pages/Shared/condominium/condominium-page"));
const Teranet = React.lazy(() =>
  import("./views/pages/Shared/teranet/teranetPageComponents/teranet-page"),
);
const TeranetWrits = React.lazy(() =>
  import("./views/pages/Shared/teranet/Writs/teranet-writsPage"),
);
const TeranetParcelRegister = React.lazy(() =>
  import(
    "./views/pages/Shared/teranet/teranetPageComponents/parcelRegister/teranet-parcelRegisterPage"
  ),
);
const VtbMortgage = React.lazy(() =>
  import("./views/pages/Shared/mortgage/vtbMortgage/vtbMortgage-page"),
);
const Municipality = React.lazy(() =>
  import("./views/pages/Shared/municipality/municipality-page"),
);
const BillsHoldbacks = React.lazy(() =>
  import("./views/pages/Shared/billsHoldbacks/billsHoldbacks-page"),
);
const Requisitions = React.lazy(() =>
  import("./views/pages/Shared/requisitions/Requisitions-page"),
);
const ResponseToRequisitions = React.lazy(() =>
  import("./views/pages/Sale/closing/responseToRequisitions"),
);
const Undertakings = React.lazy(() =>
  import("./views/pages/Mortgage/closing/undertakings/Undertakings-page"),
);
const Fire = React.lazy(() => import("./views/pages/Shared/fireInsurance/fire-page"));
const Title = React.lazy(() =>
  import(
    // "./views/pages/Shared/titleInsurance/titleInsurancePageComponents/titleInsurance-page"
    "./old-folder-structure/pages/title-insurance/title-insurance.container"
  ),
);
const Adjustments = React.lazy(() => import("./views/pages/Shared/adjustments/adjustments-page"));
const Account = React.lazy(() => import("./views/pages/Shared/statementOfAccount/Account-page"));
const Ledger = React.lazy(() => import("./views/pages/Shared/trustLedger/trustLedger-page"));
const Affidavits = React.lazy(() =>
  import("./views/pages/Shared/executionAffidavits/Affidavits-page"),
);
const Report = React.lazy(() => import("./views/pages/Purchase/closing/ReportToPurchaser/Report"));
const DirectionReFunds = React.lazy(() =>
  import("./views/pages/Sale/closing/DirectionREfunds/DirectionREFunds-page"),
);
const EReg = React.lazy(() =>
  import("./views/pages/Shared/documents/ERegistration/teranetEReg-page"),
);
const UploadDocuments = React.lazy(() =>
  import("./views/pages/Shared/documents/UploadDocuments/uploadDocuments-page"),
);
const UploadLetterHeads = React.lazy(() =>
  import("./views/pages/Shared/UploadLetterHeads/uploadLetterHead-page"),
);
// const UploadLCSTemplates = React.lazy(() =>
//   import("./views/pages/Shared/documents/LCSTemplate/lcsTemplates-page"),
// );
const GeneratingHasan = React.lazy(() =>
  import("./views/pages/Shared/documents/TestForTheGeneratingHasan/testingGenerating-page"),
);
const GeneratingJohn = React.lazy(() =>
  import("./views/pages/Shared/documents/TestForTheGeneratingJohn/testingGenerating-page"),
);
const GeneratingDocx = React.lazy(() =>
  import(
    // "./views/pages/Shared/documents/TestForTheGeneratingDocx/docxGenerating-page"
    "./old-folder-structure/pages/TestForGeneratingDocx/generatingDocxTest.page"
  ),
);
// Stewart data mapping page used for testing purpose
const StewartDataMapping = React.lazy(() =>
  import("./old-folder-structure/pages/stewart-data-mapping/stewart-data-mapping.js"),
);
const GeneratingHaydar = React.lazy(() =>
  import("./views/pages/Shared/documents/TestForTheGeneratingHaydar/testingGenerating-page"),
);
const GenerateDocuments = React.lazy(() =>
  import(
    // "./views/pages/Shared/documents/generateDocuments/generateDocuments-page"
    "./old-folder-structure/pages/generate-document/generateDocument.page"
  ),
);
const FileDocumentsList = React.lazy(() =>
  import("./views/pages/Shared/documents/FileDocumentsList/fileDocumentList-page"),
);
const ExistingEncumbrances = React.lazy(() =>
  import("./views/pages/Shared/existingEncumbrances/encumbrances-page"),
);
const Mortgagor = React.lazy(() =>
  import("./views/pages/Mortgage/initial/mortgagor/mortgagor-page"),
);
const NewMortgage = React.lazy(() =>
  import("./views/pages/Shared/mortgage/newMortgage/pages/newMortgage-mainpage"),
);
const MortgageeSolicitor = React.lazy(() =>
  import("./views/pages/Mortgage/initial/mortgageesSolicitor"),
);

const LandTransferTax = React.lazy(() =>
  import("./views/pages/Purchase/initial/landTransferTax/landTransferTax-page"),
);

const FileUploadedDocuments = React.lazy(() =>
  import("./views/pages/Shared/documents/UploadDocuments/uploadPDFDoc-file-page"),
);

const TestingLLC = React.lazy(() => import("./testingPages/TestingLLCIntegration.page"));

const GlobalMunicipality = React.lazy(() =>
  import("./admin/global-municipality/GlobalMunicipality.page"),
);
const QALLCIntegrator = React.lazy(() => import("./views/pages/QA-LLC-integrator/index"));
const LLCToLCSDataMapping = React.lazy(() =>
  import("./new-folder-structure/shared/lib/LLC/pages/LLCToLCSDataMapping"),
);
const LCSToLLCDataMapping = React.lazy(() =>
  import("./new-folder-structure/shared/lib/LLC/pages/LCSToLLCDataMapping"),
);

const FCTDataMapping = React.lazy(() =>
  import("./new-folder-structure/shared/lib/FCT/pages/LCSToFCTDataMapping"),
);
const LCSFileData = React.lazy(() =>
  import("./new-folder-structure/shared/lib/LCS/pages/LCSFileData"),
);

const routes = [
  { path: "/", exact: true, name: "Home" },

  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/stewartAuth", name: "StewartAuth", component: StewartAuth },
  { path: "/contacts", name: "Contacts", component: Contacts },
  {
    path: "/statementSettings",
    name: "Statement Settings",
    component: statementSettingsPage,
  },
  {
    path: "/manageUsers",
    name: "Manage Users",
    component: ManageUsers,
    role: "admin",
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  { path: "/invoices", name: "Invoices", component: Invoices },
  {
    path: "/lawFirmSettings",
    name: "Law Firm Settings",
    component: LawFirmSettings,
    role: "admin",
  },
  {
    path: "/templateSettings",
    name: "Template Settings",
    component: TemplateSettings,
    role: "admin",
  },
  {
    path: "/customResponseToRequisition",
    name: "Custom Responses to Requisition",
    component: CustomResponsesRequisition,
    role: "admin",
  },
  {
    path: "/integrationSettings",
    name: "Integration Settings",
    component: IntegrationSettings,
    role: "admin",
  },

  {
    path: "/uploadDocuments",
    name: "Upload Documents",
    component: UploadDocuments,
  },
  {
    path: "/uploadLetterHead",
    name: "Upload Letter Heads",
    component: UploadLetterHeads,
  },
  // TODO Add admin privilege
  // {
  //   path: "/uploadLCSTemplates",
  //   name: "Upload LCS Templates",
  //   component: UploadLCSTemplates,
  // },
  {
    path: "/testingHasan",
    name: "Testing Generating",
    component: GeneratingHasan,
  },
  {
    path: "/testingJohn",
    name: "Testing Generating",
    component: GeneratingJohn,
  },
  {
    path: "/testingDocx",
    name: "Testing Generating Docx",
    component: GeneratingDocx,
  },
  {
    path: "/stewart-data-mapping",
    name: "Stewart data mapping",
    component: StewartDataMapping,
  },
  {
    path: "/testingHaydar",
    name: "Testing Generating",
    component: GeneratingHaydar,
  },
  {
    path: "/testingLLC",
    name: "Testing LLC",
    component: TestingLLC,
  },
  {
    path: "/globalMunicipality",
    name: "Global Municipality",
    component: GlobalMunicipality,
  },
  // END: Add admin privilege
  // ===========================================
  {
    path: "/createNewFile",
    exact: true,
    name: "Create a File",
    component: CreateAFile,
  },
  {
    path: "/files/:type/:id/fileOpen",
    exact: true,
    name: "File Opening",
    component: FilePage,
  },

  {
    path: "/files/:type/:id/memos",
    exact: true,
    name: "Memos",
    component: Memos,
  },
  {
    path: "/files/:type/:id/initial/vendors",
    exact: true,
    name: "Vendors",
    component: Vendors,
  },
  {
    path: "/files/:type/:id/initial/purchasers",
    exact: true,
    name: "Purchasers",
    component: Purchasers,
  },
  {
    path: "/files/:type/:id/initial/landTransferTax",
    exact: true,
    name: "LandTransferTax",
    component: LandTransferTax,
  },
  {
    path: "/files/:type/:id/initial/property",
    exact: true,
    name: "Property",
    component: Property,
  },
  {
    path: "/files/:type/:id/initial/broker",
    exact: true,
    name: "Broker",
    component: Brokers,
  },
  {
    path: "/files/:type/:id/initial/condominium",
    exact: true,
    name: "Condominium",
    component: Condominium,
  },
  {
    path: "/files/:type/:id/initial/teranet",
    exact: true,
    name: "Teranet",
    component: Teranet,
  },
  {
    path: "/files/:type/:id/initial/teranetWrits",
    exact: true,
    name: "Teranet Writs",
    component: TeranetWrits,
  },
  {
    path: "/files/:type/:id/initial/teranetParcelRegister/:pin",
    exact: true,
    name: "Teranet Parcel Register",
    component: TeranetParcelRegister,
  },

  {
    path: "/files/:type/:id/initial/VtbMortgage",
    exact: true,
    name: "Vendor Take-Back Mortgage",
    component: VtbMortgage,
  },
  {
    path: "/files/:type/:id/initial/Municipality",
    exact: true,
    name: "Municipality",
    component: Municipality,
  },
  {
    path: "/files/:type/:id/initial/BillsHoldbacks",
    exact: true,
    name: "Bills / Holdbacks",
    component: BillsHoldbacks,
  },
  {
    path: "/files/:type/:id/closing/requisitions",
    exact: true,
    name: "Requisitions",
    component: Requisitions,
  },
  {
    path: "/files/:type/:id/closing/responseToRequisitions",
    exact: true,
    name: "Response to Requisitions",
    component: ResponseToRequisitions,
  },
  {
    path: "/files/:type/:id/closing/undertakings",
    exact: true,
    name: "Undertakings",
    component: Undertakings,
  },
  {
    path: "/files/:type/:id/closing/fireInsurance",
    exact: true,
    name: "Fire Insurance",
    component: Fire,
  },
  {
    path: "/files/:type/:id/closing/titleInsurance",
    exact: true,
    name: "Title Insurance",
    component: Title,
  },
  {
    path: "/files/:type/:id/shared/statementOfAdjustments",
    exact: true,
    name: "Statement of Adjustments",
    component: Adjustments,
  },
  {
    path: "/files/:type/:id/closing/statementOfAccount",
    exact: true,
    name: "Statement of Account",
    component: Account,
  },
  {
    path: "/files/:type/:id/closing/trustLedger",
    exact: true,
    name: "Trust Ledger",
    component: Ledger,
  },
  {
    path: "/files/:type/:id/closing/executionsAffidavits",
    exact: true,
    name: "Executions Affidavits",
    component: Affidavits,
  },
  {
    path: "/files/:type/:id/closing/reportToPurchaser",
    exact: true,
    name: "Report to Purchaser",
    component: Report,
  },
  {
    path: "/files/:type/:id/closing/directionReFunds",
    exact: true,
    name: "Direction Re Funds",
    component: DirectionReFunds,
  },
  {
    path: "/files/:type/:id/documents/eRegistration",
    exact: true,
    name: "E Registration",
    component: EReg,
  },
  {
    path: "/files/:type/:id/documents/generateDocuments",
    exact: true,
    name: "Generate Documents",
    component: GenerateDocuments,
  },
  {
    path: "/files/:type/:id/documents/uploadPDFDocs",
    exact: true,
    name: "File Uploaded Documents",
    component: FileUploadedDocuments,
  },
  {
    path: "/files/:type/:id/documents/fileDocumentsList",
    exact: true,
    name: "Finalized Documents",
    component: FileDocumentsList,
  },
  {
    path: "/files/:type/:id/initial/existingEncumbrances",
    exact: true,
    name: "ExistingEncumbrances",
    component: ExistingEncumbrances,
  },
  {
    path: "/files/:type/:id/initial/mortgagor",
    exact: true,
    name: "Mortgagor",
    component: Mortgagor,
  },
  {
    path: "/files/:type/:id/initial/newMortgage",
    exact: true,
    name: "Mortgage",
    component: NewMortgage,
  },
  {
    path: "/files/:type/:id/initial/mortgageesSolicitor",
    exact: true,
    name: "Mortgagee's Solicitor",
    component: MortgageeSolicitor,
  },
  {
    path: "/qa-llc-integrator",
    exact: true,
    name: "QA/LLC integrator",
    component: QALLCIntegrator,
  },
  {
    path: "/llc-data-mapping",
    exact: true,
    name: "LLC to LCS Data Mapping",
    component: LLCToLCSDataMapping,
  },
  {
    path: "/lcs-data-mapping",
    exact: true,
    name: "LCS to LLC Data Mapping",
    component: LCSToLLCDataMapping,
  },
  {
    path: "/fct-data-mapping",
    exact: true,
    name: "LCS to FCT Data Mapping",
    component: FCTDataMapping,
  },
  {
    path: "/lcs-file-data",
    exact: true,
    name: "LCS File Data",
    component: LCSFileData,
  },
];

export default routes;
