import { format, intlFormat } from "date-fns";

/** 
    Leave this for reference for later
*/
const FormatDateUtil = (date) => {
  if (date) return format(date.toDate(), "MMMM dd, yyyy");
  return "";
};

const FormatDateUtil_date = (date, type) => {
  if (!!date && !type) {
    return format(date, "MMMM dd, yyyy");
  }
  if (!!date && type) {
    return format(date, type);
  }
  return "";
};
const FormatDateUtil_date_with_time = (date) => {
  if (date) {
    return format(date, "MMMM dd, yyyy, hh:mm aaaaa'm'");
  }
  return "";
};
const FormatDateUtil_day = (date) => {
  if (date) {
    const dayOfMonth = intlFormat(date, { day: "numeric" });
    return getOrdinalNum(dayOfMonth);
  }
};
const FormatDateUtil_month = (date) => {
  if (date) {
    return intlFormat(date, { month: "long" });
  }
};
const FormatDateUtil_year = (date) => {
  if (date) {
    return intlFormat(date, { year: "numeric" });
  }
};
const getOrdinalNum = (n) => {
  return n + (n > 0 ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : "");
};
const getFirstDayOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};
const getLastDayOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export {
  FormatDateUtil_date,
  FormatDateUtil_day,
  FormatDateUtil,
  FormatDateUtil_month,
  FormatDateUtil_year,
  FormatDateUtil_date_with_time,
  getOrdinalNum,
  getFirstDayOfMonth,
  getLastDayOfMonth,
};

export default FormatDateUtil;
