import currency from "currency.js";
import Money from "../Money";

const processHstRebate = (finalResult, currentFile) => {
  const propertyFile = currentFile?.property;
  const newBuild = currentFile?.property?.newBuild;
  const propertyHasBeenRenovated = propertyFile?.propertyHasBeenRenovated;
  const addHstRebateToSOA = currentFile?.propertyPrice?.addHstRebateToSOA;
  const provincialPortion =
    currency(currentFile?.propertyPrice?.provincialRebatePortion).value || "";

  const federalPortion = currency(currentFile?.propertyPrice?.federalRebatePortion) || "";

  if (
    federalPortion &&
    provincialPortion &&
    (newBuild === "yes" || propertyHasBeenRenovated === "yes") &&
    addHstRebateToSOA === "yes"
  ) {
    const creditPurchaser = Money(currency(provincialPortion).add(federalPortion).value, "none");
    const propertyHstRebateRow = {
      adjustmentItem: "HST Rebate Assigned to Vendor",
      purchaserCredit: "",
      vendorCredit: "",
      credit: creditPurchaser,
      _classes: "font-weight-bold main-adjustment-text",
      automatic: "true",
    };
    finalResult.push(propertyHstRebateRow);
    const federalRow = {
      adjustmentItem: `Federal Portion: ${Money(federalPortion)}`,
      purchaserCredit: "",
      vendorCredit: "",
      automatic: "true",
    };
    finalResult.push(federalRow);
    const provincialRow = {
      adjustmentItem: `Provincial Portion: ${Money(provincialPortion)}`,
      purchaserCredit: "",
      vendorCredit: "",
      automatic: "true",
    };
    finalResult.push(provincialRow);

    const creditPurchaserRow = {
      adjustmentItem: "Credit Purchaser",
      purchaserCredit: creditPurchaser,
      vendorCredit: "",
      automatic: "true",
    };
    finalResult.push(creditPurchaserRow);
  }
};
export default processHstRebate;
