import currency from "currency.js";

const ProcessPropertyDeposits = async ({ dataToProcess, processedData }) => {
  const type = dataToProcess?.createFile?.fileType;
  dataToProcess = dataToProcess?.propertyDeposits;
  if (dataToProcess) {
    const depositAmount = currency(dataToProcess?.depositAmount).value;
    if (type === "Sale") {
      if (
        dataToProcess?.propertyDepositHeldBy === "vendorsSolicitor" &&
        dataToProcess?.multipleDeposits === "no"
      ) {
        return processedData.concat({
          trustLedgerItem: "Received deposit from vendor",
          trustLedgerExpenditureAmount: "",
          trustLedgerReceiptAmount: depositAmount,
          uniqueID: dataToProcess?.uniqueID,
          type: "receipt",
          processorReference: "propertyDeposits",
          typeOfGeneratedEntry: "automaticEntry",
        });
      }
      if (
        dataToProcess?.multipleDeposits === "yes" &&
        dataToProcess?.deposits?.some((e) => e?.propertyDepositHeldBy === "vendorsSolicitor")
      ) {
        let totalDeposits = 0;
        dataToProcess?.deposits?.forEach((e) => {
          if (e?.propertyDepositHeldBy === "vendorsSolicitor") {
            totalDeposits = currency(totalDeposits).add(e?.additionalDepositAmount).value;
          }
        });
        return processedData.concat({
          trustLedgerItem: "Received deposit from vendor",
          trustLedgerExpenditureAmount: "",
          trustLedgerReceiptAmount: totalDeposits,
          uniqueID: dataToProcess?.uniqueID,
          type: "receipt",
          processorReference: "propertyDeposits",
          typeOfGeneratedEntry: "automaticEntry",
        });
      }
      return processedData;
    }
    if (type === "Purchase") {
      if (
        dataToProcess?.propertyDepositHeldBy === "purchasersSolicitor" &&
        dataToProcess?.multipleDeposits === "no"
      ) {
        return processedData.concat({
          trustLedgerItem: "Received deposit from purchaser",
          trustLedgerExpenditureAmount: "",
          trustLedgerReceiptAmount: depositAmount,
          uniqueID: dataToProcess?.uniqueID,
          type: "receipt",
          processorReference: "propertyDeposits",
          typeOfGeneratedEntry: "automaticEntry",
        });
      }
      if (
        dataToProcess?.multipleDeposits === "yes" &&
        dataToProcess?.deposits.some((e) => e?.propertyDepositHeldBy === "purchasersSolicitor")
      ) {
        let totalDeposits = 0;
        dataToProcess?.deposits.forEach((e) => {
          if (e?.propertyDepositHeldBy === "purchasersSolicitor") {
            totalDeposits = currency(totalDeposits).add(e.additionalDepositAmount).value;
          }
        });
        return processedData.concat({
          trustLedgerItem: "Received deposit from purchaser",
          trustLedgerExpenditureAmount: "",
          trustLedgerReceiptAmount: totalDeposits,
          uniqueID: dataToProcess?.uniqueID,
          type: "receipt",
          processorReference: "propertyDeposits",
          typeOfGeneratedEntry: "automaticEntry",
        });
      }
      return processedData;
    }
    return processedData;
  }
  return processedData;
};

export default ProcessPropertyDeposits;
