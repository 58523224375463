/* eslint-disable array-callback-return */

const ProcessTrustledgerRoutineEntries = async ({ dataToProcess, processedData }) => {
  if (dataToProcess) {
    const trustLedgerData = dataToProcess?.map((data) => {
      return {
        ...data,
        processorReference: "routineEntry",
        typeOfGeneratedEntry: "automaticEntry",
      };
    });
    return processedData.concat(trustLedgerData);
  }
  return processedData;
};

export default ProcessTrustledgerRoutineEntries;
