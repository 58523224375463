/* eslint-disable @typescript-eslint/no-unused-vars */
import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { saveAs } from "file-saver";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from "reactfire";
import { dummyFilesLawFirmID } from "src/config/my-templates-config";
import HnHReactCreatableReactSelectWithLabel from "src/new-folder-structure/components/molecules/creatable-react-select/creatable-react-select.component";
import DummyFiles from "src/old-folder-structure/molecules/dummy-files-dropdown/dummy-files-dropdown.component";
import useGenerateDocument from "src/old-folder-structure/sharedComponents/generateDocument/useGenerateDocument";

import "src/old-folder-structure/organisms/template-settings/ts-specific-add-edit-modal/custom.css";
import EnsureCurlyBracketsAreClosedForDocumentTemplateName from "src/reusable/EnsureCurlyBracketsAreClosedForDocumentTemplateName";
import FileUploadDropzone from "src/reusable/FileUploadDropzone";
import HandleRemovingSlashFromTemplateDocumentName from "src/reusable/HandleRemovingSlashFromTemplateDocumentName";
import useGetCollection from "src/reusable/firestoreHooks/useGetCollection";
import useSaveFileToDatabase from "src/reusable/useSaveFileToDatabaseFields";
import { Base64toBlob, blobToBase64 } from "../../../../../reusable/base64ToBlob";
import { trpc } from "../../../../../shared/utils/trpc";

const lcsCategories = [
  {
    label: "Commercial",
    value: "Commercial",
  },
  {
    label: "Early Stages in File",
    value: "Early Stages in File",
  },
  {
    label: "Preparation for Closing",
    value: "Preparation for Closing",
  },
  {
    label: "Follow-up Letters",
    value: "Follow-up Letters",
  },
  {
    label: "Closing Documents",
    value: "Closing Documents",
  },
  {
    label: "Vacant Land",
    value: "Vacant Land",
  },
  {
    label: "Corporate",
    value: "Corporate",
  },
  {
    label: "Builder",
    value: "Builder",
  },
];

const EditTemplateSettingsModal = ({ id, selectedItem, showModal, setShowModal, refetch }) => {
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    register,
    formState: { errors },
    watch,
  } = methods;
  const lawFirmID = useSelector((state) => state?.old?.lcsUser.lawFirmID);
  const [saving, setSaving] = useState(false);
  const [templateUrl, setTemplateUrl] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [uploadedDocument, setUploadedDocument] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [fontsList, setFontsList] = useState([]);
  const [generatedFile, setGeneratedFile] = useState();
  const [generatedDocMessage, setGeneratedDocMessage] = useState({
    type: "",
    message: "",
  });

  const utils = trpc.useContext();
  const testFile = watch("testFile");
  // const hasDefaultFont = watch("hasDefaultFont");
  const db = useFirestore();
  const storage = getStorage();
  const { collectionData: billsAndHoldbacks } = useGetCollection({
    nameOfCollection: "billsHoldbacks",
  });
  const {
    handleGenerateDocumentButton,
    generating,
    handleMultipleLogic,
    linkToGenerateDocumentGuide,
  } = useGenerateDocument();
  const { fetchFontsList, createNewSpecificFont } = useSaveFileToDatabase();
  const lcsTemplateDocRef = doc(
    db,
    selectedItem?.isTemplateEdited
      ? `lawFirm/${lawFirmID}/editedTemplates/${id}`
      : `lcsTemplates/${id}`,
  );
  const { data } = useFirestoreDocData(lcsTemplateDocRef);
  const specificCategoriesCollectionRef = collection(db, `lawFirm/${lawFirmID}/specificCategories`);

  // eslint-disable-next-line no-unused-vars
  const { data: specificCategories, status: specificCategoriesStatus } = useFirestoreCollectionData(
    specificCategoriesCollectionRef,
  );

  useEffect(() => {
    getAllFontsFromDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setAllCategories([...lcsCategories, ...(specificCategories ? specificCategories : [])]);
  }, [specificCategories]);

  const { mutateAsync: validateTemplate, isLoading: isLoadingGenerateDocument } =
    trpc.lawFirmTemplate.validateTemplate.useMutation();
  const { mutateAsync: getUrl } = trpc.manageStorage.getDownloadUrl.useMutation();
  const { mutateAsync: uploadBytesToStorage } =
    trpc.manageStorage.uploadBytesToStorage.useMutation();

  const generateDocx = async () => {
    setGeneratedDocMessage({ type: "", message: "" });
    setGeneratedFile("");
    if ((uploadedDocument || templateUrl) && selectedFile) {
      const fileToUpload = uploadedDocument ? await blobToBase64(uploadedDocument) : "";
      try {
        const data = await validateTemplate({
          fileId: selectedFile.id,
          lawFirmId: lawFirmID,
          ...(uploadedDocument && {
            selectedTemplate: fileToUpload.replace(
              "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
              "",
            ),
          }),
          ...(uploadedDocument && { collection: selectedItem?.collection }),
          ...(uploadedDocument && { multiple: selectedItem?.multiple }),
          ...(templateUrl &&
            !uploadedDocument && {
              templateData: {
                ...selectedItem,
                templateId: selectedItem.id,
                multipleEntityId: 0,
                category: selectedItem.category.map((c) => (typeof c === "string" ? c : c.label)),
              },
            }),
        });
        const blob = Base64toBlob(data, "testGeneration");
        const file = new File([blob], "generatedDocument", {
          type: blob.type,
        });
        setGeneratedFile(file);
        setGeneratedDocMessage({
          type: "success",
          message: "Document generated successfully",
        });
      } catch (e) {
        setGeneratedDocMessage({
          type: "danger",
          message: "Error while generating document",
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      utils.manageStorage.getDownloadUrl.invalidate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    generateDocx();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, uploadedDocument, selectedItem, billsAndHoldbacks]);

  useEffect(() => {
    async function fetchData() {
      if (data) {
        // eslint-disable-next-line array-callback-return
        Object.keys(data).map((key) => {
          if (key === "category") {
            const categories =
              typeof data[key] !== "string"
                ? data[key].map((c) => ({
                    label: c,
                    value: c,
                  }))
                : [
                    {
                      label: data[key],
                      value: data[key],
                    },
                  ];
            setValue(key, categories);
          } else if (key === "hasDefaultFont") {
            setValue(key, data[key] ? "yes" : "no");
          } else {
            setValue(key, data[key]);
          }
        });

        let response = await getUrl({ path: data.fullPath });
        if (response?.url) {
          setTemplateUrl(response.url);
        } else {
          console.error("Error while fetching document: ", response);
        }

        // await getDownloadURL(ref(storage, `${data.fullPath || ""}`))
        //   .then((url) => {
        //     setTemplateUrl(url);
        //   })
        //   .catch((error) => {
        //     console.error("Error while fetching document: ", error);
        //   });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (selectedItem && !_.isEmpty(selectedItem?.collection))
      if (selectedItem?.collection === "newMortgages")
        selectedItem.collectionLabel = "New Mortgages";
      else if (selectedItem?.collection === "encumbrances")
        selectedItem.collectionLabel = "Existing Encumbrances";
      else if (selectedItem?.collection === "vtbMortgages")
        selectedItem.collectionLabel = "VTB Mortgages";
      else if (selectedItem?.collection === "executionAffidavits")
        selectedItem.collectionLabel = "Execution Affidavits";
      else if (selectedItem?.collection === "billsHoldbacks")
        selectedItem.collectionLabel = "Bills and holdbacks";
      else if (selectedItem?.collection === "purchasers")
        selectedItem.collectionLabel = "Purchasers";
      else if (selectedItem?.collection === "vendors") selectedItem.collectionLabel = "Vendors";
      else if (selectedItem?.collection === "mortgagors")
        selectedItem.collectionLabel = "Mortgagors";
      else selectedItem.collectionLabel = "";
  }, [selectedItem]);

  const getAllFontsFromDB = async () => {
    const fonts = await fetchFontsList();

    setFontsList(fonts);
  };
  const onSubmit = async (formData) => {
    delete formData.testFile;
    setSaving(true);
    setErrorMessage("");
    let formatedDocumentName = HandleRemovingSlashFromTemplateDocumentName(formData.documentName);
    // if(hasDefaultFont==='yes'){
    //   const defaultFont = getValues("defaultFont");
    //   if (fontsList.every((font) => font.value !== defaultFont.value)) {
    //     createNewSpecificFont(defaultFont);
    //     getAllFontsFromDB();
    //   }
    // }
    try {
      let fileMetaData = {
        ...formData,
        documentName: formatedDocumentName,
      };
      fileMetaData.documentName = formatedDocumentName;
      fileMetaData.multiple = data.multiple;
      if (!selectedItem?.isTemplateEdited) {
        fileMetaData.isTemplateEdited = true;
        fileMetaData.lcsTemplateID = id;
      }
      delete fileMetaData.fileToUpload;
      fileMetaData.fileName = `${id}.docx`;
      fileMetaData.fullPath = `lawFirms/${lawFirmID}/editedTemplates/${id}.docx`;
      fileMetaData.category = formData?.category.map((c) => (typeof c === "string" ? c : c.label));
      // fileMetaData.hasDefaultFont = formData?.hasDefaultFont === "yes";
      fileMetaData.templateOrigin = "Modified LCS";
      fileMetaData.templateType = "DOCX";

      const lcsTemplateRef = doc(db, `lawFirm/${lawFirmID}/editedTemplates/${id}`);


      let response = await getUrl({ path: selectedItem.fullPath || "" });

      const blobPromise = await fetch(response?.url);
      const blob = await blobPromise.blob();
      const base64String = await blobToBase64(blob);

      if (formData.fileToUpload?.type) {
        await setDoc(lcsTemplateRef, { ...fileMetaData });
        const base64String = await blobToBase64(formData.fileToUpload);
        uploadBytesToStorage({
          pathToStorage: `lawFirms/${lawFirmID}/editedTemplates/${id}.docx`,
          fileToUpload: base64String.replace(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
            "",
          ),
        });
        await setDoc(lcsTemplateRef, { ...fileMetaData });
      }else if (selectedItem.templateOrigin !== "Modified LCS") { uploadBytesToStorage({
        pathToStorage: `lawFirms/${lawFirmID}/editedTemplates/${id}.docx`,
        fileToUpload: base64String.replace(
          "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
          "",
        ),
      });
        await setDoc(lcsTemplateRef, { ...fileMetaData });

      }else {
        await setDoc(lcsTemplateRef, { ...fileMetaData });
      }
      reset();
      setShowModal(false);
    } catch (error) {
      if (error?.message?.toLowerCase()?.includes("failed to fetch"))
        setErrorMessage("There was an error when submitting. Please click Save to try again.");
      else {
        setErrorMessage(error?.message);
      }
    }
    refetch();
    setSaving(false);
  };
  useEffect(() => {
    if (testFile) {
      const fetchData = async (value) => {
        const fileRef = doc(db, `lawFirm/${dummyFilesLawFirmID}/files/${value}`);
        const fileData = (await getDoc(fileRef)).data();
        setSelectedFile(fileData);
      };

      // call the function
      fetchData(testFile)
        // make sure to catch any error
        .catch(console.error);
    }
  }, [db, testFile]);
  return (
    <>
      <FormProvider {...methods}>
        <CForm
          className="form-horizontal needs-validation"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <CModal
            show={showModal}
            color="primary"
            size="lg"
            onClose={() => setShowModal(false)}
            backdrop={true}
            className="modal-100-view"
            closeOnBackdrop={false}
          >
            <CModalHeader closeButton>
              <CModalTitle>Edit the existing document</CModalTitle>
            </CModalHeader>
            {errorMessage ? <CAlert color="danger">{errorMessage}</CAlert> : ""}
            <CModalBody>
              <CFormGroup row>
                <CCol md="6">
                  <CCardBody>
                    <h4>File Details</h4>

                    <b>You are able to edit these fields</b>
                    <br />
                    <CFormGroup row>
                      <CCol md="3">
                        <CLabel>Document name</CLabel>
                      </CCol>
                      <CCol md="9">
                        <input
                          className={`form-control ${
                            !_.isEmpty(errors?.documentName) ? "is-invalid" : ""
                          }`}
                          {...register(`documentName`, {
                            validate: {
                              closingCurlyBrackets: (value) =>
                                EnsureCurlyBracketsAreClosedForDocumentTemplateName(value) ||
                                "Please ensure to close all curly brackets '}}'",
                              nameIncludesForwardSlash: (value) =>
                                !value.includes("/") || 'The name cannot include a "/" symbol',
                              nameIncludesBackSlash: (value) =>
                                !value.includes(`\\`) || 'The name cannot include a "\\" symbol',
                            },

                            required: {
                              value: true,
                              message: `Document name is required`,
                            },
                          })}
                        />
                        {errors?.documentName?.message && (
                          <div className="display-invalid-feedback">
                            {errors?.documentName?.message}
                          </div>
                        )}
                      </CCol>
                    </CFormGroup>
                    <HnHReactCreatableReactSelectWithLabel
                      label="Category"
                      name={"category"}
                      required
                      modal
                      isMulti
                      options={allCategories}
                      underlyingText="Search or create new by typing in this field"
                      onCreateOption={async (value) => {
                        try {
                          const category = getValues("category");
                          setValue("category", [
                            ...category,
                            {
                              label: value,
                              value,
                            },
                          ]);
                          await addDoc(specificCategoriesCollectionRef, {
                            label: value,
                            value,
                          });
                        } catch (error) {
                          setValue("category", "");
                        }
                      }}
                    />
                    {/*<HnHToggleWithLabel*/}
                    {/*  name="hasDefaultFont"*/}
                    {/*  label="Specify default font for this generated document?"*/}
                    {/*  buttons={[*/}
                    {/*    { value: "yes", name: "Yes" },*/}
                    {/*    { value: "no", name: "No" },*/}
                    {/*  ]}*/}
                    {/*  modal*/}
                    {/*/>*/}
                    {/*{hasDefaultFont === "yes" ? (*/}
                    {/*  <CFormGroup row>*/}
                    {/*    <CCol md="3">*/}
                    {/*      <CLabel>*/}
                    {/*        Select default font for generated document*/}
                    {/*      </CLabel>*/}
                    {/*    </CCol>*/}
                    {/*    <CCol md="9">*/}
                    {/*      <HnHReactCreatableReactSelectWithLabel*/}
                    {/*        name="defaultFont"*/}
                    {/*        options={fontsList}*/}
                    {/*        onCreateOption={async (value) => {*/}
                    {/*          try {*/}
                    {/*            setValue("defaultFont", {*/}
                    {/*              label: value,*/}
                    {/*              value,*/}
                    {/*            });*/}
                    {/*          } catch (error) {*/}
                    {/*            setValue("defaultFont", "");*/}
                    {/*          }*/}
                    {/*        }}*/}
                    {/*      />*/}
                    {/*    </CCol>*/}
                    {/*  </CFormGroup>*/}
                    {/*) : (*/}
                    {/*  <></>*/}
                    {/*)}*/}
                    <br />
                    <b>More details</b>
                    <CFormGroup row className="mt-4">
                      <CCol md="4">
                        <u>Related to file type</u>
                        <br />
                        {selectedItem?.relatedToFileTypes?.map((e) => {
                          return e.label + " ";
                        })}
                      </CCol>
                      {selectedItem?.multiple &&
                      selectedItem?.multiple === "yes" &&
                      !_.isEmpty(selectedItem?.collectionLabel) ? (
                        <>
                          <CCol md="4">
                            <u>Multiple Document</u>
                            <br />
                            {selectedItem?.collectionLabel || ""}
                          </CCol>
                        </>
                      ) : (
                        ""
                      )}
                      {selectedItem?.filters?.letterOrNonLetter ? (
                        <>
                          <CCol md="4">
                            <u>Document type</u>
                            <br />
                            {selectedItem?.filters?.letterOrNonLetter || ""}
                          </CCol>
                        </>
                      ) : (
                        ""
                      )}
                    </CFormGroup>
                  </CCardBody>
                </CCol>
                <CCol md="6 pageList">
                  <CCardBody>
                    <h4>How to use this page?</h4>
                    <ol>
                      <li>
                        Refer to the Build your own guide by clicking{" "}
                        <a
                          href={linkToGenerateDocumentGuide}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <b>
                            <u>here</u>
                          </b>
                        </a>
                      </li>
                      <li>Provide the File Details on the left</li>
                      <li>Select a test file in the Test file drop down</li>
                      <li>
                        Create your .docx template in Word and add mappings where you need dynamic
                        fields to populate
                      </li>
                      <li>
                        Drag and drop your completed template in the Upload File box below and a
                        sample of the final product will be generated
                      </li>
                      <li>Review the sample</li>
                      <li>
                        Modify, save and reupload the template until the generated sample is
                        satisfactory
                      </li>
                      <li>Once satisfactory, click save</li>
                    </ol>
                  </CCardBody>
                </CCol>
              </CFormGroup>
              <hr />
              <h4>Add Template</h4>

              <CFormGroup row className="justify-content-center">
                <CCol md="10">
                  <div className="templateSection">
                    <CFormGroup row className="m-0 mb-3">
                      <DummyFiles required name="testFile" />
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol md="4">
                        {uploadedDocument ? (
                          <div>
                            Template currently uploaded:
                            <CButton
                              onClick={() => {
                                saveAs(
                                  uploadedDocument,
                                  uploadedDocument?.name || "uploadedDocument",
                                );
                              }}
                              shape="rounded-0"
                              color="link"
                            >
                              Click to download
                            </CButton>
                          </div>
                        ) : templateUrl ? (
                          <div>
                            File Uploaded:{" "}
                            <a
                              href={templateUrl}
                              target="_blank"
                              rel="noreferrer"
                              download={templateUrl}
                            >
                              Click to download
                            </a>
                          </div>
                        ) : null}
                      </CCol>
                      <CCol md="4">
                        {generatedFile && generatedDocMessage?.type === "success" && (
                          <div>
                            Generated sample:
                            <CButton
                              onClick={() => {
                                saveAs(generatedFile, "generatedDoc");
                              }}
                              shape="rounded-0"
                              color="link"
                            >
                              Click to download
                            </CButton>
                          </div>
                        )}
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs="12" md="12">
                        <FileUploadDropzone
                          name="fileToUpload"
                          accept=".docx"
                          fileUploaded={(file) => {
                            if (file) setUploadedDocument(file);
                          }}
                        />
                      </CCol>
                    </CFormGroup>{" "}
                    <CFormGroup row>
                      <CCol xs="12" md="12">
                        {generatedDocMessage?.message ? (
                          <CAlert color={generatedDocMessage?.type}>
                            {generatedDocMessage?.message}
                          </CAlert>
                        ) : (
                          ""
                        )}
                      </CCol>
                    </CFormGroup>
                  </div>
                </CCol>
              </CFormGroup>
            </CModalBody>
            <CModalFooter>
              <CButton
                type="submit"
                color="primary"
                disabled={
                  saving ||
                  isLoadingGenerateDocument ||
                  generating ||
                  (generatedDocMessage?.type !== "" && generatedDocMessage?.type !== "success")
                }
              >
                {saving ? (
                  "Saving..."
                ) : (
                  <>
                    <CIcon name="cil-pencil" alt="Settings" /> Save
                  </>
                )}{" "}
              </CButton>
              <CButton
                disabled={isLoadingGenerateDocument}
                color="secondary"
                type="button"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </CButton>
            </CModalFooter>
          </CModal>
        </CForm>
      </FormProvider>
      {/* </BlockUi> */}
    </>
  );
};

export default EditTemplateSettingsModal;
